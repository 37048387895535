.darkmode.modal-user-infos {
  background: #1a1a1a;
}

.darkmode .user-stamps-itens,
.darkmode .number-stamps,
.darkmode .top-name-h1,
.darkmode .user-infos-top-name,
.darkmode .user-infos-fields span,
.darkmode .user-infos-fields p:not(:first-child),
.darkmode .li-with-array p:first-child {
  color: #bfbfbf;
}

.darkmode .user-infos-fields .email-field-infos .infos-email,
.darkmode .view-more-btn,
.darkmode .edit-my-profile {
  color: #40c9f3;
}

.darkmode .user-infos-top,
.darkmode .user-infos-section {
  border-color: #757575;
}
