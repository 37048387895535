.count {
  color: #2ecc71;
}
.count-negative {
  color: #e74c3c;
}

.timer-item {
  display: flex;
}

.seconds-timer {
  display: none;
}
