.miniatura-pdf {
  position: relative;
}

.pdf-doc-item {
  align-items: center;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 210px;
  justify-content: center;
  overflow: hidden;
  width: 200px;
}
