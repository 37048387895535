.ant-slider-track {
  background: #03c4ff;
}

.ant-slider-handle {
  border-color: #03c4ff;
  background: #f5f7f9;
}

.avatar-crop {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.border-avatar-editor {
  align-items: center;
  border-color: #03c4ff;
  border-style: solid;
  border-width: 10px;
  display: flex;
  flex-direction: column;
  height: 310px;
  justify-content: center;
  width: 310px;
}

.buttons-adjust {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 65%;
}

.slider {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  max-width: 300px;
  width: 100%;
}

.slider-input {
  width: 80%;
}

.modal-editor {
  margin-top: 2.5rem;
}

.loader-margin {
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .btn-adjust {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .buttons-adjust {
    width: 100%;
  }
}
