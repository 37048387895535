.button-checkin {
  align-items: center;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  width: 210px;
}

.btn-checkout {
  background: #e74c3c;
}

.btn-checkin {
  background: #2ecc71;
}

.btn-checkout:hover {
  background: #ff422d;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-checkin:hover {
  background: #33E07C;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-checkout:active {
  background: #a82112;
}

.btn-checkin:active {
  background: #1c964e;
}
