.darkmode .btn-pagination {
  color: #40c9f3;
}

.darkmode .btn-pagination:hover {
  filter: brightness(110%);
}

.darkmode .btn-pagination:active {
  filter: brightness(80%);
}

.darkmode .disabled-btn {
  color: transparent;
}
