.course {
  margin-top: 30px;
}

.course-item {
  align-items: center;
}

.course-item header {
  align-self: stretch;
  background: #F5F7F9;
  border-color: #cecece;
  border-width: 1px;
  border-style: solid;
  border-bottom-width: 0;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 1em 2em;
}

.course-item header span {
  color: #474747;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.course-item:not(:first-child) {
  margin-top: 10px;
}

.infos-course {
  display: flex;
  padding: 1em 2em;
  border: 1px solid #cecece;
  border-radius: 0 0 4px 4px;
  justify-content: space-between;
  height: 82px;
}

.infos-course > .course-data-tag .tag-areas hr {
  margin-bottom: 0;
}

.infos-course span {
  color: #757575;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .infos-course strong {
    font-size: 12px;
  }
  .areas-list {
    display: none;
  }
}
