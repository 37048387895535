.darkmode .card-top {
  background: #272727;
  border-color: #383838;
}

.darkmode .card-team-name {
  color: #bfbfbf;
}

.darkmode .user-office {
  color: #989898;
}

.darkmode .card-team-item:hover {
  box-shadow: 0 3px 6px #000000;
}
