.nav-home-cp {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 4% 0;
  width: 100%;
}

.nav-home-cp ul {
  align-self: flex-start;
  display: flex;
  justify-content: center;
}

.nav-home-cp ul li {
  padding: 5px 0;
}

.nav-home-cp ul li:not(:first-child) {
  margin-left: 3rem;
}

.nav-home-cp ul li a {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  padding: 1% 0;
  text-decoration: none;
}

.nav-active {
  border-bottom: 4px solid #00b7ef;
}

.nav-active a {
  filter: brightness(0);
}

.icon-nav {
  display: none;
}

.bar-page {
  background: #bfbfbf;
  height: 2px;
  margin-top: 30px;
  width: 100%;
}

.bar-page-progress {
  background: #00b7ef;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .link-web {
    display: none;
  }

  .icon-nav {
    display: initial;
  }
}
