@media only screen and (max-width: 767px) {
  .btn-alert {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .button-modal-2 button + button {
    margin-left: 0;
  }
}
