.inputs-bank {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 396px 200px 200px;
  margin-top: 1rem;
}

.bar-div {
  height: 2px;
  margin-bottom: 40px;
  width: 100%;
}

.bar-bank {
  background: #cecece;
  height: 100%;

  width: 100%;
}

@media only screen and (max-width: 768px) {
  .inputs-bank {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
  }
  .item-bank:not(:first-child) {
    margin-top: 1rem;
  }
}
