.styled-select-2 {
  align-items: center;
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%;
}
.styled-select-2-label {
  bottom: 35px;
  color: #383838;
  font-size: 12px;
  left: 20px;
  position: absolute;
  transition: 0.4s all;
}
.styled-select-2-field:focus {
  border-bottom: 2px solid #00b7ef;
}
.styled-select-2-field:invalid {
  outline: 0;
}

.styled-select-2-field:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.styled-select-2-field-error {
  border-bottom: 2px solid #e74c3c;
}

.styled-select-2-field-error ~ label {
  color: #e74c3c;
  font-weight: 700;
}
