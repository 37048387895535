.darkmode .profile span {
  color: #f5f7f9;
}

.darkmode .user-on-header {
  animation: animateondark 0.4s linear forwards;
  animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-off-header {
  animation: animateoffdark 0.4s linear forwards;
  animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-on-header-fixed {
  border: 4px solid #58d68d;
}

.darkmode .user-off-header-fixed {
  border: 4px solid #ff796b;
}

.darkmode .on {
  background: #58d68d;
}
.darkmode .off {
  background: #ff796b;
}

@keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}
