.card-team-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: space-between;
  margin-top: 20px;
  text-align: center;
  width: 210px;
}

.card-top {
  border-bottom-width: 0;
  border-color: #bfbfbf;
  border-style: solid;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 86%;
  justify-content: space-between;
  width: 100%;
}

.card-team-avatar {
  align-items: center;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  height: 78px;
  justify-content: center;
  width: 78px;
}

.card-team-name {
  color: #262626;
  font-weight: 600;
}

.card-team-name-margin {
  margin-top: 15px;
}

.card-coins {
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 100%;
}

.card-team-item:hover {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
}

.user-office {
  margin-bottom: 15px;
}

.card-team-avatar,.loader-img {
  margin-top: 10px;
}

@media only screen and (max-width: 376px) {
  .card-team-item {
    height: 190px;
    width: 190px;
  }

  .card-team-name {
    margin: 0;
    font-size: 12px;
  }
  
  .user-office {
    font-size: 10px;
  }
}

@media only screen and (max-width: 361px) {
  .card-team-item {
    height: 150px;
    width: 150px;
  }
}
