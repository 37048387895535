.table-and-correct {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .padding-table {
    margin-bottom: 10%;
  }
  
  .hours-titles {
    display: flex;
    justify-content: space-between;
    margin-left: 18%;
    width: 39%;
  }
  
  .hours-titles-correct {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-top: 1%;
    width: 25%;
  }
  
  .hours {
    align-items: center;
    color: #474747;
    display: flex;
    justify-content: space-around;
    margin-right: 30%;
  }
  
  .hours2 {
    align-items: center;
    color: #474747;
    display: flex;
    justify-content: space-around;
    margin-right: 50%;
  }
  
  .hours-titles,
  .hours-titles-correct {
    font-weight: 600;
  }
  
  .hours {
    margin-left: 5%;
  }
  
  .table {
    margin: 0;
    max-width: 450px;
    padding: 10px 5px;
    width: 100%;
  }
  
  .table-grey {
    background: #f1f1f1;
  }
  
  .table-changed {
    align-items: center;
    background: #ffdf61a1;
    justify-content: center;
    width: 70px;
  }
  
  .input-correct {
    font-size: 14px;
    height: 44px;
    margin-top: 5%;
    max-width: 115px;
    padding-bottom: 5%;
    padding-top: 5%;
    width: 100%;
  }
  
  .input-correct:focus {
    border-bottom: 2px solid #00b7ef;
  }
  
  .input-changed {
    background: #ffdf61a1;
  }
  
  .btns-add-remove {
    margin-top: 5%;
  }
  
  .add-input {
    color: #2ecc71;
    font-size: 24px;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .add-input:disabled {
    opacity: 0;
  }
  
  .area-correction {
    align-self: flex-end;
    position: absolute;
  }
  
  .area-justify {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 372px;
    width: 100%;
  }
  
  .text-justify {
    font-weight: 900;
    margin-top: 2%;
  }
  
  .text-area-input {
    border-radius: 4px;
    font-size: 14px;
    font-style: italic;
    height: 115px;
    margin-top: 15px;
    padding: 2%;
    resize: none;
    width: 100%;
  }
  
  .text-area-input:focus {
    border-bottom: 2px solid #00b7ef;
    font-style: normal;
  }
  
  .btn-correct {
    height: 44px;
    width: 160px;
  }
  
  .btn-group-correct {
    align-self: flex-end;
    display: flex;
  }
  
  .btns {
    width: 150px;
  }
  
  .btns:first-child {
    margin-right: 5%;
  }
  
  .radio-correction {
    align-items: flex-start;
    display: flex;
    width: 100%;
  }
  
  .radio-correction:not(:first-child) {
    margin-top: 10px;
  }
  
  .radio-correction input {
    width: 20px;
    height: 20px;
  }
  
  .radio-correction span {
    margin-left: 10px;
  }
  
  
  @media only screen and (max-width: 860px) {
    .text-area-input {
      max-width: 300px;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .text-area-input, .btn-group-correct{
      align-self: center;
    }
    button.btn-correct {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      padding: 10px;
    }
    .table {
      font-size: 12px;
    }
    .span-btn-correct {
      display: none;
    }
    .area-correction {
      align-self: center;
      position: static;
    }
    .hours2 {
      font-size: 12px;
      margin: 0;
    }
    .hours-titles {
      margin-left: 12%;
      width: 48%;
    }
    .hours-titles-correct {
      width: 55%;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .btns {
      width: 130px;
    }
  }
  