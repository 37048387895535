.darkmode .btn-feedback,
.darkmode .feedback-header {
  background: #40c9f3;
  color: #222222;
}

.darkmode .btn-feed-items img,
.darkmode .feedback-header-items img {
  filter: brightness(0%) contrast(73%);
}

.darkmode .feedback-text-area {
  background: #1a1a1a;
  box-shadow: 0 3px 6px #000000;
}

.darkmode .feedback-text-area p {
  color: #bfbfbf;
}

.darkmode .feedback-text-area textarea {
  background: #1f1f1f;
  color: #bfbfbf;
}
