.infos-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.pop-style .ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(1) {
  display: none;
}

.ant-popover.ant-popconfirm {
  z-index: 10;
}

.ant-popover.ant-popconfirm.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: -25px;
  width: 300px;
  right: 0px;
  z-index: 10;
}

.infos-content {
  align-items: center;
  display: flex;
}

.profile-avatar-infos {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.user-on-infos {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.totalBalanceFix {
  background: #ffdf61a1;
  color: #383838;
  font-style: italic;
  padding: 0 10px;
}

.user-data-infos {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.title-checks {
  margin-bottom: 10px;
}

.options-area {
  display: flex;
  flex-direction: column;
}

.options-check {
  align-self: flex-start;
  display: flex;
  padding: 5px 0;
}

.clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.ant-popover-inner > div > div.ant-popover-buttons {
  align-self: center;
}

.ant-popover-message-title {
  padding: 0 25px;
}

.options-check span {
  margin-left: 5px;
}

.user-data-infos span {
  color: #474747;
  line-height: 25px;
}

.user-data-infos span:first-child {
  font-size: 20px;
  font-weight: 500;
}

span.status-user-desconnect {
  color: #e74c3c;
  font-weight: 500;
}

span.status-user-connect {
  color: #2ecc71;
  font-weight: 500;
}

span.positive-user {
  color: #2ecc71;
}

span.negative-user {
  color: #e74c3c;
}

.saldo-area span:first-child {
  font-size: 15px;
  font-weight: 400;
}

.area-check-in {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

@media only screen and (max-width: 767px) {
  .infos-container {
    align-items: center;
    flex-direction: column;
  }
  .infos-content {
    align-items: center;
    flex-direction: column;

    justify-content: center;
  }
  .user-data-infos {
    align-items: center;
    font-size: 12px;
    width: max-content;
  }
  .user-data-infos span:first-child {
    font-size: 15px;
  }
  .area-check-in {
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .clock {
    margin-top: 10px;
  }

  .ant-popover.ant-popconfirm.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: 0px;
  width: 300px;
  right: -50px;
  z-index: 10;
}
}
