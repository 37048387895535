.sub-nav-bar {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.item-li {
  align-items: center;
  padding: 0 5px;
  position: relative;
}

.item-li a {
  text-decoration: none;
  z-index: 2;
}

.item-flex {
  display: flex;
  flex-direction: column;
}

.text {
  color: #b8b8b9;
  position: absolute;
  top: 20px;
  width: 200px;
}

.text-select {
  color: #000;
  position: absolute;
  top: 20px;
  width: 200px;
}

.text-1 {
  left: -43px;
}

.text-work {
  left: -50px;
}

.text-2 {
  left: -68px;
}

.sub-nav-linha {
  width: 100%;
}

.icon-sub {
  left: -5px;
  position: absolute;
}

.icon-top {
  top: 20px;
}

.linha-nav,
.linha-nav-select {
  height: 2px;
}

.linha-nav {
  background: #b8b8b9;
}

.linha-nav-select {
  background: #00b7ef;
}
.circle-nav,
.circle-nav-select {
  border-radius: 50%;
  height: 8px;
  margin-top: 8px;
  width: 8px;
}

.circle-nav {
  background: #b8b8b9;
}

.circle-nav-select {
  background: #00b7ef;
}

@media only screen and (max-width: 768px) {
  .text,
  .text-select {
    display: none;
  }
}
