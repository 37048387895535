.stamps-modal-area {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, 218px);
  overflow: auto;
}

.stamp-card {
  align-items: center;
  border-color: #bfbfbf;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: center;
  margin: 0;
  padding: 10px;
  width: 218px;
}

.stamp-card img {
  height: 70px;
}

.stamp-card span {
  color: #000;
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}

.modal.modal-stamps {
  height: 610px;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;
}

.close-stamps-modal {
  position: absolute;
  right: 20px;
  top: 10px;
}

.close-stamps-modal img {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
} 

@media only screen and (max-width: 767px) {
  .modal-stamps {
    height: 80%;
  }
  .stamps-modal-area {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .stamp-card  {
    width: 80%;
  }
}