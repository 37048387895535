.empty-message-cp {
  display: flex;
  flex-direction: column;
  margin: 100px auto 100px auto;
  align-items: center;
}

.empty-text-cp {
  color: #cecece;
  font-size: 20px;
  margin-top: 20px;
}

.empty-img {
  height: 220px;
}
