.darkmode.table,
.darkmode.hours-titles-correct,
.darkmode.darkmode.hours-titles,
.darkmode.text-justify,
.darkmode.text-area-input,
.darkmode .radio-correction span {
  color: #bfbfbf;
}

.darkmode.text-area-input,
.darkmode.table-grey {
  background: #1f1f1f;
}

.darkmode.add-input {
  color: #58d68d;
}

.darkmode .table-changed,
.darkmode .input-changed {
  background: #ffdf6113;
}
