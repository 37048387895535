.popover-div {
  background: #00b7ef;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 170px;
  margin-left: 10px;
  width: 16vw;
}

.btn-popover {
  align-self: flex-end;
  background: #fff;
  border-radius: 20px;
  color: #00b7ef;
  margin-right: 1rem;
  height: 25px;
  width: 90px;
}

.text-and-btn {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
