.drop-list {
  display: none;
  height: 0;
  position: absolute;
  width: 0;
}

.drop-list-open {
  background: rgb(255, 255, 255);
  border-color: #383838;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: auto;
  overflow: auto;
  position: absolute;
  width: 290px;
  z-index: 2;
}

.drop-list-open::-webkit-scrollbar {
  width: 8px;
}

.search-area-files {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-area-filter {
  align-items: center;
  display: flex;
  margin-top: 30px;
}

.search-input-files {
  width: 100%;
}

.select-drop-area {
  position: relative;
}

.box-select {
  align-items: center;
  background: #fff;
  border-color: #cecece;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  color: #757575;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: space-between;
  width: 290px;
}

.box-span {
  margin-left: 1rem;
}

.strong-title {
  color: #000;
}

.data-length {
  color: #757575;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .filter-text {
    display: none;
  }
  .select-drop-area {
    align-self: center;
    margin: 0;
  }
}

@media only screen and (max-width: 375px) {
  .select-drop-area {
    margin-right: 0.8rem;
  }
}
