@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap);
.async-search-and-options {
  position: relative;
}

.async-search-options {
  background: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 17px;
  padding: 10px;
  position: absolute;
  height: auto;
  width: 344px;
  z-index: 2;
}

.item-async-search {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 10px 0;
}

.item-async-search span {
  margin-left: 10px;
}

.item-async-search.with-hover:hover {
  background: #00b7ef13;
}

.load-area {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items:center;
          align-items:center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 32px;
  width: 32px;
}
.darkmode .async-search-options {
  background: #272727;
  box-shadow: 0 3px 6px #000000;
  color: #bfbfbf;
}

.newModalContent  {
 display: -webkit-flex;
 display: flex;
 -webkit-flex-direction: column;
         flex-direction: column;
 -webkit-align-items: center;
         align-items: center;
 padding: 2em;
 text-align: center;
 font-size: 17px;
}

.title-new-modal {
    font-size: 20px;
}

.bar-new-modal {
    height: 1px;
    background: #bfbfbf;
    width: 100%;
}
.darkmode.newModal {
    color: #bfbfbf;
}
.styled-select {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
}

.styled-select-label {
  bottom: 50px;
  color: #383838;
  font-size: 15px;
  position: absolute;
  transition: 0.4s all;
}

.styled-select-field {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */ /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat;
  background-position: 92% center;
}

.styled-select-field:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat;
  background-position: 92% center;
  border-bottom: 2px solid #00b7ef;
}

.styled-select-field:invalid {
  outline: 0;
}

.styled-select-field:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.styled-select-field-error {
  border-bottom: 2px solid #e74c3c;
}

.styled-select-field-error ~ label {
  color: #e74c3c;
  font-weight: 700;
}

.darkmode .styled-select-label {
  color: #bfbfbf;
}

.darkmode .styled-select-field {
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat;
  background-position: 92% center;
}

.darkmode .styled-select-field:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat;
  background-position: 92% center;
  border-bottom-color: #40c9f3;
}

.darkmode .styled-select-field:focus ~ label {
  color: #40c9f3;
}

.styled-select-field-error {
  border-bottom-color: #ff796b;
}

.styled-select-field-error ~ label {
  color: #ff796b;
}

.table-and-correct {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.padding-table {
  margin-bottom: 10%;
}

.none-item {
  width: 139px;
}

.hours-titles {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-left: 10px;
  width: 95%;
}

.body > div:nth-child(19) > div > div {
  width: 100%;
}

.ant-popover.ant-popconfirm.ant-popover-placement-bottom {
  z-index: 10;
  max-width: 350px;
  width: 100%;
}

.input-pop {
  z-index: 2000;
}

.input-pop .styled-input-basic .icon-2 {
  top: 15px;
  z-index: 10;
}

.item-text-hour {
  width: 10%;
  display: -webkit-flex;
  display: flex;
}

.hours {
  color: #474747;
}

.hours-titles,
.hours-titles-correct {
  font-weight: 600;
}

.hours {
  margin-left: 5%;
}

.table{
  -webkit-align-self: stretch;
          align-self: stretch;
  color: #474747;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-bottom: 0;
}

.table-correct {
  display: grid;
  grid-template-columns: 100px 190px 100px 190px auto;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 10px 10px 0;
  width: 100%;
}

.table {
  padding: 10px 0;
}

.table-s {
  margin-top: 1em;
}

.correct-and-tables {
  display: grid;
  grid-template-columns: 50% 50%;
}

.hours-titles,
.table span {
  font-size: 15px;
}

.table-grey {
  background: #f1f1f1;
}

.table-changed {
  -webkit-align-items: center;
          align-items: center;
  background: #ffdf61a1;
  -webkit-justify-content: center;
          justify-content: center;
  width: 70px;
}

.correct-and-tables ul {
  margin-bottom: 0;
}

.input-correct {
  font-size: 14px;
  height: 44px;
  max-width: 115px;
  padding: 5% 10px;
  text-align: center;
  width: 100%;
}

.select-correct {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */ /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat #fff;
  background-position: 92% center;
  padding: 0 32px 0 15px;
}

.select-correct:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat #fff;
  background-position: 92% center;
  border-bottom: 2px solid #00b7ef;
}

.table-44 {
  height: 44px;
}

.input-correct:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-changed {
  background: #ffdf61a1;
}

.btns-add-remove {
  margin-top: 5%;
}

.add-input {
  color: #2ecc71;
  font-size: 24px;
  margin-left: 1%;
  margin-right: 1%;
}

.add-input:disabled {
  opacity: 0;
}

.area-correction {
  position: relative;
}

.area-justify {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 372px;
  width: 100%;
}

.text-justify {
  font-weight: 900;
  margin-top: 2%;
}

.text-area-input {
  border-radius: 4px;
  font-size: 14px;
  font-style: italic;
  height: 115px;
  margin-top: 15px;
  padding: 2%;
  resize: none;
  width: 100%;
}

.text-area-input:focus {
  border-bottom: 2px solid #00b7ef;
  font-style: normal;
}

.btn-correct {
  height: 44px;
  width: 160px;
  position: absolute;
  top: -20px;
  right: 0;
}

.btn-group-correct {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  right: 0;
}

.btns {
  width: 150px;
}

.btn-check svg {
  stroke-width: 2px;
}

.btns:first-child {
  margin-right: 5%;
}

.radio-correction {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.radio-correction:not(:first-child) {
  margin-top: 10px;
}

.radio-correction input {
  width: 20px;
  height: 20px;
}

.radio-correction span {
  margin-left: 10px;
}

.reason-span {
  font-size: 15px;
  font-weight: 500;
}

.btn-trash {
  width: 25px;
  justify-self: end;
  margin-right: 15px;
}

@media only screen and (max-width: 860px) {
  .text-area-input {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .text-area-input,
  .btn-group-correct {
    position: relative;
  }

  .area-correction {
    -webkit-align-self: center;
            align-self: center;
    position: absolute;
    right: 15px;
 
  }
  
  .hours-titles,
  .hours-titles-correct,
  .table span {
    font-size: 11px;
  }

  .button-primary.btn-correct {
    position: absolute;
    right: 0;
    top: 0%;
    width: 50px;
    padding: 10px;
  }

  .table-s{
    width: 100%;
  }

  .span-btn-correct {
    display: none;
  }

  .correct-and-tables.open .table {
    width: 100%;
  }

  .table {
    font-size: 12px;
    width: 90%;
  }

  .correct-and-tables {
    grid-template-columns: 90% 10%;
  }

  .correct-and-tables.open {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }

  .correct-and-tables.open .area-correction {
    -webkit-align-self: center;
            align-self: center;
    position: relative;
    right: 0;
  }

  .hours-titles {
    width: 85%;
    margin-left: 17px;
  }

  .btn-group-correct {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .table-correct {
    width: -webkit-max-content;
    width: max-content;
    grid-template-columns: 90px 115px 90px 115px auto;
  }

}

@media only screen and (max-width: 320px) {
  .btns {
    width: 130px;
  }
}

.darkmode.table,
.darkmode .hours-titles,
.darkmode .hours-titles-correct,
.darkmode.darkmode.hours-titles,
.darkmode.text-justify,
.darkmode.text-area-input,
.darkmode .radio-correction span {
  color: #bfbfbf;
}

.darkmode.text-area-input,
.darkmode.table-grey {
  background: #1f1f1f;
}

.darkmode.add-input {
  color: #58d68d;
}

.darkmode .table-changed,
.darkmode .input-changed {
  background: #ffdf6113;
}


.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  border-left-color: #121212;
  border-top-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.darkmode .select-correct {
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat #1a1a1a;
  background-position: 92% center;
  padding: 0 32px 0 15px;
}

.darkmode .select-correct:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat #1a1a1a;
  background-position: 92% center;
  border-bottom-color: #40c9f3;
}
.darkmode .table-44 {
  height: 44px;
}
.title-flex {
  display: -webkit-flex;
  display: flex;
}

.title-more-text {
  font-weight: 700;
}
.text-red {
  color: #e74c3c;
  margin-left: 5px;
}
.email-memorian {
  color: #00b7ef;
}
.btn-return-more {
  -webkit-align-items: center;
          align-items: center;
  color: #00b7ef;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .title-flex {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.darkmode .text-red {
  color: #ff796b;
}

.darkmode .title-more-text p:not(:first-child),
.darkmode p,
.darkmode p strong {
  color: #f5f7f9;
}

.darkmode .email-memorian,
.darkmode .btn-return-more {
  color: #40c9f3;
}

.darkmode .negative-pending span{
    color: #FF796B;
}

.darkmode .text-pending-bold,.darkmode .table-hours-pending span {
    color: #f5f7f9;
}

.darkmode .pending-text-mail a {
    color: #40c9f3;
}
.modal-pending {
    margin-top: 1.5em;
}

.modal-pending-items {
    font-size: 15px;
}

.pending-texts {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}

.pending-texts span:last-child {
    margin-left: 10px;
}

.pending-texts p {
    margin-bottom: 0;
}

.text-pending-bold {
    font-weight: 600;
}

.table-hours-pending span {
    font-weight: 500;
}

.negative-pending {
    color: #E74C3C;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.negative-pending span {
    margin-left: 10px;
}

.font-17 {
    font-size: 17px;
}

.table-hours-pending {
    display: grid;
    grid-template-columns: repeat(2, 90px);
    grid-template-rows: repeat(1, 40px);
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 0;
}

.table-hours-pending input {
    height: 40px;
    font-size: 14px;
    padding: 5% 10px;
}

.pending-text-mail p {
    margin-bottom: 0;
}

.pending-text-mail a {
    color: #00B7EF;
}

.button-pending-modal {
    float: right;
}

.pending-table {
    margin-top: 1.5em;
}
 .administrativo,.administrativa {
  background: #158bbf;
}

.android {
  background: #6dba4c;
}

.backend {
  background: #076302;
}

.bemestar {
  background: #fa7166;
}

.branding {
  background: #c91b23;
}

.comercial {
  background: #223b7d;
}

.design {
  background: #b5027e;
}

.frontend {
  background: #f1a200;
}

.fullstack {
  background: #8da434;
}

.flutter {
  background: #00579c;
}

.ios {
  background: #ff4501;
}

.marketing {
  background: #c91b23;
}

.projetos {
  background: #6531ff;
}

.rh {
  background: #158bbf;
}

.suporte,.ti {
  background: #7e7f73;
}

.qualidade {
  background: #27b5ba;
}

.administrativo:hover,
.administrativa:hover,
.android:hover,
.backend:hover,
.bemestar:hover,
.branding:hover,
.comercial:hover,
.design:hover,
.frontend:hover,
.fullstack:hover,
.ios:hover,
.marketing:hover,
.projetos:hover,
.rh:hover,
.suporte:hover,
.ti:hover,
.qualidade :hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
.tag-areas {
  display: -webkit-flex;
  display: flex;
}

.tag-areas hr {
  border-radius: 2px;
  cursor: pointer;
  height: 6px;
  margin-top: 5px;
  width: 40px;
}

.tag-areas hr:not(:first-child) {
  margin-left: 5px;
}

.tag-open {
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 22px;
  margin-top: 5px;
  padding: 0 5px;
}

.tag-open:not(:first-child) {
  margin-left: 5px;
}

.darkmode .administrativo {
  background: #158bbf;
}

.darkmode .android {
  background: #6db94f;
}

.darkmode .backend {
  background: #066302;
}

.darkmode .bemestar {
  background: #fa7166;
}

.darkmode .branding {
  background: #e21717;
}

.darkmode .comercial {
  background: #223b7d;
}

.darkmode .design {
  background: #b5027e;
}

.darkmode .frontend {
  background: #f2a200;
}

.darkmode .fullstack {
  background: #8da434;
}

.darkmode .ios {
  background: #ff4502;
}

.darkmode .marketing {
  background: #e21717;
}

.darkmode .projetos {
  background: #6531ff;
}

.darkmode .rh {
  background: #158bbf;
}

.darkmode .suporte {
  background: #7e7f73;
}

.darkmode .qualidade {
  background: #28b5b9;
}

.darkmode .tag-open p  {
  color: #1a1a1a;
}

.btn-group-cancel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 0 auto;
  margin-top: 5%;
  width: 75%;
}

.btn-cancel {
  width: 200px;
}

.button-modal,
.button-modal-2,
.modal-text,
.modal-text-forgot {
  margin: 10px auto auto;
}

.button-modal,
.button-modal-2 {
  display: -webkit-flex;
  display: flex;
}

.button-modal button + button {
  margin-left: 20px;
}

.button-modal-2 button {
  width: 240px;
}

.button-modal-2 button + button {
  margin-left: 20px;
}

.confirm-padding {
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  padding: 20px;
}

.copy-acess {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.exit {
  bottom: 40px;
  cursor: pointer;
  position: absolute;
  right: -40px;
}

.exit img {
  height: 15px;
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

.invalid-correction {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.item-cofirm {
  display: -webkit-flex;
  display: flex;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
}

.item-cofirm p {
  margin-bottom: 0;
}

.item-cofirm .confirm-date {
  margin-left: 4px;
}

.texts-informations {
  margin-left: 4px;
}

.item-course-info:not(:last-child) {
  border-bottom: 1px solid #474747;
  padding: 15px 0;
}

.modal-confirmation-title {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 17px;
  margin-bottom: 20px;
}

.modal-courses-h2,
.modal-courses-area {
  font-size: 15px;
}

.modal-courses-area {
  color: #757575;
  display: -webkit-flex;
  display: flex;
}

.modal-courses-h1,
.modal-courses-h2 {
  color: #474747;
  line-height: 30px;
}

.modal-courses-h1 {
  font-size: 18px;
}

.modal-courses-link {
  color: #158bbf;
  text-decoration: none;
}

.modal-courses-titles,
.modal-courses-acess {
  color: #474747;
}

.modal-items {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal-title {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 30px;
  width: 100%;
}

.modal-text,
.modal-text-forgot {
  font-size: 1rem;
}

.modal-text-confirm {
  margin-left: 10px;
}

.modal-text-confirm-approved {
  color: #2ecc71;
}

.modal-text-confirm-refused {
  color: #e74c3c;
}

.modal-text-confirm-pending {
  color: #FFBF00;
}

.modal-text-forgot {
  text-align: center;
}

.modal-text-forgot p {
  color: #00b7ef;
}

.more-infos {
  color: #00b7ef;
  margin-left: 4px;
}

.more-infos-modal p strong {
  margin-right: 4px;
}

.more-infos-modal .title-more {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 0;
}

.more-infos-modal .title-more-text p {
    margin-bottom: 0;
    margin-left: 4px;
}

.tags-modal {
  margin-top: 10px;
}

.text-area-overflow {
  height: 100%;
  max-height: 150px;
}

.title-exit {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}
.insituition-class {
  margin-right: 2px;
}
.insituition-class:first-letter {
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .btn-group-cancel {
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .modal-title {
    font-size: 18px;
  }
  .modal-text-forgot,
  .modal-text {
    font-size: 14px;
    text-align: center;
  }

  .item-cofirm,
  .item-course-info,
  .modal-courses-area,
  .modal-courses-h2 {
    font-size: 12px;
  }
  .confirm-date {
    display: none;
  }
  .item-cofirm {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .modal-courses-h1 {
    font-size: 18px;
  }
}

.darkmode .modal-courses-h1,
.darkmode .modal-courses-h2,
.darkmode .modal-courses-area,
.darkmode .modal-courses-acess {
  color: #bfbfbf;
}

.darkmode .modal-title,
.darkmode .modal-text-forgot,
.darkmode .modal-text,
.darkmode .texts-informations,
.darkmode .confirm-date {
  color: #f5f7f9;
}

.darkmode .modal-title {
  border-bottom-color: #757575;
}

.darkmode .modal-courses-titles {
  color: #757575;
}

.darkmode .modal-text-confirm-refused {
  color: #ff796b;
}

.darkmode .modal-text-confirm-approved {
  color: #58d68d;
}

.darkmode .modal-text-confirm-pending {
  color: #FFDF61;
}

.darkmode .more-infos {
  color: #40c9f3;
}

.button-checkin {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 210px;
}

.btn-checkout {
  background: #e74c3c;
}

.btn-checkin {
  background: #2ecc71;
}

.btn-checkout:hover {
  background: #ff422d;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-checkin:hover {
  background: #33E07C;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-checkout:active {
  background: #a82112;
}

.btn-checkin:active {
  background: #1c964e;
}

.darkmode .button-checkin {
  color: #222222;
}

.darkmode .btn-checkin {
  background: #58d68d;
}

.darkmode .btn-checkout {
  background: #ff796b;
}

.darkmode .btn-checkin:hover,
.darkmode .btn-checkout:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.darkmode .btn-checkin:active,
.darkmode .btn-checkout:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.darkmode .btn-checkin:disabled,
.darkmode .btn-checkout:disabled {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.button-pdf,
.number-pages {
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  height: 40px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 50px;
}

.pdf-navigation {
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 20rem;
}

.clear-and-select-all {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 10px;
}

.select-bar-span {
  color: #bfbfbf;
  font-size: 15px;
}

.select-btn {
  color: #00b7ef;
}

.card-team-item {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 210px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
  text-align: center;
  width: 210px;
}

.card-top {
  border-bottom-width: 0;
  border-color: #bfbfbf;
  border-style: solid;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 86%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.card-team-avatar {
  -webkit-align-items: center;
          align-items: center;
  background-size: cover;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  height: 78px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 78px;
}

.card-team-name {
  color: #262626;
  font-weight: 600;
}

.card-team-name-margin {
  margin-top: 15px;
}

.card-coins {
  -webkit-align-items: center;
          align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  height: 30px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.card-team-item:hover {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
}

.user-office {
  margin-bottom: 15px;
}

.card-team-avatar,.loader-img {
  margin-top: 10px;
}

@media only screen and (max-width: 376px) {
  .card-team-item {
    height: 190px;
    width: 190px;
  }

  .card-team-name {
    margin: 0;
    font-size: 12px;
  }
  
  .user-office {
    font-size: 10px;
  }
}

@media only screen and (max-width: 361px) {
  .card-team-item {
    height: 150px;
    width: 150px;
  }
}

.darkmode .card-top {
  background: #272727;
  border-color: #383838;
}

.darkmode .card-team-name {
  color: #bfbfbf;
}

.darkmode .user-office {
  color: #989898;
}

.darkmode .card-team-item:hover {
  box-shadow: 0 3px 6px #000000;
}

.check-item {
  height: 20px;
  width: 20px;
}

.global-content form .check-item {
  margin-top: 0;
}

.item-drop-list {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 5px 10px;
}

.select-label {
  margin-left: 1.2rem;
}

.styled-check {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 20px;
  width: 20px;
}

.styled-check-unmarked {
  background: #fff;
  border: 1px solid #cecece;
}

.styled-check-marked {
  background-color: #00b7ef;
  border: 1px solid #00b7ef;
}

.styled-check svg {
  stroke-width: 3px;
}

.darkmode .styled-check {
  background: #1a1a1a;
  border: 1px solid #383838;
}

.darkmode .styled-check-marked  {
  background: #40c9f3;
  border: 1px solid #40c9f3;
}

.ant-slider-track {
  background: #03c4ff;
}

.ant-slider-handle {
  border-color: #03c4ff;
  background: #f5f7f9;
}

.avatar-crop {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.border-avatar-editor {
  -webkit-align-items: center;
          align-items: center;
  border-color: #03c4ff;
  border-style: solid;
  border-width: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 310px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 310px;
}

.buttons-adjust {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 1rem;
  width: 65%;
}

.slider {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 1rem;
  max-width: 300px;
  width: 100%;
}

.slider-input {
  width: 80%;
}

.modal-editor {
  margin-top: 2.5rem;
}

.loader-margin {
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .btn-adjust {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .buttons-adjust {
    width: 100%;
  }
}

.darkmode .ant-slider-rail {
  background: #757575;
}

.darkmode .ant-slider-track {
  background: #40c9f3;
}

.darkmode .ant-slider-handle {
  background: #222222;
}

.darkmode .border-avatar-editor,
.darkmode .ant-slider-handle {
  border-color: #40c9f3;
}

.depoiment {
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.depoiment:not(:first-child) {
  margin-top: 20px;
}

.depoiment .avatar {
  background-size: cover;
  border-radius: 50%;
  height: 67px;
  width: 67px;
  transition: width 1s;
}

.depoiment-balom {
  background-color: #e1e1e2;
  border-radius: 0 10px 10px 10px;
  cursor: pointer;
  margin-left: 30px;
  min-height: 68px;
  padding: 10px 20px;
  width: 340px;
}

.depoiment-balom.open {
  width: 300px;
}

.delete-depoiment {
  margin-bottom: 5%;
  margin-left: 10px;
  position: relative;
}

.delete-depoiment:hover .tool-tip-button {
  display: block;
}

.tool-tip-button {
  background: #707070;
  border-radius: 4px;
  color: #fff;
  display: none;
  padding: 2px;
  position: absolute;
  right: -10px;
  z-index: 15;
}

.ballom-and-data {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  z-index: 2;
}

.span-data {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  color: #989898;
}

.depoiment-balom p {
  margin: 0;
  word-break: break-word;
}

.seta-bal {
  height: 60px;
  left: 80px;
  position: absolute;
  z-index: 1;
}

.span-name-depoiment {
  font-weight: 500;
}

.ant-popover-inner > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ant-popover-inner > div > div.ant-popover-buttons {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
}

.ant-popover-inner > div > div.ant-popover-buttons > button {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 30px;
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
  padding: 0 20px;
  transition: 0.2s;
}

.ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(1) {
  background: transparent;
  border: 1px solid #00b7ef;
  color: #00b7ef;
}

.ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #00b7ef;
  color: #fff;
  border-color: #00b7ef;
}

.ant-popover.ant-popconfirm.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #000;
}

@media only screen and (max-width: 767px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 280px;
  }
  .depoiment:hover .avatar {
    height: 30px;
    width: 30px;
  }
  .depoiment-balom.open {
    width: 250px;
  }
}

@media only screen and (max-width: 410px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 230px;
  }
}

@media only screen and (max-width: 350px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 205px;
  }

  .depoiment .avatar {
    height: 50px;
    width: 50px;
  }
}

.darkmode .stamp-card {
  border-color: #383838;
}

.darkmode .stamp-card span {
  color: #bfbfbf;
}

.stamps-modal-area {
  display: grid;
  grid-gap: 25px;
  gap: 25px;
  grid-template-columns: repeat(3, 218px);
  overflow: auto;
}

.stamp-card {
  -webkit-align-items: center;
          align-items: center;
  border-color: #bfbfbf;
  border-style: solid;
  border-width: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 250px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  padding: 10px;
  width: 218px;
}

.stamp-card img {
  height: 70px;
}

.stamp-card span {
  color: #000;
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}

.modal.modal-stamps {
  height: 610px;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;
}

.close-stamps-modal {
  position: absolute;
  right: 20px;
  top: 10px;
}

.close-stamps-modal img {
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
} 

@media only screen and (max-width: 767px) {
  .modal-stamps {
    height: 80%;
  }
  .stamps-modal-area {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .stamp-card  {
    width: 80%;
  }
}
.stamps-class {
  cursor: pointer;
  width: 100%;
}

.stamps-class span {
  font-size: 17px;
  font-weight: 500;
}

.stamps-area {
  border-top: 1px solid #cecece;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 5px 0;
}

.stamps-area img {
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.darkmode .stamps-class {
  color: #bfbfbf;
}

.darkmode .stamps-area {
  border-color: #757575;
}

.user-depoiments {
  margin-top: 20px;
}

.user-depoiments span {
  color: #383838;
  font-size: 17px;
  font-weight: 500;
}

.depoiments-text-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 15px;
  padding: 0 30px 0 0;
}

.progress-circular {
  height: 44px;
  width: 44px;
}

.button-and-progress {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 40px;
  width: 200px;
}

.button-and-progress button {
  height: 44px;
  margin-top: 0;
  width: 142px;
}

.text-depoiments {
  border-color: #bfbfbf;
  border-radius: 4px;
  font-size: 14px;
  height: 115px;
  padding: 2%;
  resize: none;
}

.text-depoiments:focus {
  border-bottom: 2px solid #00b7ef;
  font-style: normal;
}

.darkmode .user-depoiments span,
.darkmode .user-depoiments {
  color: #bfbfbf;
}

.darkmode .text-depoiments {
  background: #272727;
  border-color: #383838;
}

.styled-input-basic {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
}

.styled-input-basic:disabled {
  background: #cecece;
}

.input-text-basic {
  background: #fff;
}

.input-label-basic {
  bottom: 50px;
  color: #383838;
  cursor: text;
  font-size: 15px;
  position: absolute;
  transition: 0.4s all;
}

.input-text-basic:focus,
.ant-select-selection-search-input:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-text-basic:invalid {
  outline: 0;
}

.input-text-error-basic:valid {
  border-bottom: 2px solid #e74c3c;
}

.input-text-basic:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.input-text-basic:disabled {
  background: #cecece;
  z-index: 0;
}

.ant-select-selection-search-input:focus ~ label,
.label-focused {
  color: #00b7ef;
  font-weight: 700;
}

.input-text-basic:disabled ~ label {
  color: #383838;
}

.input-text-error-basic:valid ~ label {
  color: #e74c3c;
  font-weight: 700;
}

.icon-2 {
  position: absolute;
  right: 10px;
  top: 32%;
  z-index: 10;
}

.error-message-input {
  color: #e74c3c;
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
  margin-top: 0.2rem;
  position: absolute;
  top: 100%;
  width: max(250px, 100%);
}

.spinner-input {
  bottom: 10px;
  position: absolute;
  right: 10px;
}
.darkmode .input-label-basic {
  color: #bfbfbf;
}

.darkmode .input-text-basic:focus,
.darkmode .ant-select-selection-search-input:focus {
  border-bottom-color: #40c9f3;
}

.darkmode .input-text-error-basic:valid {
  border-bottom-color: #ff796b;
}

.darkmode .input-text-basic:focus ~ label {
  color: #40c9f3;
}

.darkmode .ant-select-selection-search-input:focus ~ label,
.darkmode .label-focused {
  color: #40c9f3;
}

.darkmode .input-text-basic:disabled ~ label {
  color: #bfbfbf;
}

.darkmode .input-text-error-basic:valid ~ label {
  color: #ff796b;
}

.darkmode .error-message-input {
  color: #ff796b;
}

.input-file-div {
  position: relative;
}

.input-file {
  -webkit-align-items: center;
          align-items: center;
  border: 2px dashed #cecece;
  background-size: cover;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 210px;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
  width: 200px;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
}

.item-input-file {
  display: none;
}

.has-thumbnail {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.has-thumbnail .icon-input-file {
  display: none;
}

.has-thumbnail .input-file-span {
  display: none;
}

.input-file-span {
  color: #383838;
  font-size: 15px;
  margin-left: 5%;
  margin-top: 5%;
  text-align: center;
}

.input-file-label {
  bottom: 100%;
  color: #383838;
  position: absolute;
  transition: 0.4s all;
  width: -webkit-max-content;
  width: max-content;
}

.item-edit {
  bottom: 80%;
  left: 80%;
  position: absolute;
}

.cirle-edit {
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 25px;
  place-content: center;
  place-items: center;
  width: 25px;
}

.cirle-edit:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.cirle-edit:active {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.input-file-icon {
  bottom: 0;
  height: 30px;
  position: absolute;
  right: 0;
}

.upload-bar {
  background-color: #bfbfbf;
  border-radius: 5px;
  height: 5px;
  width: 100%;
}

.uploadProgess {
  background-color: #00b7ef;
  border-radius: 5px;
  height: 100%;
}

.dropdown-avatar {
  background: #ffff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  -webkit-justify-content: center;
          justify-content: center;
  left: 180px;
  position: absolute;
  top: 45px;
  width: 280px;
  z-index: 5;
}

.dropdown-avatar div {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 8px 20px;
}

.dropdown-avatar div:hover {
  background: #f6f6f6;
}

.dropdown-avatar div span {
  color: #000;
  margin-left: 10px;
  padding: 0;
}

.icon-input-file img {
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .dropdown-avatar {
    left: -40px;
  }
}

.darkmode .input-file {
  border-color: #383838;
}

.darkmode .input-file-span {
  color: #757575;
}

.darkmode .input-file-label,
.darkmode .dropdown-avatar div span {
  color: #bfbfbf;
}

.darkmode .dropdown-avatar,
.darkmode .dropdown-avatar div:hover {
  background: #121212;
}

.darkmode .dropdown-avatar div:hover {
  -webkit-filter: brightness(180%);
          filter: brightness(180%);
}

.darkmode .dropdown-avatar img {
  -webkit-filter: invert(87%) sepia(0%) saturate(3%) hue-rotate(162deg) brightness(92%)
    contrast(83%);
          filter: invert(87%) sepia(0%) saturate(3%) hue-rotate(162deg) brightness(92%)
    contrast(83%);
}

.photos-user {
  -webkit-align-items: center;
          align-items: center;
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: repeat(3, 190px);
  height: 380px;
}

.photos-no-betwen {
  margin-left: 10px;
}

.photos-with-betwen {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.avatar-select {
  background-size: cover;
  cursor: pointer;
  height: 190px;
  width: 190px;
}

.section-photos {
  border-bottom: 1px #bfbfbf solid;
  border-top: 1px #bfbfbf solid;
  overflow: auto;
}
.avatar-selected {
  border: 3px #00b7ef solid;
}

.photos-dad {
  position: relative;
}

.photos-check {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 5;
}

.button-center {
  -webkit-align-self: center;
          align-self: center;
}

.modal-galery {
  margin-top: 30px;
}

.span-galery {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .photos-dad {
    margin: 5px auto;
  }
  .span-galery {
    font-size: 12px;
  }
  .photos-user {
    height: 300px;
  }
}

@media only screen and (max-width: 361px) {
  .photos-user {
    height: 200px;
  }
  .title-galery {
    font-size: 14px;
  }
}

.darkmode .span-galery {
  color: #f5f7f9;
}

.fields-profile-editor,
.avatar-and-fields,
.fields-4,
.two-fields {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
}

.avatar-and-fields {
  grid-template-columns: 190px 760px;
}

.fields-4 {
  grid-template-columns: repeat(2, 290px);
  margin-left: 10px;
}

.two-fields {
  grid-template-columns: repeat(2, 396px);
}

.input-editor-profile {
  margin-top: 30px;
  max-width: 800px;
  width: 100%;
}

.button-section {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-right: -1.5rem;
  margin-top: 30px;
}

.group-modal-editor {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.group-modal-editor button {
  width: 140px;
}

.group-modal-editor button:last-child {
  margin-left: 10px;
}

.title-editor {
  max-width: 800px;
  width: 100%;
}

.close-modal-edit {
  position: absolute;
  right: 30px;
}

.close-modal-edit img {
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

@media only screen and (max-width: 768px) {
  .fields-profile-editor,
  .avatar-and-fields,
  .fields-4,
  .two-fields {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .fields-profile-editor {
    margin-top: 3rem;
  }

  .fields-4 {
    align-self: center;
    margin-left: 0;
    width: 100%;
    margin-top: 40px;
    grid-template-columns: none;
    grid-gap: 0;
    gap: 0;
  }

  .input-editor-profile {
    margin-top: 40px;
  }

  .two-fields {
    grid-gap: 0;
    gap: 0;
    grid-template-columns: none;
    grid-row-gap: 60px;
    row-gap: 60px;
  }

  .input-file-avatar {
    -webkit-align-self: center;
            align-self: center;
  }

  .button-section {
    -webkit-align-self: center;
            align-self: center;
  }
}

@media only screen and (max-width: 767px) {
  .group-modal-editor {
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 359px) {
  .close-modal-edit {
    top: 20px;
    right: 10px;
  }
}

.toast-custom {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  color: #fff;
}

.toast-custom span {
  margin-left: 15px;
}

.darkmode .toast-custom {
  color: #222222;
}


.user-infos-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}

.user-infos-content section:first-child {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 245px;
}

.user-infos-content section:last-child {
  margin-left: 100px;
  max-width: 500px;
  width: 100%;
}

.user-infos-top {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.close-user-modal {
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -20px;
}

.close-user-modal img {
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

.top-name-h1,
.top-name-span {
  margin: 0;
}

.user-infos-modal-avatar {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  height: 245px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 245px;
  background-size: cover;
}

.coin-icon {
  -webkit-filter: invert(41%) sepia(0%) saturate(1452%) hue-rotate(209deg)
    brightness(108%) contrast(78%);
          filter: invert(41%) sepia(0%) saturate(1452%) hue-rotate(209deg)
    brightness(108%) contrast(78%);
  height: 18px;
  margin-right: 2px;
}

.depoiments-area-infos {
  margin-top: 20px;
  width: 470px;
}

.user-stamps-itens {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.number-stamps {
  font-weight: 800;
  margin: 0;
}

.user-stamps-itens span {
  color: #757575;
  font-size: 15px;
}

.user-stamps-coins-time {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.user-infos-top,
.user-infos-section {
  border-bottom: 1px solid #cecece;
  display: -webkit-flex;
  display: flex;
  padding: 20px 0;
}

.user-infos-text-value {
  margin-top: 20px;
}

.user-infos-text-value li {
  display: grid;
  grid-template-columns: 180px auto;
}

.user-infos-fields p:first-child {
  color: #757575;
}

.user-infos-fields p:not(:first-child),
.li-with-array p:first-child {
  color: #000000;
}

.modal-user-infos {
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 50%;
  margin-top: 2%;
  max-height: 610px;
  max-width: 900px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2% 60px;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

.user-infos-fields span {
  color: #383838;
  font-weight: 500;
}

.li-with-array {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.user-infos-fields p:not(:first-child),
.li-with-array p,
.infos-email {
  margin-left: 5px;
}

.user-infos-more {
  margin-top: 65px;
}

.social-media-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: 50px;
  position: relative;
  width: 70px;
}

.insta-tool:active,
.linkedin-tool:active {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.insta-tool:active ~ .tool-tip-insta,
.linkedin-tool:active ~ .tool-tip-linkedin {
  background: transparent;
  color: transparent;
}

.tool-tip-insta,
.tool-tip-linkedin {
  background: #707070;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 0 15px;
  position: absolute;
  top: 40px;
}

.insta-tool.disabled, .linkedin-tool.disabled {
  pointer-events: none;
  cursor: none;
}

.insta-tool ~ .tool-tip-insta {
  right: 0;
}

.linkedin-tool ~ .tool-tip-linkedin {
  left: 10px;
}

.insta-tool:hover ~ .tool-tip-insta,
.linkedin-tool:hover ~ .tool-tip-linkedin,
.tool-tip-linkedin:hover,
.tool-tip-insta:hover {
  display: block;
}

.user-infos-top-name h1 {
  font-size: 30px;
  font-weight: 800;
}

.user-infos-top-name span {
  font-size: 20px;
}

.view-more-btn {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.user-infos-fields .email-field-infos .infos-email,
.edit-my-profile,
.view-more-btn {
  color: #00b7ef;
}

.administrativo-text {
  color: #158bbf;
}

.android-text {
  color: #6dba4c;
}

.backend-text {
  color: #076302;
}

.bemestar-text {
  color: #fa7166;
}

.branding-text {
  color: #c91b23;
}

.comercial-text {
  color: #223b7d;
}

.design-text {
  color: #b5027e;
}

.flutter-text {
  color: #00579c;
}

.frontend-text {
  color: #f1a200;
}

.fullstack-text {
  color: #8da434;
}

.ios-text {
  color: #ff4501;
}

.marketing-text {
  color: #c91b23;
}

.projetos-text {
  color: #6531ff;
}

.rh-text {
  color: #158bbf;
}

.suporte-text {
  color: #7e7f73;
}

.qualidade-text {
  color: #27b5ba;
}

.coins-tool-tip {
  position: relative;
}

.tool-tip-coins {
  background: #707070;
  border-radius: 10px;
  color: #fff;
  display: none;
  left: -18px;
  padding: 0 15px;
  position: absolute;
  width: 115px;
}

.coins-tool-tip:hover .tool-tip-coins {
  display: block;
}

.number-stamps svg {
  margin-right: 5px;
}


@media only screen and (max-width: 1600px) {
  .modal-user-infos {
    margin-top: 4%;
    padding: 3% 60px;
    max-height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .user-infos-content {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2rem;
  }

  .close-user-modal {
    top: 0;
  }

  .user-infos-content section:last-child {
    margin-left: 0;
  }

  .modal-user-infos {
    max-height: 100vh;
  }

  .depoiments-text-area {
    padding: 0;
  }

  .text-depoiments {
    width: 100%;
  }

  .social-media-icons {
    width: 100px;
    margin-right: 0;
  }

  .user-infos-modal-avatar {
    height: 210px;
    width: 210px;
  }

  .user-infos-text-value li {
    grid-template-columns: 150px auto;
  }

  .depoiments-area-infos {
    width: auto;
  }

  .modal-user-infos {
    padding: 15% 60px;
  }
}

@media only screen and (max-width: 410px) {
  .user-infos-content {
    margin-top: 3.5rem;
  }

  .user-infos-modal-avatar {
    height: 160px;
    width: 160px;
  }

  .user-infos-text-value li {
    grid-template-columns: 130px auto;
  }
}

@media only screen and (max-width: 350px) {
  .social-media-icons img {
    width: 25px;
    height: 25px;
  }
  .modal-user-infos {
    height: 500px;
  }
  .user-infos-text-value {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .user-infos-content {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 320px) {
  .user-infos-text-value {
    font-size: 11px;
    word-break: keep-all;
  }
}

.darkmode.modal-user-infos {
  background: #1a1a1a;
}

.darkmode .user-stamps-itens,
.darkmode .number-stamps,
.darkmode .top-name-h1,
.darkmode .user-infos-top-name,
.darkmode .user-infos-fields span,
.darkmode .user-infos-fields p:not(:first-child),
.darkmode .li-with-array p:first-child {
  color: #bfbfbf;
}

.darkmode .user-infos-fields .email-field-infos .infos-email,
.darkmode .view-more-btn,
.darkmode .edit-my-profile {
  color: #40c9f3;
}

.darkmode .user-infos-top,
.darkmode .user-infos-section {
  border-color: #757575;
}

.darkmode .seta-bal {
  display: none;
}

.darkmode .depoiment-balom {
  background: #1a1a1a;
  border: 1px solid #383838;
}

.darkmode .depoiment-balom p {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button:nth-child(1) {
  border-color: #40c9f3;
  color: #40c9f3;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #40c9f3;
  border-color: #40c9f3;
  color: #222222;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-arrow {
  border-right-color: #121212;
  border-bottom-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight .ant-popover-inner > div > div.ant-popover-buttons > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #40c9f3;
  border-color: #40c9f3;
}

@media only screen and (max-width: 767px) {
  .btn-alert {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .button-modal-2 button + button {
    margin-left: 0;
  }
}

.dropdown-ul,
.dropdown-ul-disabled {
  max-width: 240px;
  padding: 10px 30px;
  position: fixed;
  right: 1rem;
  top: 6.2rem;
  width: 100%;
  z-index: 2;
}

.dropdown-father {
  position: relative;
}

.icons-dropdown:hover  ~ .tool-tip-header {
  display: block;
}

.tool-tip-header {
  background: #707070;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  display: none;
  right: -8px;
  padding: 2px 10px;
  position: absolute;
  top: 52px;
  z-index: 100;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.dropdown-ul {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.dropdown-ul-disabled {
  display: none;
}

.dropdown-ul li {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 5px 0;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.set-darkmode {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  line-height: 30px;
}

.dropdown-ul li:hover {
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
}

.dropdown-ul li:nth-child(4) {
  border-bottom: 0.5px solid #707070;
  border-top: 0.5px solid #707070;
  padding: 10px 0;
}

.dropdown-ul li:nth-child(5) {
  border-bottom: 0.5px solid #707070;
  padding: 10px 0;
}

.dropdown-ul li a,
.dropdown-span {
  color: #333333;
  margin-left: 5px;
}

.edit-perfil-span,
.span-exit-drop {
  color: #333333;
  margin-left: 11px;
}

@media only screen and (max-width: 768px) {
  .dropdown-ul,
  .dropdown-ul-disabled {
    top: 6rem;
    z-index: 10;
  }
}

.darkmode .dropdown-ul {
  background: #222222;
  box-shadow: 0 3px 6px #000000;
  color: #bfbfbf;
}

.darkmode .dropdown-ul li a,
.darkmode .edit-perfil-span,
.darkmode .span-exit-drop {
  color: #bfbfbf;
}

.darkmode .ant-switch-checked {
  background: #40c9f3;
}

.empty-message-cp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 100px auto 100px auto;
  -webkit-align-items: center;
          align-items: center;
}

.empty-text-cp {
  color: #cecece;
  font-size: 20px;
  margin-top: 20px;
}

.empty-img {
  height: 220px;
}

.darkmode .empty-text-cp {
  color: #757575;
}

.error-message {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-top: 5px;
}

.error-span {
  color: #E74C3C;
}
.custom-radio {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  background: #fff;
  border: 2px solid #cecece;
  -webkit-justify-content: center;
          justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.custom-checked {
  background: #00b7ef;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.darkmode .custom-radio {
  background: #1a1a1a;
  border: 2px solid;
  border-color: #383838;
}

.darkmode .custom-checked {
  background-color: #40c9f3;
  border-color: #bfbfbf;
}

.darkmode .label-radio {
    color: #bfbfbf;
}
.feedback-button {
  bottom: 0;
  height: auto;
  left: 1%;
  position: fixed;
  width: auto;
}

.feedback-button, .feedback-component {
  z-index: 10;
}

.btn-feedback {
  display: -webkit-flex;
  display: flex;
  height: 100px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 84px;
  transition: width 1s;
}

.btn-feedback:hover {
  width: 340px;
}

.btn-feed-items {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  font-size: calc(20px - 35%);
  -webkit-justify-content: center;
          justify-content: center;
  width: 80%;
}

.btn-feedback:hover .btn-feed-items {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.btn-feed-items span,
.btn-feed-items svg {
  display: none;
}

.btn-feedback:hover .btn-feed-items span,
.btn-feedback:hover .btn-feed-items svg {
  display: block;
  -webkit-animation-name: FEEDBACKANIMATION;
          animation-name: FEEDBACKANIMATION;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes FEEDBACKANIMATION {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FEEDBACKANIMATION {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-feed-items img {
  width: 40px;
}

.feedback-header-items {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 90%;
}

.feedback-header-items span img {
  height: 25px;
  margin-right: 10px;
  width: 25px;
}

.feedback-component {
  max-width: 340px;
  width: 100%;
}

.btn-feedback,
.feedback-header {
  -webkit-align-items: center;
          align-items: center;
  background: #00b7ef;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.feedback-header {
  height: 50px;
}

.btn-feedback:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.btn-feedback:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.feedback-text-area {
  background: #ffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 15px;
}

.feedback-text-area p {
  color: #595959;
}

.submit-feedback {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  width: 140px;
}

.feedback-text-area textarea {
  border-radius: 4px;
  font-size: 14px;
  height: 115px;
  padding: 2%;
  resize: none;
  width: 100%;
}

@media only screen and (max-width: 1650px) {
  .feedback-button {
    left: 5px;
  }
  .btn-feed-items {
    padding: 10px;
    width: 100%;
  }
  .btn-feedback {
    width: 84px;
  }
  .btn-feedback:hover {
    width: 250px;
  }
  .btn-feed-items img {
    width: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .feedback-button {
    left: 0;
    width: auto;
  }
  .btn-feedback,
  .btn-feedback:hover {
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 50px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 50px;
  }
  .btn-feed-items {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .btn-feed-items img {
    width: 25px;
  }
  .btn-feed-items span,
  .btn-feedback:hover .btn-feed-items span,
  .btn-feed-items svg,
  .btn-feedback:hover .btn-feed-items svg {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .feedback-component {
    max-width: 100%;
  }
}

.darkmode .btn-feedback,
.darkmode .feedback-header {
  background: #40c9f3;
  color: #222222;
}

.darkmode .btn-feed-items img,
.darkmode .feedback-header-items img {
  -webkit-filter: brightness(0%) contrast(73%);
          filter: brightness(0%) contrast(73%);
}

.darkmode .feedback-text-area {
  background: #1a1a1a;
  box-shadow: 0 3px 6px #000000;
}

.darkmode .feedback-text-area p {
  color: #bfbfbf;
}

.darkmode .feedback-text-area textarea {
  background: #1f1f1f;
  color: #bfbfbf;
}

.profile {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}


.profile img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.border-profile-img {
  border-radius: 50%;
  height: 60px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 60px;
  position: relative;
}

.status-on-off {
  width: 100%;
  height: 100%;
}

.indicator-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  left: 38px;
  bottom: 0px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
}

.on {
  background: #2ecc71;
  border: 2px solid #fff;
}

.off {
  background: #e74c3c;
  border: 2px solid #fff;
}

.div-test {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.user-h-avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .profile span {
    display: none;
  }

}

.darkmode .profile span {
  color: #f5f7f9;
}

.darkmode .user-on-header {
  -webkit-animation: animateondark 0.4s linear forwards;
          animation: animateondark 0.4s linear forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-off-header {
  -webkit-animation: animateoffdark 0.4s linear forwards;
          animation: animateoffdark 0.4s linear forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-on-header-fixed {
  border: 4px solid #58d68d;
}

.darkmode .user-off-header-fixed {
  border: 4px solid #ff796b;
}

.darkmode .on {
  background: #58d68d;
}
.darkmode .off {
  background: #ff796b;
}

@-webkit-keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@-webkit-keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}

@keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}

.notifications {
  position: relative;
}

.notificationsUl {
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.22);
  height: auto;
  max-height: 80vh;
  overflow: auto;
  padding: 0 15px;
  position: fixed;
  right: 2%;
  top: 12%;
  width: 530px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-notifications {
  position: relative;
}

.not-read-symbol {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00B7EF;
  padding: 5px;
}

.btn-notifications:hover ~ .notifications-tool {
  display: block;
}

.notifications-tool {
  right: -35px;
}

.number-notifications {
  -webkit-align-items: center;
          align-items: center;
  background: #F02849;
  border-radius: 50%;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
  height: 22px;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  right: -3px;
  top: -5px;
  width: 22px;
}

.notification-item {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  min-height: 50px;
  padding: 5px 0;
  word-wrap: break-word;
}

.notification-item:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.avatar-and-notification {
  -webkit-align-items:center;
          align-items:center;
  display: -webkit-flex;
  display: flex;
}

.notification-item p {
  margin-bottom: 0;
  width: 100%;
  max-width: 350px;
  margin-right: 5px;
}

.notification-item span {
  color: #757575;
}

.avatar-area-notify {
  padding: 10px;
}

.p-notification {
  margin-bottom: 0;
}

.notification-item .avatar-area-notify .avatar{
  background-size:contain ;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767px) {
  .notificationsUl {
    height: 100%;
    right: 0;
    top: 13%;
    width: 100%;
    font-size: 12px;
  }
}
  
.darkmode .notificationsUl {
    background: #222222;
}

.darkmode .not-read-symbol {
    background: #40C9F3;
  }
.header {
  -webkit-align-items: center;
          align-items: center;
  background: #00b7ef;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-columns: repeat(3,auto);
  height: auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1% 5%;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.name-header {
  color: #fff;
  font-weight: 700;
  margin-right: 0 auto;
}

.logo-nav img {
  width: 180px;
}

.notification-icon {
  margin-left: 5px;
}

.avatar-and-drop {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .logo-nav img {
    width: 150px;
    margin-top: 1rem;
    max-width: 150px;
  }

  .name-header {
    display: none;
  }
}

.darkmode .header {
  background-color: #383838;
  border-bottom: 2px solid #40c9f3;
  box-shadow: none;
}

.darkmode.certain-category-search-dropdown {
  background: #1a1a1a;
  border-color: #383838;
  color: #bfbfbf;
}

.darkmode.certain-category-search-dropdown .ant-select-item-option-content {
  color: #bfbfbf;
}

.darkmode.certain-category-search-dropdown .ant-select-item-option:hover,
.darkmode.certain-category-search-dropdown .ant-select-item-option:active,
.darkmode.certain-category-search-dropdown .ant-select-item-option-selected,
.darkmode.certain-category-search-dropdown .ant-select-item-option-active {
  background: #222222;
}

.course {
  margin-top: 30px;
}

.course-item {
  -webkit-align-items: center;
          align-items: center;
}

.course-item header {
  -webkit-align-self: stretch;
          align-self: stretch;
  background: #F5F7F9;
  border-color: #cecece;
  border-width: 1px;
  border-style: solid;
  border-bottom-width: 0;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 1em 2em;
}

.course-item header span {
  color: #474747;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.course-item:not(:first-child) {
  margin-top: 10px;
}

.infos-course {
  display: -webkit-flex;
  display: flex;
  padding: 1em 2em;
  border: 1px solid #cecece;
  border-radius: 0 0 4px 4px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 82px;
}

.infos-course > .course-data-tag .tag-areas hr {
  margin-bottom: 0;
}

.infos-course span {
  color: #757575;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .infos-course strong {
    font-size: 12px;
  }
  .areas-list {
    display: none;
  }
}

.darkmode .infos-course strong {
  color: #bfbfbf;
}

.darkmode .course-item header {
  background: #212121;

}

.darkmode .infos-course, .darkmode .course-item header {
  border-color: #383838;;
}

.darkmode .course-item header span {
  color: #cecece;
}
.download-component {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    color: #757575;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    height: 120px;
    bottom: 5%;
    width: 100%;
    max-width: 320px;
    -webkit-animation-name: animaton;
            animation-name: animaton;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    right: 0%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.30);
    z-index: 999;
}

.infos-download {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    width: 70%;
}

.close-download {
  position: absolute;
  right: -10px;
  top: -20px;
}

.close-download img {
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%) contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%) contrast(0%);
  height: 10px;
  width: 10px;
}

.download-bar {
    background: #757575;;
    height: 5px;
    margin-top: 10px;
    width: 100%;
}

.bar-blue-infos {
    background:#00c9f3 ;
    height: 100%;
}

.span-download-component {
  word-break: break-word;
}

@-webkit-keyframes animaton {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
}

@keyframes animaton {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
}
.darkmode .download-component {
    background: #1a1a1a;
    box-shadow: 0 3px 6px #000000;
}

.bar-blue-infos {
    background: #40c9f3;
}
.btn-transparent {
  background: transparent;
  color: transparent;
  height: 500px;
  width: 100%;
}

.buttons-transparent {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: absolute;
  top: 5%;
  width: 100%;
}

.navigation-pdf-buttons {
  bottom: 15px;
  margin-top: 10px;
  position: fixed;
}

.pdf-reader-item {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: auto;
}

.mobile-pdf {
  display: none;
}

.pdf-reader-item::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media only screen and (max-width: 767px) {
  .pdf-pc {
    display: none;
  }
  .mobile-pdf {
    display: block;
  }
  .navigation-pdf-buttons {
    z-index: 10;
  }
}

.image-view {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 550px;
    max-width: 980px;
    width: 100%;
}
.files {
  position: relative;
}

.barra {
  background: #bfbfbf;
  height: 3px;
  width: 100%;
}

.barra-2 {
  background: #00c9f3;
  height: 100%;
}

.btn-group-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 10%;
}

.toast-custom-download{
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
}

.toast-custom-download svg {
  margin-right: 10px;
}

.button-tool {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.button-tool-tip {
  height: 40px;
  width: 40px;
  z-index: 10;
}

.button-tool-tip:hover {
  background: #e6e6e6;
  border-radius: 50%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
}

.button-tool-tip ~ .label-btn-file {
  color: transparent;
  position: absolute;
}

.button-tool-tip:hover ~ .label-btn-file {
  -webkit-align-items: center;
          align-items: center;
  background: #757575;
  border-radius: 4px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  color: #fff;
  display: -webkit-flex;
  display: flex;
  padding: 0 5px;
  position: absolute;
  top: 45px;
}

.file {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.icon-file {
  color: #00c9f3;
}

.file-texts {
  color: #828282;
}

.file-title {
  color: #212121;
  font-weight: 700;
}

.infos {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-left: 1rem;
  width: 80%;
}

.infos-texts {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  max-height: 90px;
  margin-left: 2em;
}

.item-file {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.5rem 0;
}

.modal-media {
  -webkit-align-items: center;
          align-items: center;
  background: #bfbfbf;
  border: 20px solid #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  left: 50%;
  overflow: hidden;
  padding: 30px 60px;
  position: relative;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-normal-size {
  height: 100%;
  max-height: 650px;
  max-width: 1110px;
  width: 100%;
}

.modal-z-index {
  z-index: 10;
}

.pdf-viewer {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .btn-group-icons {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
  }
  .file {
    font-size: 12px;
  }
  .modal-media {
    height: 100%;
    max-height: 450px;
    padding: 0;
  }
}

.darkmode .infos-texts span {
  color: #bfbfbf;
}

.darkmode .barra {
  background: #383838;
}

.darkmode .barra-2 {
  background: #40c9f3;
}

.darkmode .button-tool-tip:hover {
  background: #222222;
}

.darkmode .button-tool-tip:hover ~ .label-btn-file {
  background: #383838;
  color: #bfbfbf;
}

.darkmode .view-blue,
.darkmode .save-blue {
  -webkit-filter: invert(67%) sepia(66%) saturate(1491%) hue-rotate(164deg)
    brightness(110%) contrast(91%);
          filter: invert(67%) sepia(66%) saturate(1491%) hue-rotate(164deg)
    brightness(110%) contrast(91%);
}

.darkmode.modal-media {
  border-color: #383838;
  background: #222222;
}

.feedback-item,
.feed-back-area,
.buttons-feedback {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.feed-back-area {
  cursor: pointer;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.feedback-item {
  border-bottom: 1px solid #cecece;
  height: 150px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.buttons-feedback {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px;
}

.feedback-comp {
  height: 100%;
  margin-right: 40px;
  max-width: 650px;
  width: 80%;
}

.text-container p {
  color: #000;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 800;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 560px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.avatar-feedback {
  background-size: cover;
  border-radius: 50%;
  height: 76px;
  width: 76px;
}

.span-data-feedback {
  color: #989898;
}

.name-user-feedback {
  font-weight: 800;
}

.tool-tip-feed svg {
  margin-top: 5px;
}

.tool-feed {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 40px;
  position: relative;
  width: 40px;
}

.tool-feed:hover {
  background: #bfbfbf;
}

.tool-feed span {
  display: none;
  position: absolute;
  text-align: center;
  top: 45px;
  width: 150px;
}

.tool-feed .ft-span {
  right: -30px;
  width: 100px;
}

.tool-feed .sc-span {
  right: -50px;
}

.tool-feed .ts-span {
  right: -5px;
  width: 50px;
}

.tool-feed:hover span {
  background: #707070;
  border-radius: 4px;
  color: #fff;
  display: block;
}

.user-feedback-section,
.user-feed-section-infos {
  display: -webkit-flex;
  display: flex;
}

.user-feedback-section {
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px #cecece solid;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.title-feedback {
  margin-bottom: 15px;
}

.user-feed-section-infos {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 15px;
}

.exit-fb {
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -20px;
}

.exit-fb img {
  -webkit-filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
          filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
  height: 15px;
}

.text-feedback-section {
  height: 160px;
  overflow: auto;
}

.text-feedback-section p {
  padding: 5px;
}

.data-feed-margin {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .text-container p {
    width: 440px;
  }
  .feedback-comp {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .avatar-feedback {
    height: 50px;
    width: 50px;
  }

  .feedback-comp {
    margin-right: -10px;
  }

  .text-container p,
  .name-user-feedback,
  .span-data-feedback {
    font-size: 12px;
  }

  .ant-tabs-tabpane:nth-child(2).ant-tabs-tabpane-active {
    width: 100%;
  }

  .text-container p {
    width: 180px;
  }
}

@media only screen and (max-width: 360px) {
  .avatar-feedback {
    height: 45px;
    width: 45px;
  }

  .feedback-comp {
    margin-right: -20px;
  }

  .text-container p {
    width: 130px;
  }
}

.darkmode .tool-feed:hover {
  background: #bfbfbf1e;
}

.darkmode .tool-feed:hover span {
  background: #383838;
  color: #bfbfbf;
}

.darkmode .text-container p {
  color: #bfbfbf;
}

.btn-pagination {
  -webkit-align-items: center;
          align-items: center;
  color: #00b7ef;
  display: -webkit-flex;
  display: flex;
}

.btn-pagination:hover {
  color: #158bbf;
  text-decoration: underline;
}

.btn-pagination:active {
  color: #005276;
  text-decoration: underline;
}

.disabled-btn {
  color: transparent;
}

.disabled-btn:hover {
  color: transparent;
}

.disabled-btn:active {
  color: transparent;
}

@media only screen and (max-width: 767px) {
  .btn-pagination {
    font-size: 10px;
  }
}

.darkmode .btn-pagination {
  color: #40c9f3;
}

.darkmode .btn-pagination:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.darkmode .btn-pagination:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.darkmode .disabled-btn {
  color: transparent;
}

.pagination {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 10px;
}

.pagination-center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
}

.pagination-select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */ /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat;
  background-position: 95% center;
  font-size: 14px;
  padding: 15px;
  width: 76px;
  margin-bottom: 10px;
}

.pagination-select:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat;
  background-position: 95% center;
  border-bottom: 2px solid #00b7ef;
}

.pagination-item:not(:first-child) {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination,
  .pagination-select {
    font-size: 12px;
  }
  .pagination-select {
    -webkit-align-items: center;
            align-items: center;
    width: 65px;
  }
}

.darkmode .pagination-item {
  color: #bfbfbf;
}

.darkmode .pagination-select {
  background: url(/static/media/arrowDown.b04cecfc.svg) no-repeat;
  background-position: 95% center;
}

.darkmode .pagination-select:focus {
  background: url(/static/media/arrowUp.a21510f8.svg) no-repeat;
  background-position: 95% center;
  border-bottom: 2px solid #00b7ef;
}

.pagination-feedback {
    margin-top: 15px;
}
@media only screen and (max-width: 767px) {
  .btn-cancel-register {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.nav-home-cp {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 0 4% 0;
  width: 100%;
}

.nav-home-cp ul {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.nav-home-cp ul li {
  padding: 5px 0;
}

.nav-home-cp ul li:not(:first-child) {
  margin-left: 3rem;
}

.nav-home-cp ul li a {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  padding: 1% 0;
  text-decoration: none;
}

.nav-active {
  border-bottom: 4px solid #00b7ef;
}

.nav-active a {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.icon-nav {
  display: none;
}

.bar-page {
  background: #bfbfbf;
  height: 2px;
  margin-top: 30px;
  width: 100%;
}

.bar-page-progress {
  background: #00b7ef;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .link-web {
    display: none;
  }

  .icon-nav {
    display: inline;
    display: initial;
  }
}

.darkmode .nav-active a {
  color: #bfbfbf;
  -webkit-filter: none;
          filter: none;
}

.darkmode .nav-active {
  border-bottom-color: #40c9f3;
}

.register-nav {
  margin-bottom: 40px;
}

.register-nav ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.register-nav ul li {
  padding: 5px 0;
}

.register-nav ul li a {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

.nav-active {
  border-bottom: 4px solid #00b7ef;
}

.nav-active a {
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

.email-accesscode,
.dept-off-admission,
.radios-new-user {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 50px;
}

.form-add-member {
  margin-top: 20px;
}

.email-accesscode {
  grid-template-columns: 600px 190px;
}

.dept-off-admission {
  grid-template-columns: 293px 293px 190px;
}

.radio-new-user-comp span {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 15px;
  width: 100%;
}

.radio-new-user-comp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.radios-new-user {
  grid-template-columns: repeat(2, 242px);
  font-size: 15px;
  grid-column-gap: 70px;
  -webkit-column-gap: 70px;
          column-gap: 70px;
}

.radio-user-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.btn-add-group {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 50px;
  margin-bottom: 20px;
}

.btn-add-group button {
  width: 141px;
}

.btn-add-group button:first-child {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .email-accesscode,
  .dept-off-admission,
  .radios-new-user {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .btn-add-group {
    -webkit-align-self: center;
            align-self: center;
  }
}

.miniatura {
  cursor: pointer;
  height: 80px;
  overflow: hidden;
  width: 150px;
}

.loader-grid {
  margin-left: 50%;
  margin-top: 15px;
}

.popover-div {
  background: #00b7ef;
  border-radius: 3px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 170px;
  margin-left: 10px;
  width: 16vw;
}

.btn-popover {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  background: #fff;
  border-radius: 20px;
  color: #00b7ef;
  margin-right: 1rem;
  height: 25px;
  width: 90px;
}

.text-and-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
}

.darkmode.popover-div {
  background: #383838;
  color: #bfbfbf;
}

.darkmode.popover-div .btn-popover {
  background: #40c9f3;
  color: #222222;
}

.card-ranking {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  width: 200px;
}

.avatar-user {
  background-size: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.border-avatar {
  border: 4px solid;
  border-radius: 50%;
  margin-top: 10px;
}

.border-avatar.first {
  height: 142px;
  width: 142px;
}

.border-avatar.side {
  height: 112px;
  width: 112px;
}

.border-avatar.others {
  height: 77px;
  width: 77px;
}

.coins-card {
  -webkit-align-items: center;
          align-items: center;
  color: #3a3a3a;
  display: -webkit-flex;
  display: flex;
  font-size: 17px;
  font-weight: 500;
}

.coins-card img {
  -webkit-filter: brightness(0);
          filter: brightness(0);
  height: 17px;
}

.position {
  font-size: 20px;
  font-weight: 500;
}

.user-name-ranking {
  margin-top: 10px;
}

.administrativo-border {
  border-color: #158bbf;
}

.android-border {
  border-color: #6dba4c;
}

.backend-border {
  border-color: #076302;
}

.bemestar-border {
  border-color: #fa7166;
}

.branding-border {
  border-color: #c91b23;
}

.comercial-border {
  border-color: #223b7d;
}

.design-border {
  border-color: #b5027e;
}

.frontend-border {
  border-color: #f1a200;
}

.fullstack-border {
  border-color: #8da434;
}

.ios-border {
  border-color: #ff4501;
}

.marketing-border {
  border-color: #c91b23;
}

.projetos-border {
  border-color: #6531ff;
}

.rh-border {
  border-color: #158bbf;
}

.suporte-border {
  border-color: #7e7f73;
}

.qualidade-border {
  border-color: #27b5ba;
}

@media only screen and (max-width: 767px) {
  .border-avatar.first {
    height: 100px;
    width: 100px;
  }

  .border-avatar.side {
    height: 80px;
    width: 80px;
  }

  .border-avatar.others {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 330px) {
  .border-avatar.first {
    height: 90px;
    width: 90px;
  }

  .border-avatar.side {
    height: 70px;
    width: 70px;
  }

  .border-avatar.others {
    height: 60px;
    width: 60px;
  }
}

.ranking-component {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 50px;
}

.first-and-sides {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
}

.second-user,
.third-user {
  margin-top: 60px;
}

.others-positions {
  display: -webkit-flex;
  display: flex;
  margin: 40px auto;
  width: 700px;
}

@media only screen and (max-width: 767px) {
  .ranking-component {
    margin: 0 -22px;
  }
  .first-and-sides {
    display: grid;
    grid-template-columns: repeat(3, 110px);
    margin: 0 auto;
  }
  .ant-tabs-tabpane:nth-child(2) {
    width: 0;
  }
  .others-positions {
    display: grid;
    grid-template-columns: repeat(3, 110px);
    margin-top: 20px;
  }
}

@media only screen and (max-width: 376px) {
  .ranking-component {
    margin: 0 -38px;
  }
}

@media only screen and (max-width: 361px) {
  .ranking-component {
    margin: 0 -45px;
  }
}

@media only screen and (max-width: 330px) {
  .first-and-sides {
    grid-template-columns: repeat(3, 95px);
  }
  .others-positions {
    display: grid;
    grid-template-columns: repeat(3, 95px);
    margin-top: 20px;
  }
}

.search-input-cp {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.search-input-cp input {
  background: #f1f1f1;
  height: 58px;
  padding: 0 60px;
}

.search-input-cp input:focus {
  padding: 0 60px;
}

.icon-search {
  left: 10px;
  position: absolute;
  top: 15px;
  z-index: 5;
}

.darkmode .search-input-cp input {
  background: #212121;
  color: #bfbfbf;
}

.async-search-and-options {
  position: relative;
}

.async-search-options {
  background: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 17px;
  position: absolute;
  max-height: 200px;
  overflow: scroll;
  width: 100%;
  z-index: 2;
}

.item-async-search {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.item-async-search span {
  margin-left: 10px;
}

.search-user-option {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.search-user-option.disabled {
  opacity: 0.5;
}

.add-user-term:disabled {
  color: #bfbfbf;
}

.darkmode .async-search-options {
  background: #272727;
  box-shadow: 0 3px 6px #000000;
  color: #bfbfbf;
}

.users-selected-list {
  height: 300px;
  overflow: scroll;
}

.users-selected,
.users-selected-name-avatar {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.users-selected {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}

.users-selected .avatar {
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.users-selected-name-avatar span {
  margin-left: 10px;
}

.no-users-span {
  color: #757575;
}

.users-selected-name-avatar,
.no-users-span,
.remove-user-term {
  font-size: 15px;
}

.remove-user-term {
  margin-right: 10px;
}

.remove-user-term img {
  -webkit-filter: invert(43%) sepia(89%) saturate(541%) hue-rotate(149deg)
    brightness(95%) contrast(108%);
          filter: invert(43%) sepia(89%) saturate(541%) hue-rotate(149deg)
    brightness(95%) contrast(108%);
  height: 12px;
  margin-left: 5px;
  margin-bottom: 1px;
}

.send-terms {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.select-all-terms {
  margin-left: 10px;
}

.check-terms-area .item-drop-list {
  padding: 5px 0;
}

.btn-terms-group {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-terms-group button:nth-child(1) {
  margin-right: 10px;
}

.btn-terms-group button {
  width: 141px;
}

.colabs {
  margin-top: 20px;
}

.search-colab {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: 46% 53%;
  height: 300px;
}

.colab-search-label {
  font-size: 15px;
}

.colabs-add .users-selected-component {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .search-colab {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .btn-terms-group {
    -webkit-align-self: center;
            align-self: center;
  }
}

.darkmode .colab-search-label {
  color: #bfbfbf;
}

.styled-input {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.input-text {
  background: transparent;
  padding: 0 50px;
  transition: 0.2s all;
  z-index: 2;
}

.icon {
  margin: 35px -30px 5px auto;
  z-index: 3;
}

#input-label {
  border-bottom: none;
  color: #d3d3d3;
  cursor: text;
  margin: 10px 50px;
  position: absolute;
  transition: 0.2s all;
}

.input-text:invalid {
  outline: 0;
}

.input-text:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-text-error:valid {
  border-bottom: 2px solid #e74c3c;
}

.styled-select-2 {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%;
}
.styled-select-2-label {
  bottom: 35px;
  color: #383838;
  font-size: 12px;
  left: 20px;
  position: absolute;
  transition: 0.4s all;
}
.styled-select-2-field:focus {
  border-bottom: 2px solid #00b7ef;
}
.styled-select-2-field:invalid {
  outline: 0;
}

.styled-select-2-field:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.styled-select-2-field-error {
  border-bottom: 2px solid #e74c3c;
}

.styled-select-2-field-error ~ label {
  color: #e74c3c;
  font-weight: 700;
}

.sub-nav-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.item-li {
  -webkit-align-items: center;
          align-items: center;
  padding: 0 5px;
  position: relative;
}

.item-li a {
  text-decoration: none;
  z-index: 2;
}

.item-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.text {
  color: #b8b8b9;
  position: absolute;
  top: 20px;
  width: 200px;
}

.text-select {
  color: #000;
  position: absolute;
  top: 20px;
  width: 200px;
}

.text-1 {
  left: -43px;
}

.text-work {
  left: -50px;
}

.text-2 {
  left: -68px;
}

.sub-nav-linha {
  width: 100%;
}

.icon-sub {
  left: -5px;
  position: absolute;
}

.icon-top {
  top: 20px;
}

.linha-nav,
.linha-nav-select {
  height: 2px;
}

.linha-nav {
  background: #b8b8b9;
}

.linha-nav-select {
  background: #00b7ef;
}
.circle-nav,
.circle-nav-select {
  border-radius: 50%;
  height: 8px;
  margin-top: 8px;
  width: 8px;
}

.circle-nav {
  background: #b8b8b9;
}

.circle-nav-select {
  background: #00b7ef;
}

@media only screen and (max-width: 768px) {
  .text,
  .text-select {
    display: none;
  }
}

.darkmode .text-select {
  color: #bfbfbf;
}

.darkmode .text {
  color: #757575;
}

.darkmode .linha-nav,
.darkmode .circle-nav {
  background: #757575;
}

.darkmode .linha-nav-select,
.darkmode .circle-nav-select {
  background: #40c9f3;
}

.arrow-area {
  position: relative;
}

.arrows-scroll {
  background: #bfbfbf;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 45%;
}

.arrow-scroll-left {
  left: 0;
}

.arrow-scroll-rigth {
  right: 0px;
}

.arrows-scroll svg {
  stroke-width: 2px;
}

.team-list {
  width: 650px;
  height: 325px;
}

.cards-area {
  display: -webkit-flex;
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
}

.cards-area .card-item:not(:first-child) {
  margin-left: 10px;
}

.team-list-title {
  border-bottom: 1px solid #bfbfbf;
  color: #262626;
  font-size: 20px;
}

@media only screen and (min-width: 1650px) {
  .team-list {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team-list {
    width: 100%;
  }
}

.darkmode .team-list-title {
  border-bottom-color: #383838;
  color: #bfbfbf;
}

.darkmode .ant-menu-inline,
.darkmode .ant-menu-vertical,
.darkmode .ant-menu-vertical-left {
  background: #1a1a1a;
  border: none;
}

.darkmode .nav-team-li button {
  color: #bfbfbf;
}

.darkmode .nav-team-li.active button {
  color: #40c9f3;
}

.darkmode .nav-team-li.active {
  border-color: #40c9f3;
}

.darkmode .nav-team-li {
  border-right: none;
}

.team-area {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
}

.teams-lists {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 610px;
  margin-left: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.div-teams-scroll:last-child {
  margin-bottom: 38%;
}

.nav-team {
  margin-top: 40px;
}

.nav-team-li {
  border-right: 1px solid #cecece;
  padding: 5px 10px;
  width: 100px;
}

.nav-team-li.active button {
  color: #00b7ef;
}

.nav-team-li.active {
  border-right: 2px solid #00b7ef;
}

.nav-team-li button {
  color: #757575;
  text-align: start;
  width: 100px;
}

.nav-team-li .ios-nav::first-letter {
  text-transform: lowercase;
}

@media only screen and (min-width: 1650px) {
  .teams-lists {
    height: 100%;
    max-height: 940px;
  }
  .div-teams-scroll:last-child {
    margin-bottom: 100%;
  }
}

@media only screen and (max-width: 415px) {
  .teams-lists {
    margin-left: 0;
  }
  .div-teams-scroll:last-child {
    margin-bottom: 80%;
  }
  .nav-team-li {
    width: 80px;
    margin-right: 20px;
  }
  .nav-team-li button {
    font-size: 12px;
    width: 80px;
  }
}

.count {
  color: #2ecc71;
}
.count-negative {
  color: #e74c3c;
}

.timer-item {
  display: -webkit-flex;
  display: flex;
}

.seconds-timer {
  display: none;
}

.darkmode .count-negative {
  color: #ff796b;
}

.darkmode .count {
  color: #58d68d;
}

.toast-custom-container .Toastify__toast--default {
  background: #fff;
  color: #333333;
}
.toast-custom-container .Toastify__toast--info {
  background: #00b7ef;
}
.toast-custom-container .Toastify__toast--success {
  background: #2ecc71;
}
.toast-custom-container .Toastify__toast--warning {
  background: #ffbf00;
}
.toast-custom-container .Toastify__toast--error {
  background: #e74c3c;
}

.toast-notificação {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  padding: 10px 0 ;
}

.toast-notificação .avatar {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
}

.avatar-notification {
  padding: 5px;
}
.darkmode .Toastify__toast--default {
  background: #1a1a1a;
  color: #bfbfbf;
}

.Toastify__toast--info {
  background: #40c9f3;
}

.darkmode .Toastify__toast--success {
  background: #58d68d;
}

.darkmode .Toastify__toast--warning {
  background: #ffdf61;
}

.darkmode .Toastify__toast--error {
  background: #ff796b;
}

.darkmode .Toastify__toast--info,
.darkmode .Toastify__toast--success,
.darkmode .Toastify__toast--error,
.darkmode .Toastify__toast--warning,
.darkmode .Toastify__close-button svg  {
  color: #222222;
}

.darkmode .Toastify__close-button {
  opacity: 0.8;
}

.darkmode .Toastify__progress-bar {
  background: #2222229c;
}
.clock-component {
    width: 80px;
}
.darkmode.clock-component {
    color: #f5f7f9;
}
.infos-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}

.pop-style .ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(1) {
  display: none;
}

.ant-popover.ant-popconfirm {
  z-index: 10;
}

.ant-popover.ant-popconfirm.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: -25px;
  width: 300px;
  right: 0px;
  z-index: 10;
}

.infos-content {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.profile-avatar-infos {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.user-on-infos {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.totalBalanceFix {
  background: #ffdf61a1;
  color: #383838;
  font-style: italic;
  padding: 0 10px;
}

.user-data-infos {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}

.title-checks {
  margin-bottom: 10px;
}

.options-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.options-check {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  padding: 5px 0;
}

.clock {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.ant-popover-inner > div > div.ant-popover-buttons {
  -webkit-align-self: center;
          align-self: center;
}

.ant-popover-message-title {
  padding: 0 25px;
}

.options-check span {
  margin-left: 5px;
}

.user-data-infos span {
  color: #474747;
  line-height: 25px;
}

.user-data-infos span:first-child {
  font-size: 20px;
  font-weight: 500;
}

span.status-user-desconnect {
  color: #e74c3c;
  font-weight: 500;
}

span.status-user-connect {
  color: #2ecc71;
  font-weight: 500;
}

span.positive-user {
  color: #2ecc71;
}

span.negative-user {
  color: #e74c3c;
}

.saldo-area span:first-child {
  font-size: 15px;
  font-weight: 400;
}

.area-check-in {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 5%;
}

@media only screen and (max-width: 767px) {
  .infos-container {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .infos-content {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;

    -webkit-justify-content: center;

            justify-content: center;
  }
  .user-data-infos {
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    width: -webkit-max-content;
    width: max-content;
  }
  .user-data-infos span:first-child {
    font-size: 15px;
  }
  .area-check-in {
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .clock {
    margin-top: 10px;
  }

  .ant-popover.ant-popconfirm.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: 0px;
  width: 300px;
  right: -50px;
  z-index: 10;
}
}

.darkmode.user-data-infos span {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
  position: absolute;
  top: -25px;
  width: 300px;
  right: 0px;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-arrow {
  border-left-color: #121212;
  border-top-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.darkmode.user-data-infos .status-user-connect,
.darkmode.user-data-infos .positive-user {
  color: #58d68d;
}

.darkmode.user-data-infos .status-user-desconnect,
.darkmode.user-data-infos .negative-user {
  color: #ff796b;
}

.darkmode .user-on {
  -webkit-animation: animateondark 0.4s linear forwards;
          animation: animateondark 0.4s linear forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-off {
  -webkit-animation: animateoffdark 0.4s linear forwards;
          animation: animateoffdark 0.4s linear forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-on-fixed {
  border: 4px solid #58d68d;
}

.darkmode .user-off-fixed {
  border: 4px solid #ff796b;
}

.darkmode .totalBalanceFix {
  background: #ffdf6113;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight .ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(2) {
  color:#222222 ;
}

@-webkit-keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@-webkit-keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}

@keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}


@media only screen and (max-width: 767px) {
  .ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: 0px;
  width: 300px;
  right: -50px;
  z-index: 10;
}
}
.trajectory-class {
  width: 100%;
}

.trajectory-class span {
  font-size: 17px;
  font-weight: 500;
}

.trajectory-area {
  border-top: 1px solid #cecece;
  padding: 15px 0;
}

.ant-timeline-item:first-child .dot {
  background: #00b7ef;
}

.dot {
  background: #757575;
  border-radius: 50%;
  height: 5px;
  width: 5px;
}

.title-timeline {
  color: #000;
  font-size: 17px;
  font-weight: 500;
}

.date-timeline {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}

.darkmode .trajectory-class,
.darkmode .title-timeline {
  color: #bfbfbf;
}

.darkmode .ant-timeline-item-head {
  background-color: transparent;
}

.darkmode .ant-timeline-item:first-child .dot {
  background: #40c9f3;
}

.darkmode .dot {
  background: #757575;
}

.darkmode .ant-timeline-item-tail {
  border-color: #757575;
}

.thumb {
  cursor: pointer;
  height: 80px;
  overflow: hidden;
  width: 150px;
}

.miniatura-pdf {
  position: relative;
}

.pdf-doc-item {
  -webkit-align-items: center;
          align-items: center;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  height: 210px;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  width: 200px;
}

.accordion-wrapper {
  background: #f1f1f1;
}
.accordion-wrapper-white {
  background: #fff;
}

.item-fix {
  background: #ffdf61a1;
}

.accordion-item {
  height: auto;
  max-height: 9999px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  font-weight: 500;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 44px;
  padding: 0px 2%;
}

.item-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 75%;
}

.accordion-title:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
  height: 0;
  width: 0;
}

.accordion-title:hover,
.accordion-title.open {
  color: black;
}

.accordion-title.open::after {
  border-top: 0;
}

.accordion-content {
  background: #fff;
  padding: 1em 0 2em 4em;
}

.item-title {
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 2%;
  width: 80%;
}

.item-title-correction {
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 2%;
  width: 80%;
}

.title-texts-timer {
  display: -webkit-flex;
  display: flex;
}

.title-texts {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.title-negative {
  color: #e74c3c;
  margin-left: 2%;
}

.title-positive {
  color: #474747;
  margin-left: 2%;
}

.title-positive:not(:first-child) {
  display: none;
}

.title-correction {
  color: #383838;
  font-style: italic;
}

.hours-negative {
  color: #e74c3c;
}

.hours-positive {
  color: #2ecc71;
}

.status {
  margin-right: 15px;
}

.status-work,
.status-negative {
  font-size: 20px;
  font-weight: 500;
}

.status-work {
  color: #2ecc71;
}

.status-negative {
  color: #e74c3c;
}

.hours-not-decrease, .status-not-decrease {
  color: #00B7EF;
}

@media only screen and (max-width: 767px) {
  .title-texts {
    font-size: 12px;
  }
  .title-texts-timer {
    font-size: 12px;
    width: 70%;
  }
  .title-positive:not(:first-child) {
    display: block;
  }
  .title-positive:first-child {
    display: none;
  }
  .title-correction {
    display: none;
  }
  .accordion-content {
    padding: 0;
  }
  .accordion-content.open-content {
    overflow: scroll;
  }
}

.darkmode.accordion-wrapper {
  background: #1f1f1f;
}

.darkmode.accordion-wrapper-white,
.darkmode.accordion-content {
  background: #1a1a1a;
}

.darkmode.title-positive,
.darkmode .title-correction {
  color: #bfbfbf;
}

.darkmode.hours-negative,
.darkmode.status-negative,
.darkmode.title-negative {
  color: #ff796b;
}

.darkmode.hours-positive,
.darkmode.status-work {
  color: #58d68d;
}

.darkmode .item-fix {
  background: #ffdf6113;
}

.darkmode.hours-not-decrease,
.darkmode.status-not-decrease {
  color: #40C9F3;
}
  
.table-and-correct {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
  }
  
  .padding-table {
    margin-bottom: 10%;
  }
  
  .hours-titles {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 18%;
    width: 39%;
  }
  
  .hours-titles-correct {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 5%;
    margin-top: 1%;
    width: 25%;
  }
  
  .hours {
    -webkit-align-items: center;
            align-items: center;
    color: #474747;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-right: 30%;
  }
  
  .hours2 {
    -webkit-align-items: center;
            align-items: center;
    color: #474747;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-right: 50%;
  }
  
  .hours-titles,
  .hours-titles-correct {
    font-weight: 600;
  }
  
  .hours {
    margin-left: 5%;
  }
  
  .table {
    margin: 0;
    max-width: 450px;
    padding: 10px 5px;
    width: 100%;
  }
  
  .table-grey {
    background: #f1f1f1;
  }
  
  .table-changed {
    -webkit-align-items: center;
            align-items: center;
    background: #ffdf61a1;
    -webkit-justify-content: center;
            justify-content: center;
    width: 70px;
  }
  
  .input-correct {
    font-size: 14px;
    height: 44px;
    margin-top: 5%;
    max-width: 115px;
    padding-bottom: 5%;
    padding-top: 5%;
    width: 100%;
  }
  
  .input-correct:focus {
    border-bottom: 2px solid #00b7ef;
  }
  
  .input-changed {
    background: #ffdf61a1;
  }
  
  .btns-add-remove {
    margin-top: 5%;
  }
  
  .add-input {
    color: #2ecc71;
    font-size: 24px;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .add-input:disabled {
    opacity: 0;
  }
  
  .area-correction {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    position: absolute;
  }
  
  .area-justify {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    max-width: 372px;
    width: 100%;
  }
  
  .text-justify {
    font-weight: 900;
    margin-top: 2%;
  }
  
  .text-area-input {
    border-radius: 4px;
    font-size: 14px;
    font-style: italic;
    height: 115px;
    margin-top: 15px;
    padding: 2%;
    resize: none;
    width: 100%;
  }
  
  .text-area-input:focus {
    border-bottom: 2px solid #00b7ef;
    font-style: normal;
  }
  
  .btn-correct {
    height: 44px;
    width: 160px;
  }
  
  .btn-group-correct {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
  }
  
  .btns {
    width: 150px;
  }
  
  .btns:first-child {
    margin-right: 5%;
  }
  
  .radio-correction {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  
  .radio-correction:not(:first-child) {
    margin-top: 10px;
  }
  
  .radio-correction input {
    width: 20px;
    height: 20px;
  }
  
  .radio-correction span {
    margin-left: 10px;
  }
  
  
  @media only screen and (max-width: 860px) {
    .text-area-input {
      max-width: 300px;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .text-area-input, .btn-group-correct{
      -webkit-align-self: center;
              align-self: center;
    }
    button.btn-correct {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      padding: 10px;
    }
    .table {
      font-size: 12px;
    }
    .span-btn-correct {
      display: none;
    }
    .area-correction {
      -webkit-align-self: center;
              align-self: center;
      position: static;
    }
    .hours2 {
      font-size: 12px;
      margin: 0;
    }
    .hours-titles {
      margin-left: 12%;
      width: 48%;
    }
    .hours-titles-correct {
      width: 55%;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .btns {
      width: 130px;
    }
  }
  
.darkmode.table,
.darkmode.hours-titles-correct,
.darkmode.darkmode.hours-titles,
.darkmode.text-justify,
.darkmode.text-area-input,
.darkmode .radio-correction span {
  color: #bfbfbf;
}

.darkmode.text-area-input,
.darkmode.table-grey {
  background: #1f1f1f;
}

.darkmode.add-input {
  color: #58d68d;
}

.darkmode .table-changed,
.darkmode .input-changed {
  background: #ffdf6113;
}

.accordion-wrapper {
    background: #f1f1f1;
  }
  .accordion-wrapper-white {
    background: #fff;
  }
  
  .item-fix {
    background: #ffdf61a1;
  }
  
  .accordion-item {
    height: auto;
    max-height: 9999px;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  }
  
  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  
  .accordion-title {
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    font-weight: 500;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 44px;
    padding: 0px 2%;
  }
  
  .item-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 75%;
  }
  
  .accordion-title:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
    height: 0;
    width: 0;
  }
  
  .accordion-title:hover,
  .accordion-title.open {
    color: black;
  }
  
  .accordion-title.open::after {
    border-top: 0;
  }
  
  .other-content.accordion-content.open-content {
    padding-bottom: 28%;
  }
  
  .accordion-content {
    background: #fff;
    padding: 1em 2em 2em 1em;
  }
  
  .accordion-content.open-content {
    padding-bottom: 12%;
  }
  
  .item-title {
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 2%;
    width: 80%;
  }
  
  .item-title-correction {
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 2%;
    width: 80%;
  }
  
  .title-texts-timer {
    display: -webkit-flex;
    display: flex;
  }
  
  .title-texts {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }
  
  .title-negative {
    color: #e74c3c;
    margin-left: 2%;
  }
  
  .title-positive {
    color: #474747;
    margin-left: 2%;
  }
  
  .title-positive:not(:first-child) {
    display: none;
  }
  
  .title-correction {
    color: #383838;
    font-style: italic;
  }
  
  .hours-negative, .status-negative {
    color: #e74c3c;
  }
  
  .hours-positive, .status-work {
    color: #2ecc71;
  }

  .hours-not-decrease, .status-not-decrease {
    color: #00B7EF;
  }
  
  .status {
    margin-right: 15px;
  }
  
  .status-work,
  .status-negative {
    font-size: 20px;
    font-weight: 500;
  }

  .decrease-span {
    margin-left: 3.4em;
  }
  
  @media only screen and (max-width: 767px) {
    .title-texts {
      font-size: 12px;
    }
    .title-texts-timer {
      font-size: 12px;
      width: 70%;
    }
    .title-positive:not(:first-child) {
      display: block;
    }
    .title-positive:first-child {
      display: none;
    }
    .title-correction {
      display: none;
    }
  }
  
.darkmode.accordion-wrapper {
    background: #1f1f1f;
  }
  
  .darkmode.accordion-wrapper-white,
  .darkmode.accordion-content {
    background: #1a1a1a;
  }
  
  .darkmode.title-positive,
  .darkmode .title-correction,
  .darkmode .decrease-span {
    color: #bfbfbf;
  }
  
  .darkmode.hours-negative,
  .darkmode.status-negative,
  .darkmode.title-negative {
    color: #ff796b;
  }
  
  .darkmode.hours-positive,
  .darkmode.status-work {
    color: #58d68d;
  }
  
  .darkmode .item-fix {
    background: #ffdf6113;
  }

  .darkmode.hours-not-decrease,
  .darkmode.status-not-decrease {
    color: #40C9F3;
  }
  
.drop-list {
  display: none;
  height: 0;
  position: absolute;
  width: 0;
}

.drop-list-open {
  background: rgb(255, 255, 255);
  border-color: #383838;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: auto;
  overflow: auto;
  position: absolute;
  width: 290px;
  z-index: 2;
}

.drop-list-open::-webkit-scrollbar {
  width: 8px;
}

.search-area-files {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.search-area-filter {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
}

.search-input-files {
  width: 100%;
}

.select-drop-area {
  position: relative;
}

.box-select {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border-color: #cecece;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  color: #757575;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 56px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 290px;
}

.box-span {
  margin-left: 1rem;
}

.strong-title {
  color: #000;
}

.data-length {
  color: #757575;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .filter-text {
    display: none;
  }
  .select-drop-area {
    -webkit-align-self: center;
            align-self: center;
    margin: 0;
  }
}

@media only screen and (max-width: 375px) {
  .select-drop-area {
    margin-right: 0.8rem;
  }
}

.darkmode .box-select {
    background: #1A1A1A;
    border-color: #383838;
    color: #bfbfbf;
}

.darkmode .drop-list-open {
   background: #1A1A1A;
   color: #383838;
}

.darkmode .item-drop-list {
    color: #bfbfbf;
}

.darkmode .filter-text {
    color: #bfbfbf;
}

.darkmode .items-select {
    background: #383838;
    color: #bfbfbf;
}

.darkmode .data-length {
    color: #757575;
}

.darkmode .clear-btn {
    color: #40C9F3;
}

.darkmode .strong-title {
    color: #bfbfbf;
}
.btn-x {
  -webkit-align-self: center;
          align-self: center;
  margin-left: 5px;
}

.caminho-filtros {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 15px;
  margin-top: 30px;
}

.caminho-filtros-text {
  color: #a19f9f;
  text-transform: capitalize;
}

.clear-btn {
  color: #00b7ef;
  margin-left: 10px;
}

.filters-selects-texts:first-letter {
  text-transform: capitalize;
}

.items-select {
  background: #e1e1e2;
  display: -webkit-flex;
  display: flex;
  height: 25px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 8px;
  width: auto;
}

.items-select:not(:first-child) {
  margin-left: 5px;
}

.selects-filters {
  display: -webkit-flex;
  display: flex;
}
.search-column {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.search-area {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
}

.selects-filters {
  display: -webkit-flex;
  display: flex;
}

.item-flex-filters {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .selects-filters {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .filter-span {
    display: none;
  }
  .caminho-filtros {
    width: auto;
  }
  .items-select {
    width: auto;
  }
  .item-flex-filters {
    margin-top: 10px;
  }
  .items-select:not(:first-child) {
    margin-left: 0;
  }
  .search-area {
    -webkit-align-self: center;
            align-self: center;
  }
}

@media only screen and (max-width: 375px) {
  .search-area {
    margin-right: 0.8rem;
  }
}

.darkmode .filter-span {
    color: #bfbfbf;
}
.darkmode .ant-tabs {
  color: #757575;
}

.darkmode .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #bfbfbf;
}

.darkmode .ant-tabs-ink-bar {
  background: #40c9f3;
}

.inputs-forgot-menu {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: repeat(4, 0.5fr);
}

.input-forgot-with-span {
  position: relative;
}

.label-forgot-password-menu {
  position: absolute;
  top: 70px;
}

.button-group-forgot-menu {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 5%;
  margin-top: 10%;
}

.btn-forgot-menu {
  width: 140px;
}

.btn-forgot-menu:not(:last-child) {
  margin-right: 10px;
}

.form-forgot-menu {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .inputs-forgot-menu {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .item-forgot:last-child {
    margin-top: 2rem;
  }
  .item-forgot:nth-child(2),
  .item-forgot:nth-child(3) {
    margin-top: 1rem;
  }
  .button-group-forgot-menu {
    -webkit-align-self: center;
            align-self: center;
  }
}

.darkmode .label-forgot-password-menu {
  color: #bfbfbf;
}

.form-forgot {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.forgot-inputs {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: repeat(3, 200px);
}

.input-w-message {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.forgot-span-input {
  position: absolute;
  top: 65px;
}

.forgot-btn-group {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-top: 100px;
}

.btns-forgot {
  width: 140px;
}

.btns-forgot:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .forgot-inputs {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .item-forgot-1:nth-child(2) {
    margin-top: 1rem;
  }
  .item-forgot-1:nth-child(3) {
    margin-top: 2rem;
  }
  .forgot-btn-group {
    -webkit-align-self: center;
            align-self: center;
  }
}

.select-date {
  color: #383838;
  margin-top: 5%;
  width: 200px;
}

.user-infos-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0.5rem;
}

.working-hours {
  margin-bottom: 20%;
}

.working-hours-text {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  color: #757575;
  margin: 0 0 10px 0;
}

.darkmode.working-hours-text,.darkmode.select-date {
    color: #bfbfbf;
}

.container-lg {
  -webkit-align-items: center;
          align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 183, 239, 1) 0%,
    rgba(21, 139, 191, 1) 98%
  );

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;

  overflow: hidden;
  width: 100%;
}

.login-content {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.register-now-and-login {
  display: -webkit-flex;
  display: flex;
  max-width: 500px;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.register-now-text {
  color: #fff;
  margin-top: 2em;
}

.register-now-text a {
  font-weight: 600;
}

.form {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  padding: 1em 3em;
  width: 100%;
}

.login-title {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 80px;
}

.login-title > span {
  -webkit-align-self: center;
          align-self: center;
  color: #757575;
  font-size: 20px;
  font-weight: 500;
}

.blue-bar {
  background: #00b7ef;
  border-radius: 2px;
  height: 4px;
  margin: 0 auto;
  width: 90%;
}

.form form input {
  margin-top: 25px;
}

.buttons {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.forgot-button {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  background: transparent;
  color: #00b7ef;
  font-size: 15px;
  margin-top: 10px;
}

.logo {
  max-width: 450px;
  width: 70%;
}

.inputs-code {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 2rem;
}

.input-forgot-code {
  height: 60px;
  width: 60px;
}

.input-forgot-code:not(:first-child) {
  margin-left: 10px;
}

.button-modal-forgot-pass {
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.resend-email {
  margin-top: 10px;
}

.button-resend {
  color: #00b7ef;
  margin-left: 5px;
}

footer {
  bottom: 2%;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
}

.use-marca {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .login-btn {
    height: 54px;
    width: 300px;
  }
  .login-content {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .logo {
    margin-bottom: 15%;
    margin-right: 10%;
  }
  .buttons {
    -webkit-align-self: center;
            align-self: center;
  }
  .inputs-code {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .input-forgot-code {
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    margin: 10px;
  }
  .form {
    margin-bottom: 15%;
    padding: 5%;
  }
  footer:-ms-keyboard-active {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .login-btn {
    width: 250px;
  }
}

@media only screen and (max-height: 525px) {
  footer {
    display: none;
  }
}

.form2 {
  margin-top: 1rem;
}

.inputs-ad {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-gap: 10px;
}

.input-message-ad {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.message-span-ad {
  color: #757575;
  font-size: 14px;
  font-style: italic;
  margin-top: 65px;
  position: absolute;
}

@media only screen and (min-width: 1650px) {
  .inputs-ad {
    grid-template-columns: repeat(3, 230px);
  }
}

@media only screen and (max-width: 768px) {
  .inputs-ad {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .item-acess:not(:last-child) {
    margin-top: 1rem;
  }
  .item-acess:last-child {
    margin-top: 2rem;
  }
}

.inputs-bank {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 396px 200px 200px;
  margin-top: 1rem;
}

.bar-div {
  height: 2px;
  margin-bottom: 40px;
  width: 100%;
}

.bar-bank {
  background: #cecece;
  height: 100%;

  width: 100%;
}

@media only screen and (max-width: 768px) {
  .inputs-bank {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .item-bank:not(:first-child) {
    margin-top: 1rem;
  }
}

.grid-infos {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
}

.grid-top-infos {
  grid-template-columns: 293px 293px auto;
}

.grid-center-infos {
  grid-template-columns: 395px 190px;
}

.grid-bottom-infos {
  grid-template-columns: 293px 190px;
}

@media only screen and (min-width: 1650px) {
  .grid-top-infos {
    grid-template-columns: 320px 320px 275px;
  }

  .grid-center-infos {
    grid-template-columns: 410px 230px;
  }
}

@media only screen and (max-width: 768px) {
  .grid-infos {
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
}

.class-grid-1 {
  -webkit-align-items: center;
          align-items: center;
  display: grid;
  grid-gap: 15px;
  gap: 15px;
}

.rows-gap-30 {
  grid-row-gap: 2rem;
}

.form-1 {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-top: 5%;
}

.info-group {
  grid-template-columns: 210px 600px;
  grid-template-rows: repeat(3, 100px);
}

.input-profile-avatar {
  grid-row: 1/3;
  height: 100%;
}

.sub-group-infos-1 {
  grid-template-columns: repeat(4, 195px);
  margin-top: 10px;
}


.contact-address-group {
  margin-top: 4%;
}

.contact-address-1 {
  grid-template-columns: 400px 400px;
}

.contact-address-2 {
  grid-template-columns: 400px 400px;
  margin-top: 20px;
}

.contact-address-3 {
  grid-template-columns: 400px 190px 195px;
  margin-top: 20px;
}

.btns-register-profile {
  margin: 5% 0 5% 0;
}

.btn-register-w {
  width: 140px;
}

@media only screen and (min-width: 1650px) {
  .info-group {
    grid-template-columns: 210px 700px;
  }

  .sub-group-infos-1 {
    grid-template-columns: repeat(4, 220px);
    margin-top: 10px;
  }
  

  .contact-address-1,.contact-address-2 {
    grid-template-columns: 450px 450px;
  }

  .contact-address-3 {
    grid-template-columns: 450px 215px 220px;
    margin-top: 20px;
  }
  
}

@media only screen and (max-width: 768px) {
  .class-grid-1 {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  .input-profile-avatar {
    justify-self: center;
  }
  .popover-div {
    display: none;
  }
  .select-blood,
  .input-w-margin {
    margin-top: 1rem;
    width: 100%;
  }
  .btn-adjust {
    width: 140px;
  }
  .title-data-c {
    width: 100%;
  }
}

.area-grid {
  display: grid;
  grid-template-columns: 400px 195px 195px;
  grid-gap: 15px;
  gap: 15px;
}

.input-radio-gp-academic {
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.title-top {
  margin-top: 5%;
}

.radios-1 {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0;
}

.area-400 {
  margin-top: 4%;
  grid-template-columns: 400px 195px 195px;
}

.radio {
  height: 20px;
  width: 20px;
}
.radios-label {
  bottom: 45px;
  color: #383838;
  position: absolute;
}

.btn-and-label {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.label-radio {
  color: #757575;
  margin-left: 5px;
  margin-top: 10px;
}

.second-radio {
  margin-right: 50px;
}

@media only screen and (max-width: 768px) {
  .input-radio-gp-academic {
    margin-top: 30px;
  }
  .area-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .area-400 {
    width: 100%;
  }
  .select-y {
    margin-top: 1rem;
  }
}

.darkmode .radio-label {
  color: #bfbfbf;
}

.darkmode .input-radio {
  background: #1f1f1f;
}

.darkmode .input-radio:checked {
  background: #40c9f3;
}

.radios-rg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 5% auto;
  width: 75%;
}

.button-modal2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
}

.button-modal2 button {
  width: 141px;
}

.button-modal2 button:first-child {
  margin-right: 15px;
}

.radio-1-rg {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.radio-1-rg:not(:first-child) {
  margin-top: 10px;
}

.radio-label {
  margin-left: 20px;
}

.grid-area-docs {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.file-input {
  margin-top: 45px;
}

.file-input{
  margin-left: 12px;
}

.file-input:nth-of-type(5) {
  margin-left: 0;
}

.file-input:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1088px) {
  .file-input:nth-of-type(4) {
    margin-left: 0;
  }
  .file-input:last-child {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .grid-area-docs {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .file-input:not(:last-child){
    margin-left:0;
  }
  
  .button-modal2 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .button-modal2 button {
    width: 240px;
  }
}

.area-tile-juridic {
  margin-top: 5%;
}

.area-grid-global {
  display: grid;
  grid-gap: 25px;
}

.margin-global {
  margin-top: 5%;
}

.juridic-person-data {
  grid-template-columns: repeat(2, 410px);
}

.name-civil {
  grid-template-columns: repeat(2, 410px);
}

.atuation-docs {
  grid-template-columns: 410px 190px 190px;
}

.juridic-address-1 {
  grid-template-columns: 195px 190px 410px;
}

@media only screen and (min-width: 1650px) {
  .juridic-person-data,.name-civil {
    grid-template-columns: repeat(2, 490px);
  }
  .atuation-docs {
    grid-template-columns: 490px 235px 235px;
  }
  .juridic-address-1 {
    grid-template-columns: 235px 230px 490px;
  }
}


@media only screen and (max-width: 768px) {
  .area-grid-global {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.form-labor {
  display: grid;
  grid-template-rows: repeat(2, 50px);
  grid-template-columns: auto 200px 200px;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  grid-row-gap: 50px;
  row-gap: 50px;
  margin-top: 50px;
}

.btn-group {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.btn-group button {
  width: 140px;
}
.btn-group button:first-child {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .global-content .form-labor {
    grid-template-columns: auto;
    grid-template-rows: none;
  }

  .btn-group {
    -webkit-align-self: center;
            align-self: center;
  }
}

.form-register-inputs .styled-input-basic{
    margin-top: 2em;
}

.form-register-inputs {
    color: #383838;
}

.form-register {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
}

.contact-register {
    display: grid;
    grid-template-columns: 15% 40%;
    grid-gap: 1em;
    gap: 1em;
}

.aditionais {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1em;
    gap: 1em;
}

.terms-and-conditions {
    display: -webkit-flex;
    display: flex;
    margin-top: 1em;
    -webkit-align-items: center;
            align-items: center;
}

.terms-and-conditions span {
    margin-left: 0.5em;
}

 div.register-now > section.rigth-section > div > div > div > div.form-register-inputs  .sections-register > span {
    color: #383838;
    font-size: 17px;
}

.sections-register {
    margin-top: 1em;
}

.grid-2-auto {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 1em;
    gap: 1em;
}

@media only screen and (max-width: 768px) {
    .contact-register,.aditionais  {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
    }

    .password-register {
        grid-template-columns: 100%;
    }
}
.register-now {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    color: #fff;
}

.left-section {
    background: linear-gradient(
        180deg,
        rgba(0, 183, 239, 1) 0%,
        rgba(21, 139, 191, 1) 98%
      );
    width: 50%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 2em;
}

.left-section img {
    width: 350px;
}

.left-section h1 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}

.rigth-section {
    height: 100%;
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 4em 4em 4em 4em;
    overflow: auto;
}

.rigth-section h2 {
    color: #383838;
    font-size: 25px;
    
}
.rigth-section span {
    color: #757575;
    font-size: 16px;
}

.register-form-itens {
    max-width: 700px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .register-now{
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
      height: 100%;
      overflow: scroll;
    }

    .rigth-section,.left-section {
        width: 100%;
    }

    .rigth-section {
        overflow: hidden;
    }

    .left-section img {
        width: 200px;
    }

    .left-section h1 {
        font-size: 30px;
    }
  }
  
* {
  box-sizing: border-box;
  margin: 0;
  outline: 0 !important;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font: 400 15px Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea,
select {
  font: 400 15px Poppins, sans-serif;
}

select {
  cursor: pointer;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

button:disabled {
  cursor: auto;
  cursor: initial;
  opacity: 0.5;
}

form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

input {
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 5px;
  height: 56px;
  padding: 0 24px;
  width: 100%;
}

select {
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 5px;
  color: #000000;
  height: 56px;
  padding: 0 20px;
  width: 100%;
}

hr {
  border: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}

.global-container {
  background: #f5f7f9;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.global-content {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 10rem auto;
  max-width: 960px;
  padding: 1% 4%;
  width: 100%;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.content-md-size {
  max-height: 500px;
}

.content-fixed-size {
  min-height: 900px;
}

.content-auto-size {
  height: auto;
}

.title {
  font-size: 25px;
}

.title-min {
  font-size: 20px;
}

.title,
.title-min {
  color: #383838;
}

.global-content hr {
  margin-bottom: 30px;
  margin-top: 10px;
}

.global-content form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.global-content form input,
select {
  background: #fff;
}

.button-group {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
}

.button-group button + button {
  margin-left: 20px;
}

.button-primary,
.button-secondary {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 30px;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  height: 44px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  padding: 0 20px;
  transition: 0.2s;
  width: 190px;
}

.button-primary {
  background: #00b7ef;
  color: #fff;
}

.button-primary:hover:enabled {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.button-primary:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.button-secondary {
  background: transparent;
  border: 1px solid #00b7ef;
  color: #00b7ef;
}

.button-secondary:hover {
  border: 1px solid #03c4ff;
  color: #03c4ff;
}

.button-secondary:active {
  border: 1px solid #158bbf;
  color: #158bbf;
}

.only-text-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #00b7ef;
}

.only-text-button:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.only-text-button:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.form-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10% 0 5% 0;
}

.onlyText-btn,.button-return {
  color: #00b7ef;
}

.button-return {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.title-bar {
  background: #cecece;
  height: 2px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal {
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  left: 50%;
  max-width: 725px;
  padding: 30px 60px;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.popover-div {
  height: auto;
  padding: 1%;
  max-width: 16vw;
  width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}
.ant-tabs-tab {
  color: #707070;
}

.ant-tabs-ink-bar {
  background-color: #00b7ef;
}

.tab-antd span {
  font-size: 15px;
  font-weight: 500;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover .button-scroll {
  color: #00b7ef;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #00b7ef;
}

.ant-menu-item a {
  color: #757575;
  font-weight: 500;
}

.ant-menu-item.ant-menu-item-selected button {
  color: #00b7ef;
}

.ant-menu-item a:hover,
.ant-menu-item.ant-menu-item-selected button:hover {
  color: #005276;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  0% {
    top: -300px;
    opacity: 0;
  }
  25% {
    top: -225px;
    opacity: 0.25;
  }
  50% {
    top: -150px;
    opacity: 0.5;
  }
  75% {
    top: -75px;
    opacity: 0.75;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  0% {
    top: -300px;
    opacity: 0;
  }
  25% {
    top: -225px;
    opacity: 0.25;
  }
  50% {
    top: -150px;
    opacity: 0.5;
  }
  75% {
    top: -75px;
    opacity: 0.75;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.modal-title {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 30px;
  width: 100%;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(165, 165, 165, 0.8);
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

::-webkit-scrollbar-corner {
  background: transparent;
}


@media only screen and (min-width: 1650px) {
  .global-content {
    max-width: 61vw;
  }
}

@media only screen and (max-width: 1200px) {
  .global-content {
    max-width: 85vw;
  }
  .modal-top {
    margin-top: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .form-bottom {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-self: center;
            align-self: center;
  }
  .modal-top {
    margin-top: 2.5rem;
  }
  .content-md-size {
    max-height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .global-content {
    max-width: 100vh;
    margin-top: 6.5rem;
  }
}

.darkmode.global-container {
  background: #121212;
}

.darkmode .global-content {
  background: #1a1a1a;
  box-shadow: 0 3px 6px #000000;
}

.darkmode input,
.darkmode select,
.darkmode option {
  background: #1a1a1a;
  border-color: #383838;
  color: #bfbfbf;
}

.darkmode .global-content form input,
select {
  background: #1a1a1a;
}

.darkmode .global-content form input:disabled,
.darkmode .global-content form select:disabled {
  background: #121212;
}

.darkmode input[type='radio']:not(:checked) {
  background: #1a1a1a;
}

.darkmode .button-primary {
  background: #40c9f3;
  color: #121212;
}

.darkmode .button-secondary {
  border-color: #40c9f3;
}

.darkmode .button-primary:hover,
.darkmode .button-secondary:hover,
.darkmode .button-return:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.darkmode .button-primary:active,
.darkmode .button-secondary:active,
.darkmode .button-return:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.darkmode .button-primary:disabled,
.darkmode .button-secondary:disabled,
.darkmode .button-return:disabled {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.darkmode .button-return, .darkmode .onlyText-btn,
.darkmode .button-return,
.darkmode .only-text-button {
  color: #40c9f3;
}

.darkmode.modal {
  background: #222222;
}

.darkmode input:disabled {
  background: #121212;
  border: none;
}

.darkmode .title,
.darkmode .title-min,
.darkmode .radios-label {
  color: #bfbfbf;
}

.darkmode .title-bar,
.darkmode .bar-page,
.darkmode .bar-bank {
  background: #383838;
}

.darkmode .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #bfbfbf;
}

.darkmode .ant-tabs-tab {
  color: #757575;
}

.darkmode .ant-tabs-ink-bar {
  background-color: #40c9f3;
}

.darkmode .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.darkmode .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.darkmode .ant-menu-item-selected,
.darkmode .ant-menu-item-selected button,
.darkmode .ant-menu-item-selected button:hover {
  color: #40c9f3;
}

.darkmode .ant-menu-vertical .ant-menu-item::after,
.darkmode .ant-menu-vertical-left .ant-menu-item::after,
.darkmode .ant-menu-vertical-right .ant-menu-item::after,
.darkmode .ant-menu-inline .ant-menu-item::after {
  border-right-color: #40c9f3;
}

.darkmode .ant-menu-item.ant-menu-item-selected button {
  color: #40c9f3;
}

.darkmode .ant-menu-item button {
  color: #bfbfbf;
}

.darkmode .ant-menu-item button:hover,
.darkmode .ant-menu-item.ant-menu-item-selected button:hover {
  color: #40c9f3;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

