.download-component {
    align-items: center;
    background: #fff;
    color: #757575;
    justify-content: space-evenly;
    display: flex;
    position: fixed;
    height: 120px;
    bottom: 5%;
    width: 100%;
    max-width: 320px;
    animation-name: animaton;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    right: 0%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.30);
    z-index: 999;
}

.infos-download {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 70%;
}

.close-download {
  position: absolute;
  right: -10px;
  top: -20px;
}

.close-download img {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%) contrast(0%);
  height: 10px;
  width: 10px;
}

.download-bar {
    background: #757575;;
    height: 5px;
    margin-top: 10px;
    width: 100%;
}

.bar-blue-infos {
    background:#00c9f3 ;
    height: 100%;
}

.span-download-component {
  word-break: break-word;
}

@keyframes animaton {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
}