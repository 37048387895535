.user-depoiments {
  margin-top: 20px;
}

.user-depoiments span {
  color: #383838;
  font-size: 17px;
  font-weight: 500;
}

.depoiments-text-area {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 0 30px 0 0;
}

.progress-circular {
  height: 44px;
  width: 44px;
}

.button-and-progress {
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 200px;
}

.button-and-progress button {
  height: 44px;
  margin-top: 0;
  width: 142px;
}

.text-depoiments {
  border-color: #bfbfbf;
  border-radius: 4px;
  font-size: 14px;
  height: 115px;
  padding: 2%;
  resize: none;
}

.text-depoiments:focus {
  border-bottom: 2px solid #00b7ef;
  font-style: normal;
}
