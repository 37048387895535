.button-pdf,
.number-pages {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 50px;
}

.pdf-navigation {
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 20rem;
}
