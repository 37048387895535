.select-date {
  color: #383838;
  margin-top: 5%;
  width: 200px;
}

.user-infos-area {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.working-hours {
  margin-bottom: 20%;
}

.working-hours-text {
  align-self: flex-end;
  color: #757575;
  margin: 0 0 10px 0;
}
