.check-item {
  height: 20px;
  width: 20px;
}

.global-content form .check-item {
  margin-top: 0;
}

.item-drop-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.select-label {
  margin-left: 1.2rem;
}
