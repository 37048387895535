.darkmode .Toastify__toast--default {
  background: #1a1a1a;
  color: #bfbfbf;
}

.Toastify__toast--info {
  background: #40c9f3;
}

.darkmode .Toastify__toast--success {
  background: #58d68d;
}

.darkmode .Toastify__toast--warning {
  background: #ffdf61;
}

.darkmode .Toastify__toast--error {
  background: #ff796b;
}

.darkmode .Toastify__toast--info,
.darkmode .Toastify__toast--success,
.darkmode .Toastify__toast--error,
.darkmode .Toastify__toast--warning,
.darkmode .Toastify__close-button svg  {
  color: #222222;
}

.darkmode .Toastify__close-button {
  opacity: 0.8;
}

.darkmode .Toastify__progress-bar {
  background: #2222229c;
}