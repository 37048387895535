.darkmode.table,
.darkmode .hours-titles,
.darkmode .hours-titles-correct,
.darkmode.darkmode.hours-titles,
.darkmode.text-justify,
.darkmode.text-area-input,
.darkmode .radio-correction span {
  color: #bfbfbf;
}

.darkmode.text-area-input,
.darkmode.table-grey {
  background: #1f1f1f;
}

.darkmode.add-input {
  color: #58d68d;
}

.darkmode .table-changed,
.darkmode .input-changed {
  background: #ffdf6113;
}


.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  border-left-color: #121212;
  border-top-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.darkmode .select-correct {
  background: url(../../assets/icons/arrowDown.svg) no-repeat #1a1a1a;
  background-position: 92% center;
  padding: 0 32px 0 15px;
}

.darkmode .select-correct:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat #1a1a1a;
  background-position: 92% center;
  border-bottom-color: #40c9f3;
}
.darkmode .table-44 {
  height: 44px;
}