.title-flex {
  display: flex;
}

.title-more-text {
  font-weight: 700;
}
.text-red {
  color: #e74c3c;
  margin-left: 5px;
}
.email-memorian {
  color: #00b7ef;
}
.btn-return-more {
  align-items: center;
  color: #00b7ef;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .title-flex {
    align-items: center;
    flex-direction: column;
  }
}
