.darkmode .input-label-basic {
  color: #bfbfbf;
}

.darkmode .input-text-basic:focus,
.darkmode .ant-select-selection-search-input:focus {
  border-bottom-color: #40c9f3;
}

.darkmode .input-text-error-basic:valid {
  border-bottom-color: #ff796b;
}

.darkmode .input-text-basic:focus ~ label {
  color: #40c9f3;
}

.darkmode .ant-select-selection-search-input:focus ~ label,
.darkmode .label-focused {
  color: #40c9f3;
}

.darkmode .input-text-basic:disabled ~ label {
  color: #bfbfbf;
}

.darkmode .input-text-error-basic:valid ~ label {
  color: #ff796b;
}

.darkmode .error-message-input {
  color: #ff796b;
}
