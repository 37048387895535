.darkmode .custom-radio {
  background: #1a1a1a;
  border: 2px solid;
  border-color: #383838;
}

.darkmode .custom-checked {
  background-color: #40c9f3;
  border-color: #bfbfbf;
}
