.header {
  align-items: center;
  background: #00b7ef;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-columns: repeat(3,auto);
  height: auto;
  justify-content: space-between;
  padding: 1% 5%;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.name-header {
  color: #fff;
  font-weight: 700;
  margin-right: 0 auto;
}

.logo-nav img {
  width: 180px;
}

.notification-icon {
  margin-left: 5px;
}

.avatar-and-drop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .logo-nav img {
    width: 150px;
    margin-top: 1rem;
    max-width: 150px;
  }

  .name-header {
    display: none;
  }
}
