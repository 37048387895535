.darkmode.user-data-infos span {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
  position: absolute;
  top: -25px;
  width: 300px;
  right: 0px;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-arrow {
  border-left-color: #121212;
  border-top-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.darkmode.user-data-infos .status-user-connect,
.darkmode.user-data-infos .positive-user {
  color: #58d68d;
}

.darkmode.user-data-infos .status-user-desconnect,
.darkmode.user-data-infos .negative-user {
  color: #ff796b;
}

.darkmode .user-on {
  animation: animateondark 0.4s linear forwards;
  animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-off {
  animation: animateoffdark 0.4s linear forwards;
  animation-delay: 0.2s;
  border: 4px solid transparent;
}

.darkmode .user-on-fixed {
  border: 4px solid #58d68d;
}

.darkmode .user-off-fixed {
  border: 4px solid #ff796b;
}

.darkmode .totalBalanceFix {
  background: #ffdf6113;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight .ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(2) {
  color:#222222 ;
}

@keyframes animateondark {
  0% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
  25% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #58d68d;
  }
  50% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  75% {
    border-top-color: #58d68d;
    border-right-color: #58d68d;
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
  }
  100% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
}

@keyframes animateoffdark {
  0% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #58d68d;
  }
  25% {
    border-bottom-color: #58d68d;
    border-left-color: #58d68d;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  50% {
    border-bottom-color: #58d68d;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  75% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #58d68d;
    border-top-color: #ff796b;
  }
  100% {
    border-bottom-color: #ff796b;
    border-left-color: #ff796b;
    border-right-color: #ff796b;
    border-top-color: #ff796b;
  }
}


@media only screen and (max-width: 767px) {
  .ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  position: absolute;
  top: 0px;
  width: 300px;
  right: -50px;
  z-index: 10;
}
}