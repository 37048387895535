.user-infos-content {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.user-infos-content section:first-child {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 245px;
}

.user-infos-content section:last-child {
  margin-left: 100px;
  max-width: 500px;
  width: 100%;
}

.user-infos-top {
  justify-content: space-between;
  width: 100%;
}

.close-user-modal {
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -20px;
}

.close-user-modal img {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

.top-name-h1,
.top-name-span {
  margin: 0;
}

.user-infos-modal-avatar {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 245px;
  justify-content: center;
  width: 245px;
  background-size: cover;
}

.coin-icon {
  filter: invert(41%) sepia(0%) saturate(1452%) hue-rotate(209deg)
    brightness(108%) contrast(78%);
  height: 18px;
  margin-right: 2px;
}

.depoiments-area-infos {
  margin-top: 20px;
  width: 470px;
}

.user-stamps-itens {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.number-stamps {
  font-weight: 800;
  margin: 0;
}

.user-stamps-itens span {
  color: #757575;
  font-size: 15px;
}

.user-stamps-coins-time {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.user-infos-top,
.user-infos-section {
  border-bottom: 1px solid #cecece;
  display: flex;
  padding: 20px 0;
}

.user-infos-text-value {
  margin-top: 20px;
}

.user-infos-text-value li {
  display: grid;
  grid-template-columns: 180px auto;
}

.user-infos-fields p:first-child {
  color: #757575;
}

.user-infos-fields p:not(:first-child),
.li-with-array p:first-child {
  color: #000000;
}

.modal-user-infos {
  background: white;
  display: flex;
  flex-direction: column;
  left: 50%;
  margin-top: 2%;
  max-height: 610px;
  max-width: 900px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2% 60px;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.user-infos-fields span {
  color: #383838;
  font-weight: 500;
}

.li-with-array {
  display: flex;
  flex-wrap: wrap;
}

.user-infos-fields p:not(:first-child),
.li-with-array p,
.infos-email {
  margin-left: 5px;
}

.user-infos-more {
  margin-top: 65px;
}

.social-media-icons {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  position: relative;
  width: 70px;
}

.insta-tool:active,
.linkedin-tool:active {
  filter: brightness(50%);
}

.insta-tool:active ~ .tool-tip-insta,
.linkedin-tool:active ~ .tool-tip-linkedin {
  background: transparent;
  color: transparent;
}

.tool-tip-insta,
.tool-tip-linkedin {
  background: #707070;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 0 15px;
  position: absolute;
  top: 40px;
}

.insta-tool.disabled, .linkedin-tool.disabled {
  pointer-events: none;
  cursor: none;
}

.insta-tool ~ .tool-tip-insta {
  right: 0;
}

.linkedin-tool ~ .tool-tip-linkedin {
  left: 10px;
}

.insta-tool:hover ~ .tool-tip-insta,
.linkedin-tool:hover ~ .tool-tip-linkedin,
.tool-tip-linkedin:hover,
.tool-tip-insta:hover {
  display: block;
}

.user-infos-top-name h1 {
  font-size: 30px;
  font-weight: 800;
}

.user-infos-top-name span {
  font-size: 20px;
}

.view-more-btn {
  align-items: center;
  display: flex;
}

.user-infos-fields .email-field-infos .infos-email,
.edit-my-profile,
.view-more-btn {
  color: #00b7ef;
}

.administrativo-text {
  color: #158bbf;
}

.android-text {
  color: #6dba4c;
}

.backend-text {
  color: #076302;
}

.bemestar-text {
  color: #fa7166;
}

.branding-text {
  color: #c91b23;
}

.comercial-text {
  color: #223b7d;
}

.design-text {
  color: #b5027e;
}

.flutter-text {
  color: #00579c;
}

.frontend-text {
  color: #f1a200;
}

.fullstack-text {
  color: #8da434;
}

.ios-text {
  color: #ff4501;
}

.marketing-text {
  color: #c91b23;
}

.projetos-text {
  color: #6531ff;
}

.rh-text {
  color: #158bbf;
}

.suporte-text {
  color: #7e7f73;
}

.qualidade-text {
  color: #27b5ba;
}

.coins-tool-tip {
  position: relative;
}

.tool-tip-coins {
  background: #707070;
  border-radius: 10px;
  color: #fff;
  display: none;
  left: -18px;
  padding: 0 15px;
  position: absolute;
  width: 115px;
}

.coins-tool-tip:hover .tool-tip-coins {
  display: block;
}

.number-stamps svg {
  margin-right: 5px;
}


@media only screen and (max-width: 1600px) {
  .modal-user-infos {
    margin-top: 4%;
    padding: 3% 60px;
    max-height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .user-infos-content {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }

  .close-user-modal {
    top: 0;
  }

  .user-infos-content section:last-child {
    margin-left: 0;
  }

  .modal-user-infos {
    max-height: 100vh;
  }

  .depoiments-text-area {
    padding: 0;
  }

  .text-depoiments {
    width: 100%;
  }

  .social-media-icons {
    width: 100px;
    margin-right: 0;
  }

  .user-infos-modal-avatar {
    height: 210px;
    width: 210px;
  }

  .user-infos-text-value li {
    grid-template-columns: 150px auto;
  }

  .depoiments-area-infos {
    width: auto;
  }

  .modal-user-infos {
    padding: 15% 60px;
  }
}

@media only screen and (max-width: 410px) {
  .user-infos-content {
    margin-top: 3.5rem;
  }

  .user-infos-modal-avatar {
    height: 160px;
    width: 160px;
  }

  .user-infos-text-value li {
    grid-template-columns: 130px auto;
  }
}

@media only screen and (max-width: 350px) {
  .social-media-icons img {
    width: 25px;
    height: 25px;
  }
  .modal-user-infos {
    height: 500px;
  }
  .user-infos-text-value {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .user-infos-content {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 320px) {
  .user-infos-text-value {
    font-size: 11px;
    word-break: keep-all;
  }
}
