.darkmode .ant-slider-rail {
  background: #757575;
}

.darkmode .ant-slider-track {
  background: #40c9f3;
}

.darkmode .ant-slider-handle {
  background: #222222;
}

.darkmode .border-avatar-editor,
.darkmode .ant-slider-handle {
  border-color: #40c9f3;
}
