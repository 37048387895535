.darkmode .administrativo {
  background: #158bbf;
}

.darkmode .android {
  background: #6db94f;
}

.darkmode .backend {
  background: #066302;
}

.darkmode .bemestar {
  background: #fa7166;
}

.darkmode .branding {
  background: #e21717;
}

.darkmode .comercial {
  background: #223b7d;
}

.darkmode .design {
  background: #b5027e;
}

.darkmode .frontend {
  background: #f2a200;
}

.darkmode .fullstack {
  background: #8da434;
}

.darkmode .ios {
  background: #ff4502;
}

.darkmode .marketing {
  background: #e21717;
}

.darkmode .projetos {
  background: #6531ff;
}

.darkmode .rh {
  background: #158bbf;
}

.darkmode .suporte {
  background: #7e7f73;
}

.darkmode .qualidade {
  background: #28b5b9;
}

.darkmode .tag-open p  {
  color: #1a1a1a;
}
