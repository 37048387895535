.async-search-and-options {
  position: relative;
}

.async-search-options {
  background: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 17px;
  position: absolute;
  max-height: 200px;
  overflow: scroll;
  width: 100%;
  z-index: 2;
}

.item-async-search {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item-async-search span {
  margin-left: 10px;
}

.search-user-option {
  display: flex;
  justify-content: space-between;
}

.search-user-option.disabled {
  opacity: 0.5;
}

.add-user-term:disabled {
  color: #bfbfbf;
}
