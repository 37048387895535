.photos-user {
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 190px);
  height: 380px;
}

.photos-no-betwen {
  margin-left: 10px;
}

.photos-with-betwen {
  justify-content: space-between;
}

.avatar-select {
  background-size: cover;
  cursor: pointer;
  height: 190px;
  width: 190px;
}

.section-photos {
  border-bottom: 1px #bfbfbf solid;
  border-top: 1px #bfbfbf solid;
  overflow: auto;
}
.avatar-selected {
  border: 3px #00b7ef solid;
}

.photos-dad {
  position: relative;
}

.photos-check {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 5;
}

.button-center {
  align-self: center;
}

.modal-galery {
  margin-top: 30px;
}

.span-galery {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .photos-dad {
    margin: 5px auto;
  }
  .span-galery {
    font-size: 12px;
  }
  .photos-user {
    height: 300px;
  }
}

@media only screen and (max-width: 361px) {
  .photos-user {
    height: 200px;
  }
  .title-galery {
    font-size: 14px;
  }
}
