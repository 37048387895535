.email-accesscode,
.dept-off-admission,
.radios-new-user {
  display: grid;
  gap: 15px;
  margin-top: 50px;
}

.form-add-member {
  margin-top: 20px;
}

.email-accesscode {
  grid-template-columns: 600px 190px;
}

.dept-off-admission {
  grid-template-columns: 293px 293px 190px;
}

.radio-new-user-comp span {
  align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 15px;
  width: 100%;
}

.radio-new-user-comp {
  display: flex;
  flex-direction: column;
}

.radios-new-user {
  grid-template-columns: repeat(2, 242px);
  font-size: 15px;
  column-gap: 70px;
}

.radio-user-div {
  display: flex;
  justify-content: space-between;
}

.btn-add-group {
  display: flex;
  align-self: flex-end;
  margin-top: 50px;
  margin-bottom: 20px;
}

.btn-add-group button {
  width: 141px;
}

.btn-add-group button:first-child {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .email-accesscode,
  .dept-off-admission,
  .radios-new-user {
    display: flex;
    flex-direction: column;
  }

  .btn-add-group {
    align-self: center;
  }
}
