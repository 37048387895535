.feedback-item,
.feed-back-area,
.buttons-feedback {
  align-items: center;
  display: flex;
}

.feed-back-area {
  cursor: pointer;
  justify-content: space-between;
}

.feedback-item {
  border-bottom: 1px solid #cecece;
  height: 150px;
  justify-content: space-between;
}

.buttons-feedback {
  justify-content: space-between;
  padding: 10px;
}

.feedback-comp {
  height: 100%;
  margin-right: 40px;
  max-width: 650px;
  width: 80%;
}

.text-container p {
  color: #000;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 800;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 560px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.avatar-feedback {
  background-size: cover;
  border-radius: 50%;
  height: 76px;
  width: 76px;
}

.span-data-feedback {
  color: #989898;
}

.name-user-feedback {
  font-weight: 800;
}

.tool-tip-feed svg {
  margin-top: 5px;
}

.tool-feed {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  position: relative;
  width: 40px;
}

.tool-feed:hover {
  background: #bfbfbf;
}

.tool-feed span {
  display: none;
  position: absolute;
  text-align: center;
  top: 45px;
  width: 150px;
}

.tool-feed .ft-span {
  right: -30px;
  width: 100px;
}

.tool-feed .sc-span {
  right: -50px;
}

.tool-feed .ts-span {
  right: -5px;
  width: 50px;
}

.tool-feed:hover span {
  background: #707070;
  border-radius: 4px;
  color: #fff;
  display: block;
}

.user-feedback-section,
.user-feed-section-infos {
  display: flex;
}

.user-feedback-section {
  align-items: center;
  border-bottom: 1px #cecece solid;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.title-feedback {
  margin-bottom: 15px;
}

.user-feed-section-infos {
  flex-direction: column;
  margin-left: 15px;
}

.exit-fb {
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -20px;
}

.exit-fb img {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
  height: 15px;
}

.text-feedback-section {
  height: 160px;
  overflow: auto;
}

.text-feedback-section p {
  padding: 5px;
}

.data-feed-margin {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .text-container p {
    width: 440px;
  }
  .feedback-comp {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .avatar-feedback {
    height: 50px;
    width: 50px;
  }

  .feedback-comp {
    margin-right: -10px;
  }

  .text-container p,
  .name-user-feedback,
  .span-data-feedback {
    font-size: 12px;
  }

  .ant-tabs-tabpane:nth-child(2).ant-tabs-tabpane-active {
    width: 100%;
  }

  .text-container p {
    width: 180px;
  }
}

@media only screen and (max-width: 360px) {
  .avatar-feedback {
    height: 45px;
    width: 45px;
  }

  .feedback-comp {
    margin-right: -20px;
  }

  .text-container p {
    width: 130px;
  }
}
