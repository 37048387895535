.input-file-div {
  position: relative;
}

.input-file {
  align-items: center;
  border: 2px dashed #cecece;
  background-size: cover;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: center;
  padding: 10px;
  width: 200px;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
}

.item-input-file {
  display: none;
}

.has-thumbnail {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.has-thumbnail .icon-input-file {
  display: none;
}

.has-thumbnail .input-file-span {
  display: none;
}

.input-file-span {
  color: #383838;
  font-size: 15px;
  margin-left: 5%;
  margin-top: 5%;
  text-align: center;
}

.input-file-label {
  bottom: 100%;
  color: #383838;
  position: absolute;
  transition: 0.4s all;
  width: max-content;
}

.item-edit {
  bottom: 80%;
  left: 80%;
  position: absolute;
}

.cirle-edit {
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  height: 25px;
  place-content: center;
  place-items: center;
  width: 25px;
}

.cirle-edit:hover {
  filter: brightness(90%);
}

.cirle-edit:active {
  filter: brightness(70%);
}

.input-file-icon {
  bottom: 0;
  height: 30px;
  position: absolute;
  right: 0;
}

.upload-bar {
  background-color: #bfbfbf;
  border-radius: 5px;
  height: 5px;
  width: 100%;
}

.uploadProgess {
  background-color: #00b7ef;
  border-radius: 5px;
  height: 100%;
}

.dropdown-avatar {
  background: #ffff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  left: 180px;
  position: absolute;
  top: 45px;
  width: 280px;
  z-index: 5;
}

.dropdown-avatar div {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 8px 20px;
}

.dropdown-avatar div:hover {
  background: #f6f6f6;
}

.dropdown-avatar div span {
  color: #000;
  margin-left: 10px;
  padding: 0;
}

.icon-input-file img {
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .dropdown-avatar {
    left: -40px;
  }
}
