.darkmode .ant-menu-inline,
.darkmode .ant-menu-vertical,
.darkmode .ant-menu-vertical-left {
  background: #1a1a1a;
  border: none;
}

.darkmode .nav-team-li button {
  color: #bfbfbf;
}

.darkmode .nav-team-li.active button {
  color: #40c9f3;
}

.darkmode .nav-team-li.active {
  border-color: #40c9f3;
}

.darkmode .nav-team-li {
  border-right: none;
}
