.accordion-wrapper {
  background: #f1f1f1;
}
.accordion-wrapper-white {
  background: #fff;
}

.item-fix {
  background: #ffdf61a1;
}

.accordion-item {
  height: auto;
  max-height: 9999px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  height: 44px;
  padding: 0px 2%;
}

.item-center {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.accordion-title:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
  height: 0;
  width: 0;
}

.accordion-title:hover,
.accordion-title.open {
  color: black;
}

.accordion-title.open::after {
  border-top: 0;
}

.accordion-content {
  background: #fff;
  padding: 1em 0 2em 4em;
}

.item-title {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-top: 2%;
  width: 80%;
}

.item-title-correction {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
  width: 80%;
}

.title-texts-timer {
  display: flex;
}

.title-texts {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-negative {
  color: #e74c3c;
  margin-left: 2%;
}

.title-positive {
  color: #474747;
  margin-left: 2%;
}

.title-positive:not(:first-child) {
  display: none;
}

.title-correction {
  color: #383838;
  font-style: italic;
}

.hours-negative {
  color: #e74c3c;
}

.hours-positive {
  color: #2ecc71;
}

.status {
  margin-right: 15px;
}

.status-work,
.status-negative {
  font-size: 20px;
  font-weight: 500;
}

.status-work {
  color: #2ecc71;
}

.status-negative {
  color: #e74c3c;
}

.hours-not-decrease, .status-not-decrease {
  color: #00B7EF;
}

@media only screen and (max-width: 767px) {
  .title-texts {
    font-size: 12px;
  }
  .title-texts-timer {
    font-size: 12px;
    width: 70%;
  }
  .title-positive:not(:first-child) {
    display: block;
  }
  .title-positive:first-child {
    display: none;
  }
  .title-correction {
    display: none;
  }
  .accordion-content {
    padding: 0;
  }
  .accordion-content.open-content {
    overflow: scroll;
  }
}
