.btn-group-cancel {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 5%;
  width: 75%;
}

.btn-cancel {
  width: 200px;
}

.button-modal,
.button-modal-2,
.modal-text,
.modal-text-forgot {
  margin: 10px auto auto;
}

.button-modal,
.button-modal-2 {
  display: flex;
}

.button-modal button + button {
  margin-left: 20px;
}

.button-modal-2 button {
  width: 240px;
}

.button-modal-2 button + button {
  margin-left: 20px;
}

.confirm-padding {
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
  padding: 20px;
}

.copy-acess {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.exit {
  bottom: 40px;
  cursor: pointer;
  position: absolute;
  right: -40px;
}

.exit img {
  height: 15px;
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

.invalid-correction {
  align-items: center;
  display: flex;
}

.item-cofirm {
  display: flex;
  font-weight: 700;
  align-items: center;
}

.item-cofirm p {
  margin-bottom: 0;
}

.item-cofirm .confirm-date {
  margin-left: 4px;
}

.texts-informations {
  margin-left: 4px;
}

.item-course-info:not(:last-child) {
  border-bottom: 1px solid #474747;
  padding: 15px 0;
}

.modal-confirmation-title {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  margin-bottom: 20px;
}

.modal-courses-h2,
.modal-courses-area {
  font-size: 15px;
}

.modal-courses-area {
  color: #757575;
  display: flex;
}

.modal-courses-h1,
.modal-courses-h2 {
  color: #474747;
  line-height: 30px;
}

.modal-courses-h1 {
  font-size: 18px;
}

.modal-courses-link {
  color: #158bbf;
  text-decoration: none;
}

.modal-courses-titles,
.modal-courses-acess {
  color: #474747;
}

.modal-items {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal-title {
  align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 30px;
  width: 100%;
}

.modal-text,
.modal-text-forgot {
  font-size: 1rem;
}

.modal-text-confirm {
  margin-left: 10px;
}

.modal-text-confirm-approved {
  color: #2ecc71;
}

.modal-text-confirm-refused {
  color: #e74c3c;
}

.modal-text-confirm-pending {
  color: #FFBF00;
}

.modal-text-forgot {
  text-align: center;
}

.modal-text-forgot p {
  color: #00b7ef;
}

.more-infos {
  color: #00b7ef;
  margin-left: 4px;
}

.more-infos-modal p strong {
  margin-right: 4px;
}

.more-infos-modal .title-more {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.more-infos-modal .title-more-text p {
    margin-bottom: 0;
    margin-left: 4px;
}

.tags-modal {
  margin-top: 10px;
}

.text-area-overflow {
  height: 100%;
  max-height: 150px;
}

.title-exit {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.insituition-class {
  margin-right: 2px;
}
.insituition-class:first-letter {
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .btn-group-cancel {
    align-self: center;
    flex-direction: column-reverse;
  }

  .modal-title {
    font-size: 18px;
  }
  .modal-text-forgot,
  .modal-text {
    font-size: 14px;
    text-align: center;
  }

  .item-cofirm,
  .item-course-info,
  .modal-courses-area,
  .modal-courses-h2 {
    font-size: 12px;
  }
  .confirm-date {
    display: none;
  }
  .item-cofirm {
    align-items: center;
    flex-direction: column;
  }
  .modal-courses-h1 {
    font-size: 18px;
  }
}
