.dropdown-ul,
.dropdown-ul-disabled {
  max-width: 240px;
  padding: 10px 30px;
  position: fixed;
  right: 1rem;
  top: 6.2rem;
  width: 100%;
  z-index: 2;
}

.dropdown-father {
  position: relative;
}

.icons-dropdown:hover  ~ .tool-tip-header {
  display: block;
}

.tool-tip-header {
  background: #707070;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  display: none;
  right: -8px;
  padding: 2px 10px;
  position: absolute;
  top: 52px;
  z-index: 100;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.dropdown-ul {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.dropdown-ul-disabled {
  display: none;
}

.dropdown-ul li {
  cursor: pointer;
  display: flex;
  padding: 5px 0;
  transition: filter 0.2s;
}

.set-darkmode {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}

.dropdown-ul li:hover {
  filter: opacity(0.5);
}

.dropdown-ul li:nth-child(4) {
  border-bottom: 0.5px solid #707070;
  border-top: 0.5px solid #707070;
  padding: 10px 0;
}

.dropdown-ul li:nth-child(5) {
  border-bottom: 0.5px solid #707070;
  padding: 10px 0;
}

.dropdown-ul li a,
.dropdown-span {
  color: #333333;
  margin-left: 5px;
}

.edit-perfil-span,
.span-exit-drop {
  color: #333333;
  margin-left: 11px;
}

@media only screen and (max-width: 768px) {
  .dropdown-ul,
  .dropdown-ul-disabled {
    top: 6rem;
    z-index: 10;
  }
}
