.profile {
  align-items: center;
  display: flex;
}


.profile img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.border-profile-img {
  border-radius: 50%;
  height: 60px;
  justify-content: center;
  width: 60px;
  position: relative;
}

.status-on-off {
  width: 100%;
  height: 100%;
}

.indicator-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  left: 38px;
  bottom: 0px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
}

.on {
  background: #2ecc71;
  border: 2px solid #fff;
}

.off {
  background: #e74c3c;
  border: 2px solid #fff;
}

.div-test {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.user-h-avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .profile span {
    display: none;
  }

}
