.darkmode .pagination-item {
  color: #bfbfbf;
}

.darkmode .pagination-select {
  background: url(../../assets/icons/arrowDown.svg) no-repeat;
  background-position: 95% center;
}

.darkmode .pagination-select:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat;
  background-position: 95% center;
  border-bottom: 2px solid #00b7ef;
}
