.darkmode .infos-course strong {
  color: #bfbfbf;
}

.darkmode .course-item header {
  background: #212121;

}

.darkmode .infos-course, .darkmode .course-item header {
  border-color: #383838;;
}

.darkmode .course-item header span {
  color: #cecece;
}