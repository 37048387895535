.darkmode .negative-pending span{
    color: #FF796B;
}

.darkmode .text-pending-bold,.darkmode .table-hours-pending span {
    color: #f5f7f9;
}

.darkmode .pending-text-mail a {
    color: #40c9f3;
}