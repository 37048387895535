.darkmode .trajectory-class,
.darkmode .title-timeline {
  color: #bfbfbf;
}

.darkmode .ant-timeline-item-head {
  background-color: transparent;
}

.darkmode .ant-timeline-item:first-child .dot {
  background: #40c9f3;
}

.darkmode .dot {
  background: #757575;
}

.darkmode .ant-timeline-item-tail {
  border-color: #757575;
}
