.styled-input {
  align-items: center;
  display: flex;
  position: relative;
}

.input-text {
  background: transparent;
  padding: 0 50px;
  transition: 0.2s all;
  z-index: 2;
}

.icon {
  margin: 35px -30px 5px auto;
  z-index: 3;
}

#input-label {
  border-bottom: none;
  color: #d3d3d3;
  cursor: text;
  margin: 10px 50px;
  position: absolute;
  transition: 0.2s all;
}

.input-text:invalid {
  outline: 0;
}

.input-text:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-text-error:valid {
  border-bottom: 2px solid #e74c3c;
}
