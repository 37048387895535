.toast-custom-container .Toastify__toast--default {
  background: #fff;
  color: #333333;
}
.toast-custom-container .Toastify__toast--info {
  background: #00b7ef;
}
.toast-custom-container .Toastify__toast--success {
  background: #2ecc71;
}
.toast-custom-container .Toastify__toast--warning {
  background: #ffbf00;
}
.toast-custom-container .Toastify__toast--error {
  background: #e74c3c;
}

.toast-notificação {
  align-items: center;
  display: flex;
  font-size: 15px;
  padding: 10px 0 ;
}

.toast-notificação .avatar {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
}

.avatar-notification {
  padding: 5px;
}