.btn-transparent {
  background: transparent;
  color: transparent;
  height: 500px;
  width: 100%;
}

.buttons-transparent {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 5%;
  width: 100%;
}

.navigation-pdf-buttons {
  bottom: 15px;
  margin-top: 10px;
  position: fixed;
}

.pdf-reader-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.mobile-pdf {
  display: none;
}

.pdf-reader-item::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media only screen and (max-width: 767px) {
  .pdf-pc {
    display: none;
  }
  .mobile-pdf {
    display: block;
  }
  .navigation-pdf-buttons {
    z-index: 10;
  }
}
