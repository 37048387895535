.card-ranking {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
}

.avatar-user {
  background-size: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.border-avatar {
  border: 4px solid;
  border-radius: 50%;
  margin-top: 10px;
}

.border-avatar.first {
  height: 142px;
  width: 142px;
}

.border-avatar.side {
  height: 112px;
  width: 112px;
}

.border-avatar.others {
  height: 77px;
  width: 77px;
}

.coins-card {
  align-items: center;
  color: #3a3a3a;
  display: flex;
  font-size: 17px;
  font-weight: 500;
}

.coins-card img {
  filter: brightness(0);
  height: 17px;
}

.position {
  font-size: 20px;
  font-weight: 500;
}

.user-name-ranking {
  margin-top: 10px;
}

.administrativo-border {
  border-color: #158bbf;
}

.android-border {
  border-color: #6dba4c;
}

.backend-border {
  border-color: #076302;
}

.bemestar-border {
  border-color: #fa7166;
}

.branding-border {
  border-color: #c91b23;
}

.comercial-border {
  border-color: #223b7d;
}

.design-border {
  border-color: #b5027e;
}

.frontend-border {
  border-color: #f1a200;
}

.fullstack-border {
  border-color: #8da434;
}

.ios-border {
  border-color: #ff4501;
}

.marketing-border {
  border-color: #c91b23;
}

.projetos-border {
  border-color: #6531ff;
}

.rh-border {
  border-color: #158bbf;
}

.suporte-border {
  border-color: #7e7f73;
}

.qualidade-border {
  border-color: #27b5ba;
}

@media only screen and (max-width: 767px) {
  .border-avatar.first {
    height: 100px;
    width: 100px;
  }

  .border-avatar.side {
    height: 80px;
    width: 80px;
  }

  .border-avatar.others {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 330px) {
  .border-avatar.first {
    height: 90px;
    width: 90px;
  }

  .border-avatar.side {
    height: 70px;
    width: 70px;
  }

  .border-avatar.others {
    height: 60px;
    width: 60px;
  }
}
