.container-lg {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 183, 239, 1) 0%,
    rgba(21, 139, 191, 1) 98%
  );

  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  overflow: hidden;
  width: 100%;
}

.login-content {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.register-now-and-login {
  display: flex;
  max-width: 500px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.register-now-text {
  color: #fff;
  margin-top: 2em;
}

.register-now-text a {
  font-weight: 600;
}

.form {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  padding: 1em 3em;
  width: 100%;
}

.login-title {
  align-items: center;
  display: -webkit-flex;
  flex-direction: column;
  width: 80px;
}

.login-title > span {
  align-self: center;
  color: #757575;
  font-size: 20px;
  font-weight: 500;
}

.blue-bar {
  background: #00b7ef;
  border-radius: 2px;
  height: 4px;
  margin: 0 auto;
  width: 90%;
}

.form form input {
  margin-top: 25px;
}

.buttons {
  align-self: flex-end;
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 20px;
}

.forgot-button {
  align-self: flex-start;
  background: transparent;
  color: #00b7ef;
  font-size: 15px;
  margin-top: 10px;
}

.logo {
  max-width: 450px;
  width: 70%;
}

.inputs-code {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.input-forgot-code {
  height: 60px;
  width: 60px;
}

.input-forgot-code:not(:first-child) {
  margin-left: 10px;
}

.button-modal-forgot-pass {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.resend-email {
  margin-top: 10px;
}

.button-resend {
  color: #00b7ef;
  margin-left: 5px;
}

footer {
  bottom: 2%;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
}

.use-marca {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .login-btn {
    height: 54px;
    width: 300px;
  }
  .login-content {
    align-items: center;
    flex-direction: column-reverse;
  }
  .logo {
    margin-bottom: 15%;
    margin-right: 10%;
  }
  .buttons {
    align-self: center;
  }
  .inputs-code {
    justify-content: center;
  }
  .input-forgot-code {
    align-items: center;
    font-size: 12px;
    margin: 10px;
  }
  .form {
    margin-bottom: 15%;
    padding: 5%;
  }
  footer:-ms-keyboard-active {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .login-btn {
    width: 250px;
  }
}

@media only screen and (max-height: 525px) {
  footer {
    display: none;
  }
}
