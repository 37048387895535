.darkmode .modal-courses-h1,
.darkmode .modal-courses-h2,
.darkmode .modal-courses-area,
.darkmode .modal-courses-acess {
  color: #bfbfbf;
}

.darkmode .modal-title,
.darkmode .modal-text-forgot,
.darkmode .modal-text,
.darkmode .texts-informations,
.darkmode .confirm-date {
  color: #f5f7f9;
}

.darkmode .modal-title {
  border-bottom-color: #757575;
}

.darkmode .modal-courses-titles {
  color: #757575;
}

.darkmode .modal-text-confirm-refused {
  color: #ff796b;
}

.darkmode .modal-text-confirm-approved {
  color: #58d68d;
}

.darkmode .modal-text-confirm-pending {
  color: #FFDF61;
}

.darkmode .more-infos {
  color: #40c9f3;
}
