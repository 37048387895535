.files {
  position: relative;
}

.barra {
  background: #bfbfbf;
  height: 3px;
  width: 100%;
}

.barra-2 {
  background: #00c9f3;
  height: 100%;
}

.btn-group-icons {
  display: flex;
  justify-content: space-between;
  width: 10%;
}

.toast-custom-download{
  align-items: center;
  display: flex;
  padding: 10px;
}

.toast-custom-download svg {
  margin-right: 10px;
}

.button-tool {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.button-tool-tip {
  height: 40px;
  width: 40px;
  z-index: 10;
}

.button-tool-tip:hover {
  background: #e6e6e6;
  border-radius: 50%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
}

.button-tool-tip ~ .label-btn-file {
  color: transparent;
  position: absolute;
}

.button-tool-tip:hover ~ .label-btn-file {
  align-items: center;
  background: #757575;
  border-radius: 4px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  color: #fff;
  display: flex;
  padding: 0 5px;
  position: absolute;
  top: 45px;
}

.file {
  display: flex;
  flex-direction: column;
}

.icon-file {
  color: #00c9f3;
}

.file-texts {
  color: #828282;
}

.file-title {
  color: #212121;
  font-weight: 700;
}

.infos {
  align-items: center;
  display: flex;
  margin-left: 1rem;
  width: 80%;
}

.infos-texts {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 90px;
  margin-left: 2em;
}

.item-file {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.modal-media {
  align-items: center;
  background: #bfbfbf;
  border: 20px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  padding: 30px 60px;
  position: relative;
  top: 55%;
  transform: translate(-50%, -50%);
}

.modal-normal-size {
  height: 100%;
  max-height: 650px;
  max-width: 1110px;
  width: 100%;
}

.modal-z-index {
  z-index: 10;
}

.pdf-viewer {
  display: flex;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .btn-group-icons {
    align-items: center;
    flex-direction: column;
    margin: 0;
  }
  .file {
    font-size: 12px;
  }
  .modal-media {
    height: 100%;
    max-height: 450px;
    padding: 0;
  }
}
