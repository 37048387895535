.team-area {
  display: flex;
  margin-top: 20px;
}

.teams-lists {
  display: flex;
  flex-direction: column;
  height: 610px;
  margin-left: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.div-teams-scroll:last-child {
  margin-bottom: 38%;
}

.nav-team {
  margin-top: 40px;
}

.nav-team-li {
  border-right: 1px solid #cecece;
  padding: 5px 10px;
  width: 100px;
}

.nav-team-li.active button {
  color: #00b7ef;
}

.nav-team-li.active {
  border-right: 2px solid #00b7ef;
}

.nav-team-li button {
  color: #757575;
  text-align: start;
  width: 100px;
}

.nav-team-li .ios-nav::first-letter {
  text-transform: lowercase;
}

@media only screen and (min-width: 1650px) {
  .teams-lists {
    height: 100%;
    max-height: 940px;
  }
  .div-teams-scroll:last-child {
    margin-bottom: 100%;
  }
}

@media only screen and (max-width: 415px) {
  .teams-lists {
    margin-left: 0;
  }
  .div-teams-scroll:last-child {
    margin-bottom: 80%;
  }
  .nav-team-li {
    width: 80px;
    margin-right: 20px;
  }
  .nav-team-li button {
    font-size: 12px;
    width: 80px;
  }
}
