.darkmode .text-red {
  color: #ff796b;
}

.darkmode .title-more-text p:not(:first-child),
.darkmode p,
.darkmode p strong {
  color: #f5f7f9;
}

.darkmode .email-memorian,
.darkmode .btn-return-more {
  color: #40c9f3;
}
