.newModalContent  {
 display: flex;
 flex-direction: column;
 align-items: center;
 padding: 2em;
 text-align: center;
 font-size: 17px;
}

.title-new-modal {
    font-size: 20px;
}

.bar-new-modal {
    height: 1px;
    background: #bfbfbf;
    width: 100%;
}