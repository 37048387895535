.darkmode.certain-category-search-dropdown {
  background: #1a1a1a;
  border-color: #383838;
  color: #bfbfbf;
}

.darkmode.certain-category-search-dropdown .ant-select-item-option-content {
  color: #bfbfbf;
}

.darkmode.certain-category-search-dropdown .ant-select-item-option:hover,
.darkmode.certain-category-search-dropdown .ant-select-item-option:active,
.darkmode.certain-category-search-dropdown .ant-select-item-option-selected,
.darkmode.certain-category-search-dropdown .ant-select-item-option-active {
  background: #222222;
}
