.trajectory-class {
  width: 100%;
}

.trajectory-class span {
  font-size: 17px;
  font-weight: 500;
}

.trajectory-area {
  border-top: 1px solid #cecece;
  padding: 15px 0;
}

.ant-timeline-item:first-child .dot {
  background: #00b7ef;
}

.dot {
  background: #757575;
  border-radius: 50%;
  height: 5px;
  width: 5px;
}

.title-timeline {
  color: #000;
  font-size: 17px;
  font-weight: 500;
}

.date-timeline {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}
