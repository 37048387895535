.custom-radio {
  align-items: center;
  display: flex;
  background: #fff;
  border: 2px solid #cecece;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.custom-checked {
  background: #00b7ef;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
