.table-and-correct {
  display: flex;
  flex-direction: column;
}

.padding-table {
  margin-bottom: 10%;
}

.none-item {
  width: 139px;
}

.hours-titles {
  display: flex;
  justify-content: space-around;
  margin-left: 10px;
  width: 95%;
}

.body > div:nth-child(19) > div > div {
  width: 100%;
}

.ant-popover.ant-popconfirm.ant-popover-placement-bottom {
  z-index: 10;
  max-width: 350px;
  width: 100%;
}

.input-pop {
  z-index: 2000;
}

.input-pop .styled-input-basic .icon-2 {
  top: 15px;
  z-index: 10;
}

.item-text-hour {
  width: 10%;
  display: flex;
}

.hours {
  color: #474747;
}

.hours-titles,
.hours-titles-correct {
  font-weight: 600;
}

.hours {
  margin-left: 5%;
}

.table{
  align-self: stretch;
  color: #474747;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}

.table-correct {
  display: grid;
  grid-template-columns: 100px 190px 100px 190px auto;
  gap: 1rem;
  align-items: center;
  padding: 10px 10px 10px 0;
  width: 100%;
}

.table {
  padding: 10px 0;
}

.table-s {
  margin-top: 1em;
}

.correct-and-tables {
  display: grid;
  grid-template-columns: 50% 50%;
}

.hours-titles,
.table span {
  font-size: 15px;
}

.table-grey {
  background: #f1f1f1;
}

.table-changed {
  align-items: center;
  background: #ffdf61a1;
  justify-content: center;
  width: 70px;
}

.correct-and-tables ul {
  margin-bottom: 0;
}

.input-correct {
  font-size: 14px;
  height: 44px;
  max-width: 115px;
  padding: 5% 10px;
  text-align: center;
  width: 100%;
}

.select-correct {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(../../assets/icons/arrowDown.svg) no-repeat #fff;
  background-position: 92% center;
  padding: 0 32px 0 15px;
}

.select-correct:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat #fff;
  background-position: 92% center;
  border-bottom: 2px solid #00b7ef;
}

.table-44 {
  height: 44px;
}

.input-correct:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-changed {
  background: #ffdf61a1;
}

.btns-add-remove {
  margin-top: 5%;
}

.add-input {
  color: #2ecc71;
  font-size: 24px;
  margin-left: 1%;
  margin-right: 1%;
}

.add-input:disabled {
  opacity: 0;
}

.area-correction {
  position: relative;
}

.area-justify {
  display: flex;
  flex-direction: column;
  max-width: 372px;
  width: 100%;
}

.text-justify {
  font-weight: 900;
  margin-top: 2%;
}

.text-area-input {
  border-radius: 4px;
  font-size: 14px;
  font-style: italic;
  height: 115px;
  margin-top: 15px;
  padding: 2%;
  resize: none;
  width: 100%;
}

.text-area-input:focus {
  border-bottom: 2px solid #00b7ef;
  font-style: normal;
}

.btn-correct {
  height: 44px;
  width: 160px;
  position: absolute;
  top: -20px;
  right: 0;
}

.btn-group-correct {
  align-self: flex-end;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  right: 0;
}

.btns {
  width: 150px;
}

.btn-check svg {
  stroke-width: 2px;
}

.btns:first-child {
  margin-right: 5%;
}

.radio-correction {
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.radio-correction:not(:first-child) {
  margin-top: 10px;
}

.radio-correction input {
  width: 20px;
  height: 20px;
}

.radio-correction span {
  margin-left: 10px;
}

.reason-span {
  font-size: 15px;
  font-weight: 500;
}

.btn-trash {
  width: 25px;
  justify-self: end;
  margin-right: 15px;
}

@media only screen and (max-width: 860px) {
  .text-area-input {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .text-area-input,
  .btn-group-correct {
    position: relative;
  }

  .area-correction {
    align-self: center;
    position: absolute;
    right: 15px;
 
  }
  
  .hours-titles,
  .hours-titles-correct,
  .table span {
    font-size: 11px;
  }

  .button-primary.btn-correct {
    position: absolute;
    right: 0;
    top: 0%;
    width: 50px;
    padding: 10px;
  }

  .table-s{
    width: 100%;
  }

  .span-btn-correct {
    display: none;
  }

  .correct-and-tables.open .table {
    width: 100%;
  }

  .table {
    font-size: 12px;
    width: 90%;
  }

  .correct-and-tables {
    grid-template-columns: 90% 10%;
  }

  .correct-and-tables.open {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .correct-and-tables.open .area-correction {
    align-self: center;
    position: relative;
    right: 0;
  }

  .hours-titles {
    width: 85%;
    margin-left: 17px;
  }

  .btn-group-correct {
    flex-direction: column-reverse;
  }

  .table-correct {
    width: max-content;
    grid-template-columns: 90px 115px 90px 115px auto;
  }

}

@media only screen and (max-width: 320px) {
  .btns {
    width: 130px;
  }
}
