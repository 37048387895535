.darkmode .box-select {
    background: #1A1A1A;
    border-color: #383838;
    color: #bfbfbf;
}

.darkmode .drop-list-open {
   background: #1A1A1A;
   color: #383838;
}

.darkmode .item-drop-list {
    color: #bfbfbf;
}

.darkmode .filter-text {
    color: #bfbfbf;
}

.darkmode .items-select {
    background: #383838;
    color: #bfbfbf;
}

.darkmode .data-length {
    color: #757575;
}

.darkmode .clear-btn {
    color: #40C9F3;
}

.darkmode .strong-title {
    color: #bfbfbf;
}