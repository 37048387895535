.arrow-area {
  position: relative;
}

.arrows-scroll {
  background: #bfbfbf;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  position: absolute;
  top: 45%;
}

.arrow-scroll-left {
  left: 0;
}

.arrow-scroll-rigth {
  right: 0px;
}

.arrows-scroll svg {
  stroke-width: 2px;
}
