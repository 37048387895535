.darkmode.accordion-wrapper {
  background: #1f1f1f;
}

.darkmode.accordion-wrapper-white,
.darkmode.accordion-content {
  background: #1a1a1a;
}

.darkmode.title-positive,
.darkmode .title-correction {
  color: #bfbfbf;
}

.darkmode.hours-negative,
.darkmode.status-negative,
.darkmode.title-negative {
  color: #ff796b;
}

.darkmode.hours-positive,
.darkmode.status-work {
  color: #58d68d;
}

.darkmode .item-fix {
  background: #ffdf6113;
}

.darkmode.hours-not-decrease,
.darkmode.status-not-decrease {
  color: #40C9F3;
}
  