.radios-rg {
  display: flex;
  flex-direction: column;
  margin: 5% auto;
  width: 75%;
}

.button-modal2 {
  display: flex;
  align-self: center;
}

.button-modal2 button {
  width: 141px;
}

.button-modal2 button:first-child {
  margin-right: 15px;
}

.radio-1-rg {
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
}

.radio-1-rg:not(:first-child) {
  margin-top: 10px;
}

.radio-label {
  margin-left: 20px;
}

.grid-area-docs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.file-input {
  margin-top: 45px;
}

.file-input{
  margin-left: 12px;
}

.file-input:nth-of-type(5) {
  margin-left: 0;
}

.file-input:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1088px) {
  .file-input:nth-of-type(4) {
    margin-left: 0;
  }
  .file-input:last-child {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .grid-area-docs {
    flex-direction: column;
  }
  .file-input:not(:last-child){
    margin-left:0;
  }
  
  .button-modal2 {
    flex-direction: column;
  }

  .button-modal2 button {
    width: 240px;
  }
}
