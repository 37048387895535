.register-nav {
  margin-bottom: 40px;
}

.register-nav ul {
  display: flex;
  justify-content: space-around;
}

.register-nav ul li {
  padding: 5px 0;
}

.register-nav ul li a {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

.nav-active {
  border-bottom: 4px solid #00b7ef;
}

.nav-active a {
  filter: brightness(0);
}
