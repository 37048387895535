.form2 {
  margin-top: 1rem;
}

.inputs-ad {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-gap: 10px;
}

.input-message-ad {
  display: flex;
  flex-direction: column;
}

.message-span-ad {
  color: #757575;
  font-size: 14px;
  font-style: italic;
  margin-top: 65px;
  position: absolute;
}

@media only screen and (min-width: 1650px) {
  .inputs-ad {
    grid-template-columns: repeat(3, 230px);
  }
}

@media only screen and (max-width: 768px) {
  .inputs-ad {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
  }
  .item-acess:not(:last-child) {
    margin-top: 1rem;
  }
  .item-acess:last-child {
    margin-top: 2rem;
  }
}
