.darkmode .button-checkin {
  color: #222222;
}

.darkmode .btn-checkin {
  background: #58d68d;
}

.darkmode .btn-checkout {
  background: #ff796b;
}

.darkmode .btn-checkin:hover,
.darkmode .btn-checkout:hover {
  filter: brightness(110%);
}

.darkmode .btn-checkin:active,
.darkmode .btn-checkout:active {
  filter: brightness(80%);
}

.darkmode .btn-checkin:disabled,
.darkmode .btn-checkout:disabled {
  filter: brightness(70%);
}
