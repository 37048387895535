.darkmode.global-container {
  background: #121212;
}

.darkmode .global-content {
  background: #1a1a1a;
  box-shadow: 0 3px 6px #000000;
}

.darkmode input,
.darkmode select,
.darkmode option {
  background: #1a1a1a;
  border-color: #383838;
  color: #bfbfbf;
}

.darkmode .global-content form input,
select {
  background: #1a1a1a;
}

.darkmode .global-content form input:disabled,
.darkmode .global-content form select:disabled {
  background: #121212;
}

.darkmode input[type='radio']:not(:checked) {
  background: #1a1a1a;
}

.darkmode .button-primary {
  background: #40c9f3;
  color: #121212;
}

.darkmode .button-secondary {
  border-color: #40c9f3;
}

.darkmode .button-primary:hover,
.darkmode .button-secondary:hover,
.darkmode .button-return:hover {
  filter: brightness(110%);
}

.darkmode .button-primary:active,
.darkmode .button-secondary:active,
.darkmode .button-return:active {
  filter: brightness(80%);
}

.darkmode .button-primary:disabled,
.darkmode .button-secondary:disabled,
.darkmode .button-return:disabled {
  filter: brightness(70%);
}

.darkmode .button-return, .darkmode .onlyText-btn,
.darkmode .button-return,
.darkmode .only-text-button {
  color: #40c9f3;
}

.darkmode.modal {
  background: #222222;
}

.darkmode input:disabled {
  background: #121212;
  border: none;
}

.darkmode .title,
.darkmode .title-min,
.darkmode .radios-label {
  color: #bfbfbf;
}

.darkmode .title-bar,
.darkmode .bar-page,
.darkmode .bar-bank {
  background: #383838;
}

.darkmode .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #bfbfbf;
}

.darkmode .ant-tabs-tab {
  color: #757575;
}

.darkmode .ant-tabs-ink-bar {
  background-color: #40c9f3;
}

.darkmode .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.darkmode .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.darkmode .ant-menu-item-selected,
.darkmode .ant-menu-item-selected button,
.darkmode .ant-menu-item-selected button:hover {
  color: #40c9f3;
}

.darkmode .ant-menu-vertical .ant-menu-item::after,
.darkmode .ant-menu-vertical-left .ant-menu-item::after,
.darkmode .ant-menu-vertical-right .ant-menu-item::after,
.darkmode .ant-menu-inline .ant-menu-item::after {
  border-right-color: #40c9f3;
}

.darkmode .ant-menu-item.ant-menu-item-selected button {
  color: #40c9f3;
}

.darkmode .ant-menu-item button {
  color: #bfbfbf;
}

.darkmode .ant-menu-item button:hover,
.darkmode .ant-menu-item.ant-menu-item-selected button:hover {
  color: #40c9f3;
  filter: brightness(110%);
}
