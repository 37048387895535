.darkmode .text-select {
  color: #bfbfbf;
}

.darkmode .text {
  color: #757575;
}

.darkmode .linha-nav,
.darkmode .circle-nav {
  background: #757575;
}

.darkmode .linha-nav-select,
.darkmode .circle-nav-select {
  background: #40c9f3;
}
