.area-tile-juridic {
  margin-top: 5%;
}

.area-grid-global {
  display: grid;
  grid-gap: 25px;
}

.margin-global {
  margin-top: 5%;
}

.juridic-person-data {
  grid-template-columns: repeat(2, 410px);
}

.name-civil {
  grid-template-columns: repeat(2, 410px);
}

.atuation-docs {
  grid-template-columns: 410px 190px 190px;
}

.juridic-address-1 {
  grid-template-columns: 195px 190px 410px;
}

@media only screen and (min-width: 1650px) {
  .juridic-person-data,.name-civil {
    grid-template-columns: repeat(2, 490px);
  }
  .atuation-docs {
    grid-template-columns: 490px 235px 235px;
  }
  .juridic-address-1 {
    grid-template-columns: 235px 230px 490px;
  }
}


@media only screen and (max-width: 768px) {
  .area-grid-global {
    display: flex;
    flex-direction: column;
  }
}
