.darkmode .dropdown-ul {
  background: #222222;
  box-shadow: 0 3px 6px #000000;
  color: #bfbfbf;
}

.darkmode .dropdown-ul li a,
.darkmode .edit-perfil-span,
.darkmode .span-exit-drop {
  color: #bfbfbf;
}

.darkmode .ant-switch-checked {
  background: #40c9f3;
}
