.stamps-class {
  cursor: pointer;
  width: 100%;
}

.stamps-class span {
  font-size: 17px;
  font-weight: 500;
}

.stamps-area {
  border-top: 1px solid #cecece;
  display: grid;
  grid-template-columns: repeat(5, auto);
  row-gap: 10px;
  padding: 5px 0;
}

.stamps-area img {
  height: 30px;
  width: 30px;
  margin-top: 5px;
}
