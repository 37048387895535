@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  outline: 0 !important;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font: 400 15px Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea,
select {
  font: 400 15px Poppins, sans-serif;
}

select {
  cursor: pointer;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

button:disabled {
  cursor: initial;
  opacity: 0.5;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 5px;
  height: 56px;
  padding: 0 24px;
  width: 100%;
}

select {
  background: #fff;
  border: 1px solid #cecece;
  border-radius: 5px;
  color: #000000;
  height: 56px;
  padding: 0 20px;
  width: 100%;
}

hr {
  border: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}

.global-container {
  background: #f5f7f9;
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.global-content {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 10rem auto;
  max-width: 960px;
  padding: 1% 4%;
  width: 100%;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.content-md-size {
  max-height: 500px;
}

.content-fixed-size {
  min-height: 900px;
}

.content-auto-size {
  height: auto;
}

.title {
  font-size: 25px;
}

.title-min {
  font-size: 20px;
}

.title,
.title-min {
  color: #383838;
}

.global-content hr {
  margin-bottom: 30px;
  margin-top: 10px;
}

.global-content form {
  display: flex;
  flex-direction: column;
}

.global-content form input,
select {
  background: #fff;
}

.button-group {
  align-self: flex-end;
  display: flex;
}

.button-group button + button {
  margin-left: 20px;
}

.button-primary,
.button-secondary {
  align-items: center;
  border-radius: 30px;
  display: flex;
  font-size: 15px;
  height: 44px;
  justify-content: center;
  margin-top: 20px;
  padding: 0 20px;
  transition: 0.2s;
  width: 190px;
}

.button-primary {
  background: #00b7ef;
  color: #fff;
}

.button-primary:hover:enabled {
  filter: brightness(110%);
}

.button-primary:active {
  filter: brightness(80%);
}

.button-secondary {
  background: transparent;
  border: 1px solid #00b7ef;
  color: #00b7ef;
}

.button-secondary:hover {
  border: 1px solid #03c4ff;
  color: #03c4ff;
}

.button-secondary:active {
  border: 1px solid #158bbf;
  color: #158bbf;
}

.only-text-button {
  display: flex;
  align-items: center;
  color: #00b7ef;
}

.only-text-button:hover {
  filter: brightness(110%);
}

.only-text-button:active {
  filter: brightness(80%);
}

.form-bottom {
  display: flex;
  justify-content: space-between;
  margin: 10% 0 5% 0;
}

.onlyText-btn,.button-return {
  color: #00b7ef;
}

.button-return {
  align-items: center;
  display: flex;
}

.title-bar {
  background: #cecece;
  height: 2px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal {
  background: white;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 50%;
  max-width: 725px;
  padding: 30px 60px;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.popover-div {
  height: auto;
  padding: 1%;
  max-width: 16vw;
  width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}
.ant-tabs-tab {
  color: #707070;
}

.ant-tabs-ink-bar {
  background-color: #00b7ef;
}

.tab-antd span {
  font-size: 15px;
  font-weight: 500;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover .button-scroll {
  color: #00b7ef;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #00b7ef;
}

.ant-menu-item a {
  color: #757575;
  font-weight: 500;
}

.ant-menu-item.ant-menu-item-selected button {
  color: #00b7ef;
}

.ant-menu-item a:hover,
.ant-menu-item.ant-menu-item-selected button:hover {
  color: #005276;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  0% {
    top: -300px;
    opacity: 0;
  }
  25% {
    top: -225px;
    opacity: 0.25;
  }
  50% {
    top: -150px;
    opacity: 0.5;
  }
  75% {
    top: -75px;
    opacity: 0.75;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  0% {
    top: -300px;
    opacity: 0;
  }
  25% {
    top: -225px;
    opacity: 0.25;
  }
  50% {
    top: -150px;
    opacity: 0.5;
  }
  75% {
    top: -75px;
    opacity: 0.75;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.modal-title {
  align-self: flex-start;
  border-bottom: 1px solid #cecece;
  font-size: 20px;
  line-height: 50px;
  margin-bottom: 30px;
  width: 100%;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(165, 165, 165, 0.8);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

::-webkit-scrollbar-corner {
  background: transparent;
}


@media only screen and (min-width: 1650px) {
  .global-content {
    max-width: 61vw;
  }
}

@media only screen and (max-width: 1200px) {
  .global-content {
    max-width: 85vw;
  }
  .modal-top {
    margin-top: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .form-bottom {
    flex-direction: column;
    align-self: center;
  }
  .modal-top {
    margin-top: 2.5rem;
  }
  .content-md-size {
    max-height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .global-content {
    max-width: 100vh;
    margin-top: 6.5rem;
  }
}
