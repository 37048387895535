.btn-x {
  align-self: center;
  margin-left: 5px;
}

.caminho-filtros {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  margin-top: 30px;
}

.caminho-filtros-text {
  color: #a19f9f;
  text-transform: capitalize;
}

.clear-btn {
  color: #00b7ef;
  margin-left: 10px;
}

.filters-selects-texts:first-letter {
  text-transform: capitalize;
}

.items-select {
  background: #e1e1e2;
  display: flex;
  height: 25px;
  justify-content: space-between;
  padding: 0 8px;
  width: auto;
}

.items-select:not(:first-child) {
  margin-left: 5px;
}

.selects-filters {
  display: flex;
}
.search-column {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.search-area {
  align-items: center;
  align-self: flex-start;
  display: flex;
  margin-top: 30px;
}

.selects-filters {
  display: flex;
}

.item-flex-filters {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .selects-filters {
    flex-direction: column-reverse;
  }
  .filter-span {
    display: none;
  }
  .caminho-filtros {
    width: auto;
  }
  .items-select {
    width: auto;
  }
  .item-flex-filters {
    margin-top: 10px;
  }
  .items-select:not(:first-child) {
    margin-left: 0;
  }
  .search-area {
    align-self: center;
  }
}

@media only screen and (max-width: 375px) {
  .search-area {
    margin-right: 0.8rem;
  }
}
