.darkmode .ant-tabs {
  color: #757575;
}

.darkmode .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #bfbfbf;
}

.darkmode .ant-tabs-ink-bar {
  background: #40c9f3;
}
