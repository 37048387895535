.darkmode .tool-feed:hover {
  background: #bfbfbf1e;
}

.darkmode .tool-feed:hover span {
  background: #383838;
  color: #bfbfbf;
}

.darkmode .text-container p {
  color: #bfbfbf;
}
