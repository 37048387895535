.darkmode .styled-select-label {
  color: #bfbfbf;
}

.darkmode .styled-select-field {
  background: url(../../assets/icons/arrowDown.svg) no-repeat;
  background-position: 92% center;
}

.darkmode .styled-select-field:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat;
  background-position: 92% center;
  border-bottom-color: #40c9f3;
}

.darkmode .styled-select-field:focus ~ label {
  color: #40c9f3;
}

.styled-select-field-error {
  border-bottom-color: #ff796b;
}

.styled-select-field-error ~ label {
  color: #ff796b;
}
