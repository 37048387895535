.class-grid-1 {
  align-items: center;
  display: grid;
  gap: 15px;
}

.rows-gap-30 {
  grid-row-gap: 2rem;
}

.form-1 {
  align-items: center;
  display: flex;
  margin-top: 5%;
}

.info-group {
  grid-template-columns: 210px 600px;
  grid-template-rows: repeat(3, 100px);
}

.input-profile-avatar {
  grid-row: 1/3;
  height: 100%;
}

.sub-group-infos-1 {
  grid-template-columns: repeat(4, 195px);
  margin-top: 10px;
}


.contact-address-group {
  margin-top: 4%;
}

.contact-address-1 {
  grid-template-columns: 400px 400px;
}

.contact-address-2 {
  grid-template-columns: 400px 400px;
  margin-top: 20px;
}

.contact-address-3 {
  grid-template-columns: 400px 190px 195px;
  margin-top: 20px;
}

.btns-register-profile {
  margin: 5% 0 5% 0;
}

.btn-register-w {
  width: 140px;
}

@media only screen and (min-width: 1650px) {
  .info-group {
    grid-template-columns: 210px 700px;
  }

  .sub-group-infos-1 {
    grid-template-columns: repeat(4, 220px);
    margin-top: 10px;
  }
  

  .contact-address-1,.contact-address-2 {
    grid-template-columns: 450px 450px;
  }

  .contact-address-3 {
    grid-template-columns: 450px 215px 220px;
    margin-top: 20px;
  }
  
}

@media only screen and (max-width: 768px) {
  .class-grid-1 {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    row-gap: 30px;
  }
  .input-profile-avatar {
    justify-self: center;
  }
  .popover-div {
    display: none;
  }
  .select-blood,
  .input-w-margin {
    margin-top: 1rem;
    width: 100%;
  }
  .btn-adjust {
    width: 140px;
  }
  .title-data-c {
    width: 100%;
  }
}
