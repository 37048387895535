.notifications {
  position: relative;
}

.notificationsUl {
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.22);
  height: auto;
  max-height: 80vh;
  overflow: auto;
  padding: 0 15px;
  position: fixed;
  right: 2%;
  top: 12%;
  width: 530px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-notifications {
  position: relative;
}

.not-read-symbol {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00B7EF;
  padding: 5px;
}

.btn-notifications:hover ~ .notifications-tool {
  display: block;
}

.notifications-tool {
  right: -35px;
}

.number-notifications {
  align-items: center;
  background: #F02849;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 22px;
  justify-content: center;
  position: absolute;
  right: -3px;
  top: -5px;
  width: 22px;
}

.notification-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding: 5px 0;
  word-wrap: break-word;
}

.notification-item:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.avatar-and-notification {
  align-items:center;
  display: flex;
}

.notification-item p {
  margin-bottom: 0;
  width: 100%;
  max-width: 350px;
  margin-right: 5px;
}

.notification-item span {
  color: #757575;
}

.avatar-area-notify {
  padding: 10px;
}

.p-notification {
  margin-bottom: 0;
}

.notification-item .avatar-area-notify .avatar{
  background-size:contain ;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767px) {
  .notificationsUl {
    height: 100%;
    right: 0;
    top: 13%;
    width: 100%;
    font-size: 12px;
  }
}
  