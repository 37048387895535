.users-selected-list {
  height: 300px;
  overflow: scroll;
}

.users-selected,
.users-selected-name-avatar {
  align-items: center;
  display: flex;
}

.users-selected {
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}

.users-selected .avatar {
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.users-selected-name-avatar span {
  margin-left: 10px;
}

.no-users-span {
  color: #757575;
}

.users-selected-name-avatar,
.no-users-span,
.remove-user-term {
  font-size: 15px;
}

.remove-user-term {
  margin-right: 10px;
}

.remove-user-term img {
  filter: invert(43%) sepia(89%) saturate(541%) hue-rotate(149deg)
    brightness(95%) contrast(108%);
  height: 12px;
  margin-left: 5px;
  margin-bottom: 1px;
}
