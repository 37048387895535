.send-terms {
  display: flex;
  flex-direction: column;
}

.select-all-terms {
  margin-left: 10px;
}

.check-terms-area .item-drop-list {
  padding: 5px 0;
}

.btn-terms-group {
  align-self: flex-end;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-terms-group button:nth-child(1) {
  margin-right: 10px;
}

.btn-terms-group button {
  width: 141px;
}

.colabs {
  margin-top: 20px;
}

.search-colab {
  display: grid;
  gap: 15px;
  grid-template-columns: 46% 53%;
  height: 300px;
}

.colab-search-label {
  font-size: 15px;
}

.colabs-add .users-selected-component {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .search-colab {
    display: flex;
    flex-direction: column;
  }

  .btn-terms-group {
    align-self: center;
  }
}
