.depoiment {
  display: flex;
  position: relative;
}

.depoiment:not(:first-child) {
  margin-top: 20px;
}

.depoiment .avatar {
  background-size: cover;
  border-radius: 50%;
  height: 67px;
  width: 67px;
  transition: width 1s;
}

.depoiment-balom {
  background-color: #e1e1e2;
  border-radius: 0 10px 10px 10px;
  cursor: pointer;
  margin-left: 30px;
  min-height: 68px;
  padding: 10px 20px;
  width: 340px;
}

.depoiment-balom.open {
  width: 300px;
}

.delete-depoiment {
  margin-bottom: 5%;
  margin-left: 10px;
  position: relative;
}

.delete-depoiment:hover .tool-tip-button {
  display: block;
}

.tool-tip-button {
  background: #707070;
  border-radius: 4px;
  color: #fff;
  display: none;
  padding: 2px;
  position: absolute;
  right: -10px;
  z-index: 15;
}

.ballom-and-data {
  display: flex;
  flex-direction: column;
  height: auto;
  z-index: 2;
}

.span-data {
  align-self: flex-end;
  color: #989898;
}

.depoiment-balom p {
  margin: 0;
  word-break: break-word;
}

.seta-bal {
  height: 60px;
  left: 80px;
  position: absolute;
  z-index: 1;
}

.span-name-depoiment {
  font-weight: 500;
}

.ant-popover-inner > div {
  display: flex;
  flex-direction: column;
}

.ant-popover-inner > div > div.ant-popover-buttons {
  align-self: flex-end;
  display: flex;
}

.ant-popover-inner > div > div.ant-popover-buttons > button {
  align-items: center;
  border-radius: 30px;
  display: flex;
  font-size: 12px;
  padding: 0 20px;
  transition: 0.2s;
}

.ant-popover-inner > div > div.ant-popover-buttons > button:nth-child(1) {
  background: transparent;
  border: 1px solid #00b7ef;
  color: #00b7ef;
}

.ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #00b7ef;
  color: #fff;
  border-color: #00b7ef;
}

.ant-popover.ant-popconfirm.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #000;
}

@media only screen and (max-width: 767px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 280px;
  }
  .depoiment:hover .avatar {
    height: 30px;
    width: 30px;
  }
  .depoiment-balom.open {
    width: 250px;
  }
}

@media only screen and (max-width: 410px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 230px;
  }
}

@media only screen and (max-width: 350px) {
  .depoiment-balom {
    margin-left: 25px;
    width: 205px;
  }

  .depoiment .avatar {
    height: 50px;
    width: 50px;
  }
}
