.styled-select {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.styled-select-label {
  bottom: 50px;
  color: #383838;
  font-size: 15px;
  position: absolute;
  transition: 0.4s all;
}

.styled-select-field {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(../../assets/icons/arrowDown.svg) no-repeat;
  background-position: 92% center;
}

.styled-select-field:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat;
  background-position: 92% center;
  border-bottom: 2px solid #00b7ef;
}

.styled-select-field:invalid {
  outline: 0;
}

.styled-select-field:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.styled-select-field-error {
  border-bottom: 2px solid #e74c3c;
}

.styled-select-field-error ~ label {
  color: #e74c3c;
  font-weight: 700;
}
