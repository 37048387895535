.search-input-cp {
  align-items: center;
  display: flex;
  position: relative;
}

.search-input-cp input {
  background: #f1f1f1;
  height: 58px;
  padding: 0 60px;
}

.search-input-cp input:focus {
  padding: 0 60px;
}

.icon-search {
  left: 10px;
  position: absolute;
  top: 15px;
  z-index: 5;
}
