.darkmode .seta-bal {
  display: none;
}

.darkmode .depoiment-balom {
  background: #1a1a1a;
  border: 1px solid #383838;
}

.darkmode .depoiment-balom p {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button:nth-child(1) {
  border-color: #40c9f3;
  color: #40c9f3;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  > div
  > div.ant-popover-buttons
  > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #40c9f3;
  border-color: #40c9f3;
  color: #222222;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner {
  background: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-arrow {
  border-right-color: #121212;
  border-bottom-color: #121212;
}

.ant-popover.ant-popconfirm.darkmode.ant-popover-placement-topRight
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-message
  .ant-popover-message-title {
  color: #bfbfbf;
}

.ant-popover.ant-popconfirm.darkmode.pop-style.ant-popover-placement-bottomRight .ant-popover-inner > div > div.ant-popover-buttons > button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #40c9f3;
  border-color: #40c9f3;
}
