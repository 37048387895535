.ranking-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.first-and-sides {
  display: flex;
  margin: 0 auto;
}

.second-user,
.third-user {
  margin-top: 60px;
}

.others-positions {
  display: flex;
  margin: 40px auto;
  width: 700px;
}

@media only screen and (max-width: 767px) {
  .ranking-component {
    margin: 0 -22px;
  }
  .first-and-sides {
    display: grid;
    grid-template-columns: repeat(3, 110px);
    margin: 0 auto;
  }
  .ant-tabs-tabpane:nth-child(2) {
    width: 0;
  }
  .others-positions {
    display: grid;
    grid-template-columns: repeat(3, 110px);
    margin-top: 20px;
  }
}

@media only screen and (max-width: 376px) {
  .ranking-component {
    margin: 0 -38px;
  }
}

@media only screen and (max-width: 361px) {
  .ranking-component {
    margin: 0 -45px;
  }
}

@media only screen and (max-width: 330px) {
  .first-and-sides {
    grid-template-columns: repeat(3, 95px);
  }
  .others-positions {
    display: grid;
    grid-template-columns: repeat(3, 95px);
    margin-top: 20px;
  }
}
