 .administrativo,.administrativa {
  background: #158bbf;
}

.android {
  background: #6dba4c;
}

.backend {
  background: #076302;
}

.bemestar {
  background: #fa7166;
}

.branding {
  background: #c91b23;
}

.comercial {
  background: #223b7d;
}

.design {
  background: #b5027e;
}

.frontend {
  background: #f1a200;
}

.fullstack {
  background: #8da434;
}

.flutter {
  background: #00579c;
}

.ios {
  background: #ff4501;
}

.marketing {
  background: #c91b23;
}

.projetos {
  background: #6531ff;
}

.rh {
  background: #158bbf;
}

.suporte,.ti {
  background: #7e7f73;
}

.qualidade {
  background: #27b5ba;
}

.administrativo:hover,
.administrativa:hover,
.android:hover,
.backend:hover,
.bemestar:hover,
.branding:hover,
.comercial:hover,
.design:hover,
.frontend:hover,
.fullstack:hover,
.ios:hover,
.marketing:hover,
.projetos:hover,
.rh:hover,
.suporte:hover,
.ti:hover,
.qualidade :hover {
  filter: brightness(80%);
}
.tag-areas {
  display: flex;
}

.tag-areas hr {
  border-radius: 2px;
  cursor: pointer;
  height: 6px;
  margin-top: 5px;
  width: 40px;
}

.tag-areas hr:not(:first-child) {
  margin-left: 5px;
}

.tag-open {
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 22px;
  margin-top: 5px;
  padding: 0 5px;
}

.tag-open:not(:first-child) {
  margin-left: 5px;
}
