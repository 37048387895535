.fields-profile-editor,
.avatar-and-fields,
.fields-4,
.two-fields {
  display: grid;
  gap: 10px;
}

.avatar-and-fields {
  grid-template-columns: 190px 760px;
}

.fields-4 {
  grid-template-columns: repeat(2, 290px);
  margin-left: 10px;
}

.two-fields {
  grid-template-columns: repeat(2, 396px);
}

.input-editor-profile {
  margin-top: 30px;
  max-width: 800px;
  width: 100%;
}

.button-section {
  align-self: flex-end;
  margin-right: -1.5rem;
  margin-top: 30px;
}

.group-modal-editor {
  align-self: flex-end;
}

.group-modal-editor button {
  width: 140px;
}

.group-modal-editor button:last-child {
  margin-left: 10px;
}

.title-editor {
  max-width: 800px;
  width: 100%;
}

.close-modal-edit {
  position: absolute;
  right: 30px;
}

.close-modal-edit img {
  filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(112deg) brightness(150%)
    contrast(0%);
}

@media only screen and (max-width: 768px) {
  .fields-profile-editor,
  .avatar-and-fields,
  .fields-4,
  .two-fields {
    display: flex;
    flex-direction: column;
  }

  .fields-profile-editor {
    margin-top: 3rem;
  }

  .fields-4 {
    align-self: center;
    margin-left: 0;
    width: 100%;
    margin-top: 40px;
    grid-template-columns: none;
    gap: 0;
  }

  .input-editor-profile {
    margin-top: 40px;
  }

  .two-fields {
    gap: 0;
    grid-template-columns: none;
    row-gap: 60px;
  }

  .input-file-avatar {
    align-self: center;
  }

  .button-section {
    align-self: center;
  }
}

@media only screen and (max-width: 767px) {
  .group-modal-editor {
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 359px) {
  .close-modal-edit {
    top: 20px;
    right: 10px;
  }
}
