.form-register-inputs .styled-input-basic{
    margin-top: 2em;
}

.form-register-inputs {
    color: #383838;
}

.form-register {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.contact-register {
    display: grid;
    grid-template-columns: 15% 40%;
    gap: 1em;
}

.aditionais {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 1em;
}

.terms-and-conditions {
    display: flex;
    margin-top: 1em;
    align-items: center;
}

.terms-and-conditions span {
    margin-left: 0.5em;
}

 div.register-now > section.rigth-section > div > div > div > div.form-register-inputs  .sections-register > span {
    color: #383838;
    font-size: 17px;
}

.sections-register {
    margin-top: 1em;
}

.grid-2-auto {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 1em;
}

@media only screen and (max-width: 768px) {
    .contact-register,.aditionais  {
      display: flex;
      flex-direction: column;
    }

    .password-register {
        grid-template-columns: 100%;
    }
}