.darkmode .radio-label {
  color: #bfbfbf;
}

.darkmode .input-radio {
  background: #1f1f1f;
}

.darkmode .input-radio:checked {
  background: #40c9f3;
}
