.styled-input-basic {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.styled-input-basic:disabled {
  background: #cecece;
}

.input-text-basic {
  background: #fff;
}

.input-label-basic {
  bottom: 50px;
  color: #383838;
  cursor: text;
  font-size: 15px;
  position: absolute;
  transition: 0.4s all;
}

.input-text-basic:focus,
.ant-select-selection-search-input:focus {
  border-bottom: 2px solid #00b7ef;
}

.input-text-basic:invalid {
  outline: 0;
}

.input-text-error-basic:valid {
  border-bottom: 2px solid #e74c3c;
}

.input-text-basic:focus ~ label {
  color: #00b7ef;
  font-weight: 700;
}

.input-text-basic:disabled {
  background: #cecece;
  z-index: 0;
}

.ant-select-selection-search-input:focus ~ label,
.label-focused {
  color: #00b7ef;
  font-weight: 700;
}

.input-text-basic:disabled ~ label {
  color: #383838;
}

.input-text-error-basic:valid ~ label {
  color: #e74c3c;
  font-weight: 700;
}

.icon-2 {
  position: absolute;
  right: 10px;
  top: 32%;
  z-index: 10;
}

.error-message-input {
  color: #e74c3c;
  display: flex;
  font-size: 14px;
  margin-top: 0.2rem;
  position: absolute;
  top: 100%;
  width: max(250px, 100%);
}

.spinner-input {
  bottom: 10px;
  position: absolute;
  right: 10px;
}