.form-labor {
  display: grid;
  grid-template-rows: repeat(2, 50px);
  grid-template-columns: auto 200px 200px;
  column-gap: 15px;
  row-gap: 50px;
  margin-top: 50px;
}

.btn-group {
  align-self: flex-end;
}

.btn-group button {
  width: 140px;
}
.btn-group button:first-child {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .global-content .form-labor {
    grid-template-columns: auto;
    grid-template-rows: none;
  }

  .btn-group {
    align-self: center;
  }
}
