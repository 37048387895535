.btn-pagination {
  align-items: center;
  color: #00b7ef;
  display: flex;
}

.btn-pagination:hover {
  color: #158bbf;
  text-decoration: underline;
}

.btn-pagination:active {
  color: #005276;
  text-decoration: underline;
}

.disabled-btn {
  color: transparent;
}

.disabled-btn:hover {
  color: transparent;
}

.disabled-btn:active {
  color: transparent;
}

@media only screen and (max-width: 767px) {
  .btn-pagination {
    font-size: 10px;
  }
}
