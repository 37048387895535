.styled-check {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.styled-check-unmarked {
  background: #fff;
  border: 1px solid #cecece;
}

.styled-check-marked {
  background-color: #00b7ef;
  border: 1px solid #00b7ef;
}

.styled-check svg {
  stroke-width: 3px;
}
