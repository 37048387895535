.register-now {
    display: flex;
    flex: 1;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    color: #fff;
}

.left-section {
    background: linear-gradient(
        180deg,
        rgba(0, 183, 239, 1) 0%,
        rgba(21, 139, 191, 1) 98%
      );
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
}

.left-section img {
    width: 350px;
}

.left-section h1 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}

.rigth-section {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 4em 4em 4em;
    overflow: auto;
}

.rigth-section h2 {
    color: #383838;
    font-size: 25px;
    
}
.rigth-section span {
    color: #757575;
    font-size: 16px;
}

.register-form-itens {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .register-now{
      flex-direction: column-reverse;
      height: 100%;
      overflow: scroll;
    }

    .rigth-section,.left-section {
        width: 100%;
    }

    .rigth-section {
        overflow: hidden;
    }

    .left-section img {
        width: 200px;
    }

    .left-section h1 {
        font-size: 30px;
    }
  }
  