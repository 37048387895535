.darkmode .input-file {
  border-color: #383838;
}

.darkmode .input-file-span {
  color: #757575;
}

.darkmode .input-file-label,
.darkmode .dropdown-avatar div span {
  color: #bfbfbf;
}

.darkmode .dropdown-avatar,
.darkmode .dropdown-avatar div:hover {
  background: #121212;
}

.darkmode .dropdown-avatar div:hover {
  filter: brightness(180%);
}

.darkmode .dropdown-avatar img {
  filter: invert(87%) sepia(0%) saturate(3%) hue-rotate(162deg) brightness(92%)
    contrast(83%);
}
