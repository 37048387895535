.clear-and-select-all {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.select-bar-span {
  color: #bfbfbf;
  font-size: 15px;
}

.select-btn {
  color: #00b7ef;
}
