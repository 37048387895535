.miniatura {
  cursor: pointer;
  height: 80px;
  overflow: hidden;
  width: 150px;
}

.loader-grid {
  margin-left: 50%;
  margin-top: 15px;
}
