.form-forgot {
  display: flex;
  flex-direction: column;
}

.forgot-inputs {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 200px);
}

.input-w-message {
  display: flex;
  flex-direction: column;
  position: relative;
}

.forgot-span-input {
  position: absolute;
  top: 65px;
}

.forgot-btn-group {
  align-self: flex-end;
  display: flex;
  margin-top: 100px;
}

.btns-forgot {
  width: 140px;
}

.btns-forgot:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .forgot-inputs {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
  }
  .item-forgot-1:nth-child(2) {
    margin-top: 1rem;
  }
  .item-forgot-1:nth-child(3) {
    margin-top: 2rem;
  }
  .forgot-btn-group {
    align-self: center;
  }
}
