.feedback-button {
  bottom: 0;
  height: auto;
  left: 1%;
  position: fixed;
  width: auto;
}

.feedback-button, .feedback-component {
  z-index: 10;
}

.btn-feedback {
  display: flex;
  height: 100px;
  justify-content: space-between;
  width: 84px;
  transition: width 1s;
}

.btn-feedback:hover {
  width: 340px;
}

.btn-feed-items {
  align-items: center;
  display: flex;
  font-size: calc(20px - 35%);
  justify-content: center;
  width: 80%;
}

.btn-feedback:hover .btn-feed-items {
  justify-content: space-between;
}

.btn-feed-items span,
.btn-feed-items svg {
  display: none;
}

.btn-feedback:hover .btn-feed-items span,
.btn-feedback:hover .btn-feed-items svg {
  display: block;
  animation-name: FEEDBACKANIMATION;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes FEEDBACKANIMATION {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-feed-items img {
  width: 40px;
}

.feedback-header-items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.feedback-header-items span img {
  height: 25px;
  margin-right: 10px;
  width: 25px;
}

.feedback-component {
  max-width: 340px;
  width: 100%;
}

.btn-feedback,
.feedback-header {
  align-items: center;
  background: #00b7ef;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.feedback-header {
  height: 50px;
}

.btn-feedback:hover {
  filter: brightness(110%);
}

.btn-feedback:active {
  filter: brightness(80%);
}

.feedback-text-area {
  background: #ffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.feedback-text-area p {
  color: #595959;
}

.submit-feedback {
  align-items: center;
  align-self: flex-end;
  display: flex;
  width: 140px;
}

.feedback-text-area textarea {
  border-radius: 4px;
  font-size: 14px;
  height: 115px;
  padding: 2%;
  resize: none;
  width: 100%;
}

@media only screen and (max-width: 1650px) {
  .feedback-button {
    left: 5px;
  }
  .btn-feed-items {
    padding: 10px;
    width: 100%;
  }
  .btn-feedback {
    width: 84px;
  }
  .btn-feedback:hover {
    width: 250px;
  }
  .btn-feed-items img {
    width: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .feedback-button {
    left: 0;
    width: auto;
  }
  .btn-feedback,
  .btn-feedback:hover {
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 50px;
    margin-bottom: 15px;
    margin-left: 15px;
    width: 50px;
  }
  .btn-feed-items {
    justify-content: center;
  }
  .btn-feed-items img {
    width: 25px;
  }
  .btn-feed-items span,
  .btn-feedback:hover .btn-feed-items span,
  .btn-feed-items svg,
  .btn-feedback:hover .btn-feed-items svg {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .feedback-component {
    max-width: 100%;
  }
}
