.darkmode .infos-texts span {
  color: #bfbfbf;
}

.darkmode .barra {
  background: #383838;
}

.darkmode .barra-2 {
  background: #40c9f3;
}

.darkmode .button-tool-tip:hover {
  background: #222222;
}

.darkmode .button-tool-tip:hover ~ .label-btn-file {
  background: #383838;
  color: #bfbfbf;
}

.darkmode .view-blue,
.darkmode .save-blue {
  filter: invert(67%) sepia(66%) saturate(1491%) hue-rotate(164deg)
    brightness(110%) contrast(91%);
}

.darkmode.modal-media {
  border-color: #383838;
  background: #222222;
}
