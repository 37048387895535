.grid-infos {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
}

.grid-top-infos {
  grid-template-columns: 293px 293px auto;
}

.grid-center-infos {
  grid-template-columns: 395px 190px;
}

.grid-bottom-infos {
  grid-template-columns: 293px 190px;
}

@media only screen and (min-width: 1650px) {
  .grid-top-infos {
    grid-template-columns: 320px 320px 275px;
  }

  .grid-center-infos {
    grid-template-columns: 410px 230px;
  }
}

@media only screen and (max-width: 768px) {
  .grid-infos {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
  }
}
