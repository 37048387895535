.async-search-and-options {
  position: relative;
}

.async-search-options {
  background: #fff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 17px;
  padding: 10px;
  position: absolute;
  height: auto;
  width: 344px;
  z-index: 2;
}

.item-async-search {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px 0;
}

.item-async-search span {
  margin-left: 10px;
}

.item-async-search.with-hover:hover {
  background: #00b7ef13;
}

.load-area {
  display: flex;
  align-items:center;
  justify-content: center;
  height: 32px;
  width: 32px;
}