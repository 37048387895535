.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination-center {
  align-items: center;
  align-self: center;
  display: flex;
}

.pagination-select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/
  background: url(../../assets/icons/arrowDown.svg) no-repeat;
  background-position: 95% center;
  font-size: 14px;
  padding: 15px;
  width: 76px;
  margin-bottom: 10px;
}

.pagination-select:focus {
  background: url(../../assets/icons/arrowUp.svg) no-repeat;
  background-position: 95% center;
  border-bottom: 2px solid #00b7ef;
}

.pagination-item:not(:first-child) {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination,
  .pagination-select {
    font-size: 12px;
  }
  .pagination-select {
    align-items: center;
    width: 65px;
  }
}
