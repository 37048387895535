.inputs-forgot-menu {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 0.5fr);
}

.input-forgot-with-span {
  position: relative;
}

.label-forgot-password-menu {
  position: absolute;
  top: 70px;
}

.button-group-forgot-menu {
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-bottom: 5%;
  margin-top: 10%;
}

.btn-forgot-menu {
  width: 140px;
}

.btn-forgot-menu:not(:last-child) {
  margin-right: 10px;
}

.form-forgot-menu {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .inputs-forgot-menu {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
  }
  .item-forgot:last-child {
    margin-top: 2rem;
  }
  .item-forgot:nth-child(2),
  .item-forgot:nth-child(3) {
    margin-top: 1rem;
  }
  .button-group-forgot-menu {
    align-self: center;
  }
}
