.area-grid {
  display: grid;
  grid-template-columns: 400px 195px 195px;
  gap: 15px;
}

.input-radio-gp-academic {
  align-items: center;
  position: relative;
}

.title-top {
  margin-top: 5%;
}

.radios-1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.area-400 {
  margin-top: 4%;
  grid-template-columns: 400px 195px 195px;
}

.radio {
  height: 20px;
  width: 20px;
}
.radios-label {
  bottom: 45px;
  color: #383838;
  position: absolute;
}

.btn-and-label {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.label-radio {
  color: #757575;
  margin-left: 5px;
  margin-top: 10px;
}

.second-radio {
  margin-right: 50px;
}

@media only screen and (max-width: 768px) {
  .input-radio-gp-academic {
    margin-top: 30px;
  }
  .area-grid {
    display: flex;
    flex-direction: column;
  }
  .area-400 {
    width: 100%;
  }
  .select-y {
    margin-top: 1rem;
  }
}
