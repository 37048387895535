.team-list {
  width: 650px;
  height: 325px;
}

.cards-area {
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
}

.cards-area .card-item:not(:first-child) {
  margin-left: 10px;
}

.team-list-title {
  border-bottom: 1px solid #bfbfbf;
  color: #262626;
  font-size: 20px;
}

@media only screen and (min-width: 1650px) {
  .team-list {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team-list {
    width: 100%;
  }
}
