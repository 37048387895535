.modal-pending {
    margin-top: 1.5em;
}

.modal-pending-items {
    font-size: 15px;
}

.pending-texts {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}

.pending-texts span:last-child {
    margin-left: 10px;
}

.pending-texts p {
    margin-bottom: 0;
}

.text-pending-bold {
    font-weight: 600;
}

.table-hours-pending span {
    font-weight: 500;
}

.negative-pending {
    color: #E74C3C;
    display: flex;
    align-items: center;
}

.negative-pending span {
    margin-left: 10px;
}

.font-17 {
    font-size: 17px;
}

.table-hours-pending {
    display: grid;
    grid-template-columns: repeat(2, 90px);
    grid-template-rows: repeat(1, 40px);
    column-gap: 20px;
    align-items: center;
    padding: 5px 0;
}

.table-hours-pending input {
    height: 40px;
    font-size: 14px;
    padding: 5% 10px;
}

.pending-text-mail p {
    margin-bottom: 0;
}

.pending-text-mail a {
    color: #00B7EF;
}

.button-pending-modal {
    float: right;
}

.pending-table {
    margin-top: 1.5em;
}